import React from 'react';
import { SearchItem, configJSON } from '../AdvancedSearchController';
import { Box, Button, Typography, styled } from '@material-ui/core';
import { MarkerSmall, noResults } from '../assets';
import { SearchData } from "../../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import { countNights } from '../../../../components/src/helpers';
import moment from 'moment';
import { checkCondition } from '../../../../components/src/HelperUtils';
import { useLanguageContext } from "../../../../components/src/context/LanguageContext";

type Props = {
    item: SearchItem;
    searchData?: SearchData;
    onSeeOffer: (item: SearchItem) => void;
    //Viewmap
    MapButton: (item:SearchItem) => JSX.Element;

}

const breakpoint = 1200;
const isMiddle = 1450;

const ItemWrapper = styled(Box)(({theme}) => ({
    boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
    borderRadius: '25px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(breakpoint)]: {
        flexDirection: 'row',
    },

    "& .image-container": {
        width: '100%',
        height: '250px',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        [theme.breakpoints.up(breakpoint)]: {
            width: '18vw',
            height: '270px',
            maxWidth: '18vw',
            minWidth: '18vw', 
        },
        [theme.breakpoints.up(isMiddle)]: {
            width: '270px',
            height: '270px',
            maxWidth: '270px',
            minWidth: '270px', 
        },
    },
    "& .image": {
        width:"100%",height:"100%",
        objectFit:"cover",
        objectPosition:'center',
        WebkitObjectFit: 'cover', 
        WebkitObjectPosition: 'center', 
    },
    "& .main-content": {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',

        [theme.breakpoints.up(breakpoint)]: {
            padding: '18px 20px',
        },

        [theme.breakpoints.up(isMiddle)]: {
            padding: '40px',
        },
    },
    "& .title": {
        color: '#000',
        fontSize: 'calc(100% + (26 - 16) * ((100vw - 320px) / (1600 - 320)))',
        fontWeight: 600
    },
    "& .content-line": {
        display: 'flex',
        gap: '12px',
        fontSize: '14px',
        fontWeight: 500,
        color: '#909090',
        alignItems: 'flex-start'
    },
    "& .contenxt-text": {
        fontSize: '14px',
        fontWeight: 500,
        [theme.breakpoints.up(breakpoint)]: {
            fontSize: '13px',
            fontWeight: 500,
        },

        [theme.breakpoints.up(isMiddle)]: {
            fontSize: '14px',
            fontWeight: 500,
        },
    },
    "& .link": {
        color: '#909090',
        fontSize: '12px',
        fontWeight: 700,
        textDecoration: 'underline',
    },
    "& .warning-text": {
        color: '#FFCE00',
        fontSize: '14px',
        fontWeight: 600
    },
    "& .price-block": {
        margin: '28px',
        marginTop: 0,
        marginRight: '45px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        [theme.breakpoints.up(breakpoint)]: {
            borderLeft: '0.5px solid #B7B7B7',
            alignItems: 'flex-end',
            margin: "20px 20px 20px 20px"
        },

        [theme.breakpoints.up(isMiddle)]: {
            paddingLeft: '36px',
            marginTop: "28px",
            marginLeft: 'auto',
        },
    },
    "& .offer-btn": {
        color: '#6F05EA',
        textTransform: 'none',
        backgroundColor: 'transparent',
        backround: 'transparent',
        padding: '10px 36px',
        borderRadius: '35px',
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 700,
        fontFamily: 'Montserrat',
        letterSpacing: 0,
        border: '1px solid #6F05EA',
        whiteSpace: 'nowrap',
    },
    "& .price-text": {
        color: '#777777',
        fontSize: '14px',
        fontWeight: 500,
        marginBottom: '20px'
    },
    "& .price-section": {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up(breakpoint)]: {
            alignItems: 'end',
        },
    },
    "& .price-list": {
        display: 'flex',
        alignItems: 'center',
    },
    "& .no-tax-price": {
        color: '#777777',
        fontSize: '14px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
    },

    "& .main-price": {
        color: '#6F05EA',
        fontSize: '24px',
        fontWeight: 700,
        marginLeft: '16px',
        whiteSpace: 'nowrap',
    },
    "& .fees-text": {
        marginTop: '8px',
        marginBottom: '20px',
        color: '#777777',
        fontSize: '12px',
        fontWeight: 500,
        width: '166px',
        [theme.breakpoints.up(breakpoint)]: {
            textAlign: 'right'

        },
    },

}))

const AvailabilityTypo = styled(Typography)({
    fontSize: 14,
    fontWeight: 600,
    color: "#FFCE00",
    lineHeight: "17px"
})

const ListItem = ({ item, searchData, onSeeOffer,MapButton }: Props) => {
    
    const { languageData } = useLanguageContext();
    const listData = languageData?.translation?.advanceSearch;
    const nights =listData?.nights;
    const session =listData?.session;
    const hour =listData?.hour;
    const day =listData?.day;
    const there_only = listData?.there_only;
    const there_is = listData?.there_is;
    const service_left = listData?.service_left;
    const space_left = listData?.space_left;
    const room_left = listData?.room_left;
    const see_offer = listData?.see_offer;
    const tax = listData?.tax;
    const from = listData?.from
    const url = item?.search_gallery_url !== "No image" ? item?.search_gallery_url : noResults;
    const petType = searchData?.your_pet?.split(" ")[0].toLowerCase();
    
    const handleGetNights = () => {
        if(searchData) {
            if(searchData.start_date && searchData.end_date) {
                return countNights(searchData.start_date, searchData.end_date)
            }
        }
        return 0;
    }
    const renderInfoHedaer = () => {
        if(searchData) {
            switch (searchData.service_type) {
                case 'Hotels':
                    return `${handleGetNights()} ${nights}, 1 ${petType}`;
                case 'Dog Walking':
                    return `${session}`;
                case 'Pet Sitting':
                    return `${hour}`;
                case 'Day Care':
                    return `${day}`;
                case 'Training':
                    return `${session}`;
                default:
                    return '';
            }
        }
    }

    const getServiceType = () => {
        const isMoreService = checkCondition(Boolean(item?.service_av.availability > 1), true, false) as boolean;
        switch (item?.service_details.service_type) {
            case configJSON.SERVICE_ENUMS.HOTELS:
                return checkCondition(isMoreService, `${there_only} ${item?.service_av.availability} ${room_left}`, `${there_is} ${item?.service_av.availability} ${room_left}`) as string;
            case configJSON.SERVICE_ENUMS.DAY_CARE:
                return checkCondition(isMoreService,`${there_only} ${item?.service_av.availability} ${space_left}`, `${there_is} ${item?.service_av.availability} ${space_left}`) as string;
            case configJSON.SERVICE_ENUMS.DOG_WALKING:
            case configJSON.SERVICE_ENUMS.PET_SITTING:
            case configJSON.SERVICE_ENUMS.TRAINING:
            case configJSON.SERVICE_ENUMS.GROOMING:
                return checkCondition(isMoreService,`${there_only} ${item?.service_av.availability} ${service_left}`, `${there_is} ${item?.service_av.availability} ${service_left}`) as string
        }
    }
    
    const availabilityText = checkCondition(Boolean(item?.service_av.availability < 4 && item?.service_av.availability > 0), getServiceType(), "") as string;

    return (
        <ItemWrapper>
            <Box className='image-container' style={{ maxWidth: '100%' }} >
                <img src={url} className='image' />
            </Box>
            <Box className='main-content'>
                <Typography className='title'>{item?.establishment_name}</Typography>
                <Box className='content-line'>
                    <img src={MarkerSmall}/>
                    <Typography className='contenxt-text'>{item?.address}</Typography>
                </Box>
                <Box style={{cursor: "pointer",pointerEvents: 'auto'}}>{MapButton && MapButton(item)}</Box>
                <AvailabilityTypo data-test-id="availability-text">{availabilityText}</AvailabilityTypo>
            </Box>
            <Box className='price-block'>
                <Box className='price-section'>
                    <Typography className='price-text'>{renderInfoHedaer()}</Typography>
                    <Box className='price-list'>
                        <Typography className='no-tax-price'>{from}</Typography>
                        <Typography className='main-price'>€ {item?.service_av.price}</Typography>
                    </Box>
                    <Typography className='fees-text'>{tax}</Typography>
                </Box>
                <Button className='offer-btn' onClick={() => onSeeOffer(item)}>{see_offer}</Button>
            </Box>
        </ItemWrapper>
    )
}

export default ListItem;