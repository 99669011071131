// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

const webConfigJSON = require("../config.js");
interface IAppoinment {
    service_date: string
    service_type: string
    customer_name: string
    customer_phone: string
    service_status: "Completed" | "Booked" | "Cancelled"
    service_charge: string
}

export const appointmentsList = [
    {
        service_date: "2024-06-12",
        service_type: "Group walking",
        customer_name: "Wade Warren",
        customer_phone: "12345654",
        service_status: "Completed",
        service_charge: "100€"
    },
    {
        service_date: "2024-06-11",
        customer_name: "Leslie Alexander",
        service_type: "Individual walking",
        customer_phone: "12345654",
        service_status: "Booked",
        service_charge: "200€"
    },
    {
        customer_name: "Robert Fox",
        service_date: "2024-06-10",
        service_type: "Group walking",
        customer_phone: "12345654",
        service_status: "Cancelled",
        service_charge: "50€"
    },
    {
        service_status: "Booked",
        service_date: "2024-06-09",
        service_type: "Individual walking",
        customer_name: "Jacob Jones",
        customer_phone: "12345654",
        service_charge: "10€"
    },
    {
        service_charge: "50€",
        service_date: "2024-06-08",
        service_type: "Group walking",
        customer_name: "Jenny Wilson",
        customer_phone: "12345654",
        service_status: "Booked",        
    },
    {
        service_status: "Completed",
        service_charge: "100€",
        service_date: "2024-06-07",
        service_type: "Individual walking",
        customer_name: "Cameron Williamson",
        customer_phone: "12345654",
    },
    {
        customer_phone: "12345654",
        service_status: "Completed",
        service_date: "2024-06-06",
        service_type: "Group walking",
        customer_name: "Cameron Williamson dasndan",        
        service_charge: "20€"
    }
]
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    searchValue: string,
    filteredList: IAppoinment[],
    openBookingDetails: boolean,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class AppoinmentsDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            searchValue: "",
            filteredList: (appointmentsList as IAppoinment[]),
            openBookingDetails: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleChangeSearch = (value: string) => {
        const trimmedValue = value.trimStart()
        const fieldsToFilter: Partial<keyof IAppoinment>[] = [
            "service_type",
            "customer_name",
            "customer_phone",
            "service_status",
            "service_charge",
        ];

        const filteredArr = (appointmentsList as IAppoinment[]).filter((item) =>
            fieldsToFilter.some((field) =>
                item[field as keyof IAppoinment]?.toString().toLowerCase().includes(trimmedValue.toLowerCase())
            )
        );

        this.setState({
            searchValue: trimmedValue,
            filteredList: filteredArr
        })
    }
    handleOpenBookingDetails = () => {
        this.setState({
            openBookingDetails: true
        })
    }
    handleCloseBookingDetails = () => {
        this.setState({
            openBookingDetails: false
        })
    }
    // Customizable Area End
}

