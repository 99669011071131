import {
    FormControl,
    InputAdornment,
    MenuItem,
    Select,
    styled,
  } from "@material-ui/core";
  import React, { useState } from "react";
  import { DropdownIcon, VendorCalendarPerson } from "../assets";
import { Employee } from "../types";
  
  const RotateIcon: React.FC<{ open: boolean }> = ({ open }) => {
    return (
      <img
        src={DropdownIcon}
        style={{
          transition: "transform 0.2s ease",
          transform: open ? "rotate(0deg)" : "rotate(180deg)",
        }}
      />
    );
  };
  
  const CustomMenuItem = styled(MenuItem)({
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000",
  
    "&.Mui-selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#F3EBFF",
    },
  });
  
  const CustomSelect = styled(Select)({
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000",
    width: "230px",
    height: "28px",
    overflow: "hidden",
    paddingLeft: 0,
  
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      paddingRight: "10px",
      border: 'none'
    },
    "& .Mui-focused .MuiSelect-select": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiList-padding": {
      padding: 0,
    },
  });

  type Props = {
    employeesList: Employee[];
    fetchEmployeeEvents: (employee_id: string) => void;
  }
  
  const VendorCalendarSelect = ({ employeesList, fetchEmployeeEvents }: Props) => {
    const [selectedUser, setSelectedUser] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeUser = (event: React.ChangeEvent<{ value: unknown }>) => {
      if (event.target.value !== undefined) {
        setSelectedUser(event.target.value as string);
        fetchEmployeeEvents(event.target.value as string);
      }
    };
  
    return (
      <FormControl variant="outlined" fullWidth>
        <CustomSelect
          data-test-id="vendor-select"
          labelId="select-label"
          value={selectedUser}
          startAdornment={
            <InputAdornment position="start">
              <img src={VendorCalendarPerson} />
            </InputAdornment>
          }
          onChange={handleChangeUser}
          displayEmpty
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          IconComponent={() => <RotateIcon open={isOpen} />}
          MenuProps={{
            disableScrollLock: true,
            marginThreshold: undefined,
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: 200,
                overflowY: "auto",
                marginLeft: "-40px",
                width: "230px",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          <CustomMenuItem value="">
            Member Calendars
          </CustomMenuItem>
          {employeesList.map((employee) => (
            <CustomMenuItem key={String(employee.id)} value={String(employee.id)}>
              {employee.name}
            </CustomMenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    );
  };
  
  export default VendorCalendarSelect;
  