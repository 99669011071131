import { enLandingPage, ptLandingPage } from "./translations/customerLandingPage";
import { enCustomerSignUp, ptCustomerSignUp } from "./translations/customerSignUp";
import { enCustomerUserProfile, ptCustomerUserProfile } from "./translations/customerUserProfile";
import { enEmployeeManagement, ptEmployeeManagement } from "./translations/employeeManagement";
import { enEstablishmentService, ptEstablishmentService } from "./translations/EstablishmentAddService/establishmentService";
import { enForgotNewPassword, ptForgotNewPassword } from "./translations/forgotNewPassword";
import { enLogin, ptLogin } from "./translations/login";
import { enScheduling, ptScheduling } from "./translations/scheduling";
import { enVendorLanding, ptVendorLanding } from "./translations/vendorLandingPage";
import { enVendorSignUp, ptVendorSignUp } from "./translations/vendorSignUp";

export const availableResources = {
    en: {
        login: enLogin,
        customerSignUp: enCustomerSignUp,
        forgotNewPassword:enForgotNewPassword,
        vendorSignUp: enVendorSignUp,
        landingPage: enLandingPage,
        scheduling:enScheduling,
        vendorLandingPage:enVendorLanding,
        establishmentService: enEstablishmentService,
        employeeManagement: enEmployeeManagement,
        customerUserProfile:enCustomerUserProfile
    },
    pt: {
        login: ptLogin,
        customerSignUp: ptCustomerSignUp,
        forgotNewPassword: ptForgotNewPassword,
        vendorSignUp: ptVendorSignUp,
        landingPage:ptLandingPage,
        scheduling:ptScheduling,
        vendorLandingPage:ptVendorLanding,
        establishmentService: ptEstablishmentService,
        employeeManagement:ptEmployeeManagement,
        customerUserProfile:ptCustomerUserProfile
    }
}
