import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { UserIcon, attachFileIcon, dogImage, sendIcon, video } from "./assets";
//twilio chat
import moment, { MomentInput } from "moment";
import { Client } from '@twilio/conversations';
import { scrollToBottom } from '../../../components/src/scrollToBottom';

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface UploadFile {
  file: File;
  progress: number;
}
//twilio chat
interface IApiModel {
  contentType?: string;
  method: string;
  body?: object;
  endPoint: string;
  isJsonStringify?: boolean;
  token?: string | null;
}
export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}
interface ViewImageDialogModel {
  isImageDialogOpen: boolean;
  viewImageArray: string[],
  imageIndex: number,
  type: string,
  timestamp: string
}
//twilio chat
interface ChatRoom {
  id: number;
  name: string;
  last_message: string | null;
  chat_type: string; // e.g., "single_user"
  unread_message: number;
  last_message_time:string;
  muted: boolean;
  created_at: string; // ISO 8601 date format
  updated_at: string; // ISO 8601 date format
}

export interface UserData {
  phone_number: number;
  name: string;
  email: string;
  role: string; // e.g., "customer", "vendor"
  image_url: string;
}

interface TwilioConversation {
  sid: string;
  friendly_name: string;
  account_sid: string;
  unique_name: string;
  state: string; // e.g., "active"
  messaging_service_sid: string;
  attributes: string; // JSON string
  chat_service_sid: string;
  date_created: string; // ISO 8601 date format
  date_updated: string; // ISO 8601 date format
}

interface ChatData {
  
  chat_room: ChatRoom;
  other_user_data: UserData;
  last_message: string ;
  twilio_conversation: TwilioConversation | null;
  error: string | null; // could be null or an error message
}

//conversation chat interface 
interface Attachment {
  id: number;
  file_name: string;
  url: string;
  file_type: any
}

export interface LocalMessage {
  id: number;
  message: string;
  account_id: number;
  chat_id: number;
  is_mark_read: boolean;
  created_at: string;
  updated_at: string;
  type: string;
  attachments: Attachment[];
}

interface TwilioMessage {
  body: string;
  author: string;
  message_sid: string;
  conversation_sid: string;
  account_sid: string;
  participant_sid: string;
  type: string;
  date_created: string;
  date_updated: string;
}

export interface MessageData {
  id: React.Key | null | undefined;
  local_message: LocalMessage;
  twilio_message: TwilioMessage;
}
export interface UnreadMessageCount{
  total_unread_count:number
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userId: number  | string;
  conversationId: any;
  handleBackListClick: () => void;
  //twilio chat
  userListData: ChatData[];
  userAccessToken: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  dummyMassageList: any;
  dummyUserName: string;
  dummyMsgDate: string;
  dummyNewMessage: string;
  replyMessageData: { messageId: number, message: string, image: string, type: string,message_sid:string };
  isReplyMessage: boolean;
  viewImageDialog: ViewImageDialogModel,
  uploadDocumentData: { uploadFiles: UploadFile[], completedFiles: File[] }
  isUploadDocumentDialogOpen: boolean,
  uploadDocumentTypeError: string,
  //twilio chat
  twilioClient: any,
  conversations: any,
  twiliomessages: any,
  activeConversation: any,
  messageInput: string,
  isLoading: boolean,
  accessToken: any,
  currentConversation: any;
  errorMessage:string 
  OpenMessagePopUp:boolean;
  lastDisplayedDate: any,
  onScrollMessageLoading:boolean;
  total_unread_count:number | null
  replyToMessage: any
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  getCoversationListId: string = "";
  sendMessagePostApi: string = "";
  accessTokenApiId: string = "";
  messageListener = null; // Initialize the listener tracking variable
  chatContainerRef = React.createRef<HTMLDivElement>(); // Ref for the chat container
  getTotalUnreadMessage:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      dummyMassageList: [],
      dummyUserName: "",
      dummyMsgDate: "",
      dummyNewMessage: "",
      replyMessageData: {
        messageId: 0,
        message: "",
        image: "",
        type: "",
        message_sid:""
      },
      isReplyMessage: false,
      viewImageDialog: {
        isImageDialogOpen: false,
        viewImageArray: [],
        imageIndex: 0,
        type: "",
        timestamp: ""
      },
      uploadDocumentData: { uploadFiles: [], completedFiles: [] },
      isUploadDocumentDialogOpen: false,
      uploadDocumentTypeError: "",
      //twilio chat
      conversations: '',
      twiliomessages: '',
      activeConversation: null,
      messageInput: '',
      isLoading: true,
      accessToken: '',
      twilioClient: '',
      currentConversation: '',
      errorMessage:'',
      OpenMessagePopUp:false,
      lastDisplayedDate: null,
      onScrollMessageLoading:false,
      total_unread_count:0,
      replyToMessage: null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  uploadIntervals: { [key: string]: NodeJS.Timeout } = {};
  async componentDidMount() {
    super.componentDidMount();
    if (this.props.userListData.length > 0) {
      this.setState({ isLoading: false });
      this.filterMessagesByUserId();
      this.GetUserConversationData();
      this.initializeTwilioClient()
      this.scrollToBottom(); // Scroll to the bottom
    }
    this.getTotalUnreadCounts()

    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
    Keyboard.addListener("keyboardDidShow", this._keyboardDidShow.bind(this));
    Keyboard.addListener("keyboardDidHide", this._keyboardDidHide.bind(this));
    this.scrollToBottom(); // Scroll to the bottom
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
    if (this.state.currentConversation) {
      this.state.currentConversation.removeAllListeners();
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (accountIdInput: string) => {
    this.setState({ accountIdInput });
  };

  handleMessageChange = (message: string) => {
    this.setState({ message });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.files) {
      const file = event.target.files[0] as Blob;
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.setState({ imageUrl: readerEvent.target?.result as string, docRes: file, isVisiblePreviewModal: true });
      };
      reader.readAsDataURL(file);
      this.setState({ docRes: file, isVisiblePreviewModal: true });
    }
    else {
      this.setState({ imageUrl: "", docRes: null })
    }
  };

  handleSendMessage = () => {
    this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
    this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
  };

  handleInsertImage = () => {
    this.setState({ isUploadDocumentDialogOpen: true })
  };

  getChatDetails = (token: string, chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = (accountIdInput: string, chatId: number) => {
    if (!accountIdInput || this.isStringNullOrBlank(accountIdInput)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        accounts_id: [Number(accountIdInput)],
        chat_id: Number(chatId),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addUserToChatApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  leaveChatRoom = (chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: parseInt(chatId + "", 10),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (chatId: number, message: string, imageUrl?: string) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("message[message]", message);
    if (imageUrl) {
      formData.append("message[attachments][]", (this.state.docRes as Blob));
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}/${chatId}/messages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: this.state.chatId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat(this.state.accountIdInput, this.state.chatId),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(this.state.chatId),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) this.setState({ token });
      this.refreshChatInterval = setInterval(
        () => this.getChatDetails(this.state.token, this.state.chatId),
        30000
      );
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
    }
    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getChatApiCallId &&
      responseJson && responseJson.data
    ) {
      const chatData = responseJson.data;
      const { muted } = chatData.attributes.accounts_chats.find(
        (item: { attributes: { account_id: number } }) =>
          item.attributes.account_id === this.state.accountId
      ).attributes;
      this.setState({
        chatData,
        muted,
      });
      this.updateReadMessages();
    }

    if (apiRequestCallId === this.getCoversationListId) {
      this.handleResponseData(responseJson)
    }
    if (apiRequestCallId === this.sendMessagePostApi) {
      this.handleSendMessageResponse(responseJson)
    }
    if (apiRequestCallId === this.accessTokenApiId) {
      this.handleSendMessageResponse2(responseJson)
    }if (apiRequestCallId === this.getTotalUnreadMessage) {
      this.handletotalunreadMessage(responseJson)
    }
  }
  //dummy data message filter
  componentDidUpdate(prevProps: any,prevState:any) {
    if (this.props.userId !== prevProps.userId) {
      this.setState({ isLoading: true })
      this.filterMessagesByUserId();
      this.GetUserConversationData();
      this.getTotalUnreadCounts()
      this.initializeTwilioClient(); 
    }
   
   
    if (prevState.dummyMassageList?.length !== this.state.dummyMassageList?.length) {
      this.scrollToBottom();
    }
  }
  scrollToBottom = () => {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTo({
        top: this.chatContainerRef.current.scrollHeight,
        behavior: 'smooth', // Smooth scrolling
      });
    }
    
  };
  filterMessagesByUserId = () => {
    const { userId, userListData } = this.props;

    if (userListData && userListData.length > 0) {
      const userMessages = this.props.userListData.find((chatData: { chat_room: { id: number; }; }) => chatData.chat_room.id === userId);
      if (userMessages) {
        this.setState({ dummyUserName: userMessages.other_user_data.name, dummyMsgDate: this.formatDate(userMessages.chat_room.last_message_time),lastDisplayedDate:this.formatDate(userMessages.chat_room.updated_at), imageUrl: userMessages.other_user_data.image_url });

      }
    }
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ dummyNewMessage: event.target.value });
  }
   handleKeyDown = (event: { key: string; keyCode: number; preventDefault: () => void; }) => {
    // Check if the Enter key (keyCode 13 or event.key === "Enter") is pressed
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault(); 
     this.setState({dummyNewMessage:''}) // Clear the input after sending
     this.dummyHandleSendMessage()
    }
  };
  handleReplyMessageClick = (message:any
) => {
  this.scrollToBottom();
  const attachments = message.local_message?.attachments;
  const firstAttachment = Array.isArray(attachments) ? attachments[0] : attachments || null;
  this.setState((prevState) => ({
    replyMessageData: {
      ...prevState.replyMessageData,
      message: message.local_message?.message,
      image: firstAttachment?.url || "",
      type: firstAttachment?.file_type || "",
      messageId:message.local_message?.id,
      message_sid:message.twilio_message?.message_sid,
    },
    replyToMessage: message,
    isReplyMessage: true,
  }));
};
dummyHandleSendMessage = async () => {
  this.setState({ isReplyMessage: false });
  const { dummyNewMessage, replyToMessage, uploadDocumentData } = this.state;
  const { userId } = this.props;
  if (dummyNewMessage !== '') {
    const formattedDate = new Date().toISOString();
    const newMessageObject = {
      id: new Date().getTime(),
      local_message: {
        id: new Date().getTime(),
        message: dummyNewMessage,
        account_id: userId,
        chat_id: userId,
        is_mark_read: false,
        created_at: formattedDate,
        updated_at: formattedDate,
        type: 'sender', // Mark as sender
        attachments: [...uploadDocumentData.uploadFiles],
      },
      twilio_message: {
        body: dummyNewMessage,
        author: '',
        message_sid: '',
        reply_message: replyToMessage?.twilio_message?.message_sid || null, // Attach parent message
      },
      parentMessage: replyToMessage || null, // Reference the parent message
    };

    // Add the new message to `dummyMassageList` instantly
    this.setState((prevState) => ({
      dummyMassageList: [...prevState.dummyMassageList, newMessageObject],
      dummyNewMessage: '', // Clear the message input field
      replyToMessage: null, // Clear the reply state
    }));

    // Scroll to the bottom of the chat view
    scrollToBottom(this.chatContainerRef.current);

    // Send the message to the server
    this.sendMessageToserver();
  }
};



  handleReplyClose = () => {
    this.setState({ isReplyMessage: false,replyMessageData:{
      type:'',
      message:'',
      message_sid:'',
      messageId:0,
      image:''
    },replyToMessage:'' });
  }
  formatTimeAgo3 = (timestamp: string) => {
    const date = moment(timestamp);
    return date.format('hh:mm A'); // e.g., "08:20 PM"
  };
  handleImageClick = (images: any, type: string, timestamp: string) => {
    this.setState((prevState) => ({
      viewImageDialog: {
        ...prevState.viewImageDialog,
        viewImageArray: images,
        isImageDialogOpen: true,
        type: type,
        timestamp: this.formatTimeAgo3(timestamp)
      },
    }))
  };
  handleImageCloseDialog = () => {
    this.setState((prevState) => ({
      viewImageDialog: {
        ...prevState.viewImageDialog,
        viewImageArray: [],
        isImageDialogOpen: false,
      },
    }))
  };
  handleImageDialogPrev = () => {
    this.setState((prevState) => ({
      viewImageDialog: {
        ...prevState.viewImageDialog,
        imageIndex:
          prevState.viewImageDialog.imageIndex === 0
            ? prevState.viewImageDialog.viewImageArray.length - 1
            : prevState.viewImageDialog.imageIndex - 1
      }
    }));
  };
  handleImageDialogNext = () => {
    this.setState((prevState) => ({
      viewImageDialog: {
        ...prevState.viewImageDialog,
        imageIndex:
          prevState.viewImageDialog.imageIndex === prevState.viewImageDialog.viewImageArray.length - 1
            ? 0
            : prevState.viewImageDialog.imageIndex + 1
      }
    }));
  };
  //end dummy data message filter
  handleUploadDocumentCloseDialog = () => {
    this.setState({ isUploadDocumentDialogOpen: false, uploadDocumentTypeError: "", uploadDocumentData: { uploadFiles: [], completedFiles: [] } })
  }
  handleDrop = (acceptedFiles: File[]) => {
    const fileTypeError = acceptedFiles.every(file => ['image/png', 'video/mp4', 'image/jpeg'].includes(file.type));
    if (!fileTypeError) {
      this.setState({ uploadDocumentTypeError: "Only PNG, JPEG and MP4 files are allowed" })
    }
    else {
      const filesWithProgress: UploadFile[] = acceptedFiles.map(file => ({
        file,
        progress: 0,
      }));
      this.setState((prevState) => ({
        uploadDocumentData: {
          ...prevState.uploadDocumentData,
          uploadFiles: [...prevState.uploadDocumentData.uploadFiles, ...filesWithProgress],
        },
      }));
      filesWithProgress.forEach(uploadFile => {
        this.uploadFile(uploadFile);
      });
    }
  };

  uploadFile = (uploadFile: UploadFile) => {
    const { file } = uploadFile;
    const interval = setInterval(() => {
      if (uploadFile.progress < 100) {
        const newProgress = uploadFile.progress + 10;
        uploadFile.progress = newProgress;
        this.setState((prevState) => ({
          uploadDocumentData: {
            ...prevState.uploadDocumentData,
            uploadFiles: [...this.state.uploadDocumentData.uploadFiles]
          },
        }));
      } else {
        clearInterval(interval);
        delete this.uploadIntervals[file.name];
        this.setState((prevState) => ({
          uploadDocumentData: {
            ...prevState.uploadDocumentData,
            uploadFiles: prevState.uploadDocumentData.uploadFiles.filter(f => f.file !== file),
            completedFiles: [...prevState.uploadDocumentData.completedFiles, file],
          },
        }));
      }
    }, 500);
    this.uploadIntervals[file.name] = interval;
  };
  handleUploadDocumentCloseClick = (DeleteFile: File) => {
    clearInterval(this.uploadIntervals[DeleteFile.name]);
    delete this.uploadIntervals[DeleteFile.name];
    this.setState((prevState) => ({
      uploadDocumentData: {
        ...prevState.uploadDocumentData,
        uploadFiles: prevState.uploadDocumentData.uploadFiles.filter(f => f.file !== DeleteFile),
        completedFiles: prevState.uploadDocumentData.completedFiles.filter(file => file !== DeleteFile),
      }
    }));
  }
  handleUploadFileClick = () => {
    const { userId } = this.props;
    const images = this.state.uploadDocumentData.completedFiles.map((file, index) => {
      const url = URL.createObjectURL(file); // Create a temporary URL for the file
      const fileType = file.type || 'application/octet-stream'; // Default to binary if type is missing
      return {
        id: index,
        file_name: file.name,
        file_type: fileType, // Add file_type here
        url: url,
      };
    });

    this.setState({ isUploadDocumentDialogOpen: false });

    if (this.state.uploadDocumentData.completedFiles.length > 0) {
      const newMessageDocumentObject = {
        id: new Date().getTime(),
        local_message: {
          id: new Date().getTime(),
          message: '', // No text as it's just an upload
          account_id: userId,
          chat_id: userId,
          is_mark_read: false,
          created_at: new Date().toISOString(), // Ensure consistent timestamp format
          updated_at: new Date().toISOString(),
          type: 'sender',
          attachments: images,
        },
        twilio_message: {}, // If needed, provide empty object or values
      };

      // Clear the upload data state


      // Update message list based on existing messages
      this.setState(
        (prevState) => ({
          dummyMassageList: [...prevState.dummyMassageList, newMessageDocumentObject],
          dummyNewMessage: '',
          uploadDocumentData: { uploadFiles: [], completedFiles: [] },
          uploadDocumentTypeError: "",
        }),
        () => {
          this.sendMessageToserver();
        }
      );
    }

  };


  loadConversations = async (client: { getSubscribedConversations: () => any; }) => {
    const conversations = await client.getSubscribedConversations();
    this.setState({ conversations: conversations.items });
  };

  // Fetch messages from a specific conversation
  loadMessages = async (conversation: string) => {
    this.setState({ activeConversation: conversation, });
  };

  // Send message to the active conversation
  sendMessage = async () => {
    const { activeConversation, messageInput } = this.state;
    if (activeConversation && messageInput) {
      await activeConversation.sendMessage(messageInput);
      this.setState({ messageInput: '' });
      this.loadMessages(activeConversation); // Refresh messages after sending
    }
  };
  //twilio chat
  formatDate(createdAt: MomentInput) {
    const date = moment(createdAt);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    if (date.isSame(today, 'day')) {
      return "Today";
    } else if (date.isSame(yesterday, 'day')) {
      return "Yesterday";
    } else {
      return date.format('DD/MM/YY'); // Format as '24/10/24'
    }
  }
  GetUserConversationData = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    this.getCoversationListId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.getCoversationendpoint}?conversation_sid=${this.props.conversationId}&chat_id=${this.props.userId}`,
      token: userInfoToken
    });
  };
  getAccessToken = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    this.accessTokenApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.accessTokenendPoint,
      token: userInfoToken
    });
  };
  getTotalUnreadCounts=async()=>{
    const userInfoToken = localStorage.getItem("login_token");
    this.getTotalUnreadMessage = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getunreadCount,
      token: userInfoToken
    });
    
  }
  apiCall = async (data: IApiModel) => {
    const { method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    header = {
      token: token,
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleResponseData(response: any) {
    if (!response.errors) {
      const messages = response.response || [];
      let replyMessageData = {
        messageId: 0,
        message: "",
        image: "",
        type: "",
        message_sid: ""
      };
      const replyMessage = messages.find(
        (message: any) => message.twilio_message?.reply_message
      );
      if (replyMessage) {
        const localMessage = replyMessage.local_message || {};
        const twilioMessage = replyMessage.twilio_message || {};
        replyMessageData = {
          messageId: localMessage.id || 0,
          message: localMessage.message || "",
          image: localMessage.attachments ? localMessage.attachments[0]?.url || "" : "",
          type: localMessage.type || "",
          message_sid: twilioMessage.reply_message || ""
        };
      }
      // Update state
      this.setState({
        dummyMassageList: messages,
        replyMessageData,
        isLoading: false
      });
      this.getTotalUnreadCounts();
    } else {
      this.setState({
        dummyMassageList: [],
        isLoading: false
      });
    }
  }
  
  handleSendMessageResponse(response: any) {
    this.setState({dummyNewMessage:''})
    if (response) {
      if (response?.data) {
        this.setState({ isLoading: false,dummyMassageList: response.response,
         })
      } if (response.errors) {
        this.setState({OpenMessagePopUp:true})
        // this.showAlert('!Alert', response.errors)
      }
    }

  }
  handleSendMessageResponse2(response: any) {
    if (response) {
      if (response.access_token) {
        this.setState({ accessToken: response.access_token, errorMessage: '' }); // Clear error
      } else if (response.errors) {
        this.setState({ errorMessage: response.errors }); // Store the error message in state
      }
    }
  }
  handletotalunreadMessage(response:any){
    if(response){
      if(response?.total_unread_count){
        this.setState({total_unread_count:response?.total_unread_count})
        const unreadCount = response.total_unread_count ?? 0; // Fallback if undefined
        localStorage.setItem('total_unread_count', JSON.stringify(unreadCount));
        this.forceUpdate()
      }else{
      const unreadCount = 0; // Fallback if undefined
      localStorage.setItem('total_unread_count', JSON.stringify(0));
      this.forceUpdate()
      }
    }
  }
  checklastmsg() {
    const lastMessage = this.state.dummyMassageList[this.state.dummyMassageList.length - 1];
    const attachments = lastMessage?.local_message?.attachments || [];
    return attachments
  }
  sendMessageToserver = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    const formdata = new FormData();
    formdata.append("chat_id", this.props.userId.toString());
    formdata.append("conversation_sid", this.props.conversationId);
    if (this.state.replyToMessage?.twilio_message?.message_sid) {
      formdata.append("message_sid", this.state.replyToMessage?.twilio_message?.message_sid.toString() || "");
    }

    if (this.state.dummyNewMessage !== '') {
      formdata.append("message", this.state.dummyNewMessage);
      this.sendMessagePostApi = await this.apiCall({
        method: configJSON.postApiMethod,
        endPoint: configJSON.sendMessageEndpont,
        token: userInfoToken,
        body: formdata,
        isJsonStringify: false,
      });
    } else {
      this.sendAttachmentToServer()
    }

  };
  sendAttachmentToServer = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    const formdata = new FormData();
    formdata.append("chat_id", this.props.userId.toString());
    formdata.append("conversation_sid", this.props.conversationId);
    formdata.append("message", this.state.dummyNewMessage);
    //add message-id for reply message for media and body
    const attachments = await this.checklastmsg()
    if (Array.isArray(attachments) && attachments.length > 0) {
      for (const attachment of attachments) {
        if (attachment.url) {
          // Fetch and convert the URL to a Blob object
          const fileBlob = await fetch(attachment.url).then((res) => res.blob());
          // Append the file to FormData using "attachments[]" format
          formdata.append("attachments[]", fileBlob, attachment.file_name);
        }
      }
    }
    this.sendMessagePostApi = await this.apiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.sendMessageEndpont,
      token: userInfoToken,
      body: formdata,
      isJsonStringify: false,
    });
  }

 
  
  async initializeTwilioClient() {
    const client = new Client(this.props.userAccessToken);  // Access token fetched from backend
    client.on('initialized', () => {
      this.setState({ twilioClient: client });
      this.setupLiveChatListeners(client);  // Start listening for live events after client initialization
    });

  }

  setupLiveChatListeners(client: any) {
    if (this.messageListener) {
      return; // Prevent duplicate subscriptions
    }else{
      this.messageListener = client.on('messageAdded', (message:any) => {
        this.handleIncomingMessage(message);
      });
    }
  }
  // Handler for incoming messages
  handleIncomingMessage = (message: any) => {
    const { conversationId } = this.props; // Active conversation ID (passed as prop or from state)
    const { dummyMassageList } = this.state;
    // Filter messages that belong to the active conversation
    if (message.conversation.sid !== conversationId) {
      return; // Ignore messages not related to the active conversation
    }
    // Check if the message is already in dummyMassageList based on type (receiver/sender) and message body
    const isDuplicate = dummyMassageList.some(
      (msg: { local_message: { message: any; type: string; }; }) =>
        msg.local_message &&
        msg.local_message.message === message.state.body &&
        msg.local_message.type === "sender"
    );
    if (isDuplicate) {
      return; // Ignore duplicate messages
    }
    const newMessage = {
      id: message.state.sid,
      local_message: {
        id: message.state.sid,
        message: message.state.body,
        account_id: message.state.author,
        chat_id: message.conversation.sid,
        is_mark_read: message.state.read,//need to check
        created_at: message.state.dateCreated,
        updated_at: message.state.dateUpdated,
        type: "receiver", // Mark as received message
        attachments: [],
      },
      twilio_message: {
        body: message.state.body,
        author: message.state.author,
        message_sid: message.state.sid,
        conversation_sid: message.conversation.sid,
        account_sid: message.accountSid,
        participant_sid: message.participantSid,
        type: message.type,
        date_created: message.state.dateCreated,
        date_updated: message.state.dateUpdated,
      },
    };
    this.setState((prevState) => ({
      dummyMassageList: [...prevState.dummyMassageList, newMessage]
    }));
    const messageDateLabel = this.formatDate(message.dateCreated);
      this.setState({
        lastDisplayedDate: messageDateLabel,
        dummyMsgDate: messageDateLabel,
      });
    this.scrollToBottom(); // Scroll to the bottom
  };
  async downloadMedia(mediaUrls: string[], type: 'image' | 'video') {
    const userInfoToken = localStorage.getItem("login_token");
    try {
      for (const [index, mediaUrl] of mediaUrls.entries()) {
        const response = await fetch(mediaUrl, {
          headers: {
            Authorization: `Bearer ${userInfoToken}`,
          },
        });
  
        if (!response.ok) throw new Error('Failed to fetch media');
  
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = blobUrl;
  
        // Set a unique download filename for each media
        link.download = type === 'video' 
          ? `downloaded-video-${index + 1}.mp4` 
          : `downloaded-image-${index + 1}.jpg`;
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        URL.revokeObjectURL(blobUrl); // Clean up the Blob URL after download
      }
    } catch (error) {
      console.error(`Failed to fetch media at ${mediaUrls}`);
    }
  }
 
  handleClosePaymentMessageModal=()=>{
    this.setState({OpenMessagePopUp:false})
  }
  handleOpenPaymentMessage=()=>{
    this.setState({OpenMessagePopUp:true})
  }
  handleScroll = (event: { target: any; }) => {
    const container = event.target;
    const { dummyMassageList } = this.state;
  
    const containerMiddle = container.scrollTop + container.clientHeight / 2;
    let middleMessageDate = '';
  
    if (container.querySelectorAll) {
      const messages = Array.from(container.querySelectorAll('.message-item'));
  
      messages.some((msg:any) => {
        const msgOffsetTop = msg.offsetTop;
        const msgHeight = msg.offsetHeight;
  
        const messageId = msg.getAttribute('data-id');
        if (!messageId) {
          return false; // Skip this message
        }
        const currentMessage2 = dummyMassageList.find((m: { local_message: { id: { toString: () => any; }; }; }) => {
          return m.local_message?.id?.toString() === messageId.toString();
        });
  
        if (!currentMessage2) {
          return false; // Skip this message
        }
  
        if (
          msgOffsetTop <= containerMiddle &&
          msgOffsetTop + msgHeight >= containerMiddle
        ) {
          middleMessageDate = this.formatDate(currentMessage2.local_message.created_at);
          return true; // Exit loop once the middle message is found
        }
  
        return false;
      });
  
      this.setState({ lastDisplayedDate: middleMessageDate, dummyMsgDate: middleMessageDate });
    } else {
      console.error('Container does not support querySelectorAll');
    }
  };
  
  
  // Customizable Area End
}
