import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import { checkCondition } from "../../../../components/src/HelperUtils";
import i18n from "../../../../components/src/i18next/i18n";
export const configJSON = require("../config.js");
interface IFormValue {
  spaceTitle: string;
  capacity: string;
  allowedPet: string;
  description: string;
  images: File[]
}
interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}

interface IAddSpaceResponse {
  data:{
    id:number,
    type:string,
    attributes:{}
  }
  errors:string[]
}
interface ISpaceAttribute {
  id: number,
  title: string,
  capacity: number,
  allows_pet: string,
  description: string,
  duration: null,
  shift: null,
  service_id: number,
  created_at: string,
  updated_at: string,
  images_urls: IImageResponse[],
  
}
interface ISpace {
  id:number;
  type: string;
  attributes: ISpaceAttribute;
}

interface IGetSpaceResponse {
  data:ISpace[]
}

interface IImageResponse {
    id: number,
    file_name: string,
    file_url: string,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedService:IFilteredService;
  getServicesList:(id:string) => void;
  closePricesSections: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDialogOpen: boolean;
  uploadImageArray: File[];
  errorState: string;
  isViewDialogOpen: boolean;
  isEdit: boolean;
  getSpaceList:ISpace[],
  getSpaceListLoading:boolean,
  selectedViewData:ISpace,
  spaceFormInitialValue:IFormValue,
  saveUpdateLoading: boolean,
  openCreateSpace:boolean,
  openSimpleModal:boolean, 
  messageSimpleModal:string,
  //VIEW IMAGE
  isSpaceImgSlider: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SpacesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addSpaceApiCallId : string = "";
  getSpaceApiCallId : string = "";
  updateSpaceApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isDialogOpen: false,
      uploadImageArray: [],
      errorState: "",
      isViewDialogOpen: false,
      isEdit: false,
      getSpaceList:[],
      selectedViewData:{} as ISpace,
      getSpaceListLoading:false,
      spaceFormInitialValue:{  spaceTitle: "",
      capacity: "",
      allowedPet: "",
      description: "",
      images:[],
    },
    saveUpdateLoading: false,
    openCreateSpace:false,
    openSimpleModal:false,
    messageSimpleModal:"",
    //VIEW IMAGE
    isSpaceImgSlider: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.addSpaceApiCallId:
            this.handleAddSpaceResponse(responseJson);
            break;
          case this.getSpaceApiCallId:
            this.handleGetSpaceResponse(responseJson);
            break;
          case this.updateSpaceApiCallId:
            this.handleUpdateSpaceResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      uploadImageArray: [],
      errorState: "",
      isEdit: false,
      spaceFormInitialValue:{
        spaceTitle: "",
        capacity: "",
        allowedPet: "",
        description: "",
        images: []
      }
    });
  };

  handleAddNewSpacesClick = () => {
    const emptyServiceId = this.props.selectedService.service_id === "";
      if (emptyServiceId) {
        {
          this.setState({
            openCreateSpace: true
          })
        }
      }
      else {
        this.setState({
          isDialogOpen: true
        })
      }
  };

  handleFormSubmit = (values: IFormValue) => {
    this.addSpaceApiCall(values);
  };

  handleViewDialogClose = () => {
    this.setState({
      isViewDialogOpen: false,
      isDialogOpen: false,
      isEdit: false,
      selectedViewData:{} as ISpace,
    });
  };

  handleViewClick = (selectId:number) => {
    const selectedData = this.state.getSpaceList.find((item) => item.id === selectId) as ISpace;
    this.setState({ isViewDialogOpen: true, selectedViewData:selectedData});
  };

  handleViewEditClick = async () => {
    const data = this.state.selectedViewData;
    const imageList = await this.handleSelectedFile(data.attributes.images_urls);
    this.setState({
      isDialogOpen: true,
      isViewDialogOpen: false,
      isEdit: true,
      spaceFormInitialValue:{
        spaceTitle: data.attributes.title ,
        capacity: data.attributes.capacity.toString(),
        allowedPet: data.attributes.allows_pet,
        description: data.attributes.description,
        images:imageList
      }
    });
  };

  apiCall = async (data: IApiModel) => {
    const {
      contentType,
      method,
      endPoint,
      body,
      token,
      isJsonStringify,
    } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async componentDidMount() {
    super.componentDidMount();
    if(this.props.selectedService.service_id){
      this.getSpaceApiCall(); 
    }
  }

  addSpaceApiCall = async(values: IFormValue) => {
    let newSpaceData = new FormData();
    newSpaceData.append("sub_service[title]",values.spaceTitle);
    newSpaceData.append("sub_service[capacity]",values.capacity);
    newSpaceData.append("sub_service[allows_pet]",values.allowedPet);
    newSpaceData.append("sub_service[description]",values.description);
    newSpaceData.append("sub_service[service_id]",this.props.selectedService.service_id);
    if (values.images.length > 0) {
      values.images.forEach((file) => {
      newSpaceData.append("sub_service[images][]", file);
      });
      }
   
    const token = await getStorageData("login_token");
    if (token) {
      if (!this.state.isEdit) {
        this.setState({ saveUpdateLoading: true })
        this.addSpaceApiCallId = await this.apiCall({
          method: configJSON.httpPostMethod,
          token: token,
          endPoint: configJSON.addSpaceEndPoint,
          body: newSpaceData,
        });
      } else {
        this.setState({ saveUpdateLoading: true })
        this.updateSpaceApiCallId = await this.apiCall({
          method: configJSON.httpPutMethod,
          token: token,
          endPoint: `${configJSON.updateSpaceEndPoint}${this.state.selectedViewData.id}?service_id=${this.props.selectedService.service_id}`,
          body: newSpaceData,
        });
      }
    }
  };

  handleAddSpaceResponse = (responseJson:IAddSpaceResponse) => {
    this.setState({ saveUpdateLoading: false })
    if(responseJson && responseJson.data && responseJson.data.attributes){
      this.handleDialogClose();
      this.getSpaceApiCall();
      this.props.closePricesSections()
    }
    if (responseJson && responseJson.errors) {
      this.setState({
        openSimpleModal: true,
        messageSimpleModal: checkCondition(Boolean(responseJson.errors.length), responseJson.errors[0], "") as string
      })
    }
  };

  getSpaceApiCall = async () => {
    const token = await getStorageData("login_token");
    if (token) {
      this.setState({getSpaceListLoading:true,getSpaceList:[]})
      this.getSpaceApiCallId = await this.apiCall({
        method: configJSON.httpGetMethod,
        token: token,
        endPoint: `${configJSON.getSpaceEndPoint}${this.props.selectedService.service_id}`,
      });
    }
  };

  handleGetSpaceResponse = (responseJson:IGetSpaceResponse) => {
    this.setState({getSpaceListLoading:false})
    if(responseJson && responseJson.data && responseJson.data.length){
      this.setState({getSpaceList:responseJson.data})
    }
    else{
      this.setState({getSpaceList:[]})
    }
  }

  handleSelectedFile = async (
    images: IImageResponse[],
  ) => {
    return await Promise.all(
      images.map(async (image) => {
        const blob = await fetch(image.file_url).then((res) => res.blob());
        const urlParts = image.file_name.split(".");
        const fileExtension = urlParts.pop() || "jpg";
        let mimeType = "image/jpg";
        if (fileExtension === "png") {
          mimeType = "image/png";
        }

        const fileName = `backend-image-${image.file_name}`;

        return new File([blob], fileName, {
          type: mimeType,
        });
      })
    );
  };

  handleUpdateSpaceResponse = (responseJson: IAddSpaceResponse) => {
    this.setState({ saveUpdateLoading: false })
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.handleDialogClose();
      this.getSpaceApiCall();
      this.setState({
        spaceFormInitialValue: {
          spaceTitle: "",
          capacity: "",
          allowedPet: "",
          description: "",
          images: []
        }
      })
    }
    if (responseJson && responseJson.errors) {
      this.setState({
        openSimpleModal: true,
        messageSimpleModal: checkCondition(Boolean(responseJson.errors.length), responseJson.errors, "") as string
      })
    }
  };

  handleHiddenClick = () => {
    this.setState({isEdit:true});
  }

  handleCreateSpaceDialogClose = () => {
    this.setState({openCreateSpace:false})
  }

  simpleModalClose = () => {
    this.setState({
      openSimpleModal: false,
      messageSimpleModal: ""
    })
  }

  spaceImageBackClick = () => {
    this.setState({ isSpaceImgSlider: false })
  }

  spaceImageClick = () => {
    this.setState({ isSpaceImgSlider: true })
  }

  transSpace = (spaceKey: string) => {
    if (spaceKey !== "") {
      return i18n.t(`generalInfo.${spaceKey}`, { ns: "establishmentService" });
    }
  }
  // Customizable Area End
}
