import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IFilteredService } from "../../../components/src/interfaces.web";
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";

interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface IImage {
  id: number;
  url: string
}
interface IUploadImage {
  id: null | number;
  url: string;
  file?: File;
}

interface IUploadResponse {
  galleries: IImage[],
  meta: {
    message: string
  }
}

interface IDeleteResponse {
  meta: {
    message: string
  },
  error?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedService: IFilteredService;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uploadedFiles: IUploadImage[];
  isEditImage: boolean;
  serviceImageListResponse: IImage[];
  deleteImageIds: number[]
  //LOADER
  isSaveLoading: boolean;
  isGetListLoader: boolean;
  uploadGalleryLoader: boolean;
  deleteGalleryLoader: boolean;
  OpenGallaryPopUp:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomizableVendorGalleryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getServiceGalleryListId: string = "";
  uploadServiceGalleryId: string = "";
  deleteServiceGalleryId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    ];

    this.state = {
      uploadedFiles: [],
      isEditImage: false,
      serviceImageListResponse: [],
      deleteImageIds: [],
      //LOADER
      isSaveLoading: false,
      isGetListLoader: false,
      uploadGalleryLoader: false,
      deleteGalleryLoader: false,
      //PopUp
      OpenGallaryPopUp:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.getServiceGalleryListId:
          this.handleGetServiceListResponse(responseJson);
          break;
        case this.uploadServiceGalleryId:
          this.handleUploadGalleryResponse(responseJson);
          break;
        case this.deleteServiceGalleryId:
          this.handleDeleteGalleryResponse(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    if (this.props.selectedService.service_id) {
      this.getGalleryAPI();
    }
  }

  handleMediaSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const mediaFiles: IUploadImage[] = Array.from(files).map((file) => ({
        id: null,
        url: URL.createObjectURL(file),
        file: file
      }));

      this.setState((prevState) => ({
        uploadedFiles: [...prevState.uploadedFiles, ...mediaFiles],
        isEditImage: true
      }));
    }
  };

  handleEditClick = () => {
    this.setState({ isEditImage: true })
  }

  handleCancelClick = () => {
    this.setState({ isEditImage: false, uploadedFiles: this.state.serviceImageListResponse, deleteImageIds: [] })
  }

  handleRemoveFile = (fileToRemove: File, imageId: number | null) => {
    if (imageId !== null) {
      const fileToDelete = this.state.uploadedFiles.filter((mediaFile) => mediaFile.id !== imageId)
      this.setState((prevState) => ({
        deleteImageIds: [...prevState.deleteImageIds, imageId],
        uploadedFiles: fileToDelete,
      }));
    } else {
      this.state.uploadedFiles.forEach((mediaFile) => {
        if (mediaFile.file === fileToRemove) {
          URL.revokeObjectURL(mediaFile.url);
        }
      });
      this.setState((prevState) => ({
        uploadedFiles: prevState.uploadedFiles.filter((mediaFile) => mediaFile.file !== fileToRemove),
      }));
    }
  };

  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getGalleryAPI = async () => {
    this.setState({ isGetListLoader: true, serviceImageListResponse: [] })
    if (this.props.selectedService.service_id) {
      const userInfoToken = await getStorageData("login_token");
      this.getServiceGalleryListId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: `${configJSON.showGallery}?id=${this.props.selectedService.service_id}`,
        token: userInfoToken,
      });
    }
  };

  handleGetServiceListResponse = (responseJSON: IUploadResponse) => {
    this.setState({ isGetListLoader: false })
    if (responseJSON.galleries && responseJSON.galleries.length) {
      this.setState({ serviceImageListResponse: responseJSON.galleries, uploadedFiles: responseJSON.galleries })
    }
  }

  handleSaveClick = () => {
    const { uploadedFiles, deleteImageIds } = this.state;
    const newImageList = uploadedFiles.filter((mediaFile) => mediaFile.id === null);
    if((deleteImageIds.length > 0 || newImageList.length > 0)){
      this.setState({ isSaveLoading: true });
    }
    if (newImageList.length > 0) {
      this.uploadImageAPI(newImageList);
    }
    if (deleteImageIds.length > 0) {
      this.deleteImageAPI();
    }
  }

  uploadImageAPI = async (newImageList: IUploadImage[]) => {
    let imagesFormData = new FormData();
    if (newImageList.length > 0) {
      newImageList.forEach((file) => {
        if (file.file) {
          imagesFormData.append("service[service_galleries][]", file.file);
        }
      });
    }
    this.setState({ uploadGalleryLoader: true });
    if (this.props.selectedService.service_id) {
      const token = await getStorageData("login_token");
      this.uploadServiceGalleryId = await this.apiCall({
        method: configJSON.httpPutMethod,
        endPoint: `${configJSON.ENDPOINTS.UPDATE_SERVICE_GALLERY}${this.props.selectedService.service_id}`,
        body: imagesFormData,
        token: token,
      });
    }
  }

  deleteImageAPI = async () => {
    this.setState({ deleteGalleryLoader: true });
    if (this.props.selectedService.service_id) {
      const token = await getStorageData("login_token");
      this.deleteServiceGalleryId = await this.apiCall({
        method: configJSON.httpDeleteMethod,
        endPoint: `${configJSON.ENDPOINTS.DELETE_SERVICE_GALLERY}${this.props.selectedService.service_id}&gallery_ids[]=${this.state.deleteImageIds}`,
        token: token,
      });
    }
  }

  handleUploadGalleryResponse = (responseJson: IUploadResponse) => {
    this.setState({ uploadGalleryLoader: false });
    this.changeSaveLoadingStatus();
    if (responseJson && responseJson.galleries.length > 0) {
      this.setState({ uploadedFiles: [], serviceImageListResponse: [] })
    }
  }

  handleDeleteGalleryResponse = (responseJson: IDeleteResponse) => {
    this.setState({ deleteGalleryLoader: false });
    this.changeSaveLoadingStatus();
    if (responseJson && responseJson.meta.message) {
      this.setState({ deleteImageIds: [] })
    }
  }

  changeSaveLoadingStatus = () => {
    const { uploadGalleryLoader, deleteGalleryLoader } = this.state;
    if (!uploadGalleryLoader && !deleteGalleryLoader) {
      this.setState({ isSaveLoading: false, isEditImage: false });
      this.getGalleryAPI();
    }
  };
  handleCreateGallaryDailogOpen=()=>{
    this.setState({OpenGallaryPopUp:true})
  }
  handleCreateGallaryDialogClose=()=>{
    this.setState({OpenGallaryPopUp:false})
  }

  transGallery = (gKey: string) => {
    return i18n.t(`vendorProfile.${gKey}`, { ns: "establishmentService" });
  }
  // Customizable Area End
}