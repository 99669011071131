import { Box } from '@material-ui/core';
import React from 'react'
import Field from '../Field';
import { GenericFormikProps } from '../../types';
import { generateTimeRange } from '../../../../../components/src/HelperUtils';

type Props = GenericFormikProps & { isGrooming?: boolean }

const TimeChange = ({ event, errors, touched, getFieldProps, setFieldValue, values, renderCustomField, handleBlur, isGrooming }: Props) => {

    const getOptionList = () => {
        if(isGrooming) {
            const timeRange = generateTimeRange("00:00", "23:30", 30);
            const optionList = timeRange.map((item) => ({ label: item.name, value: item.value }))
            return optionList;
        } else {
            return [
                { label: "Morning", value: "Morning" },
                { label: "Evening", value: "Evening" },
            ]
        }
    }

    return (
        <Box className="form-row">
            <Box style={{ width: '100%', borderBottom: '1px solid #D9D9D9' }}>
                <Field title="Old Time" value={isGrooming ? (event.start_time || '---') : event.shift } withOpacity withPadding />
            </Box>
            <Box style={{ position: "relative", width: '100%' }}>
                {renderCustomField({
                    handleBlur,
                    errors,
                    field: isGrooming ? "start_time" : "shift",
                    disabledField: "isEditRequestChange",
                    values,
                    list: getOptionList(),
                    touched,
                    handleChange: (event: any) => {
                        setFieldValue(isGrooming ? "start_time" : "shift", event.target.value);
                    },
                    getFieldProps,
                    editLabel: {
                        enabled: true,
                        styles: { fontSize: "12px" },
                    },
                })}
            </Box>
        </Box>
    )
}

export default TimeChange;