import moment from "moment";
import React from "react";
import { Box, Typography, styled } from "@material-ui/core";
import { DayCellWrapperProps } from "./types";
import { Views } from "react-big-calendar";

const DayCellWrapper = styled('div')(({ theme }) => ({
    padding: "5px",
    position: "relative",
    backgroundColor: "transparent",
    zIndex: 15,
    color: "black",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: 'Montserrat',

    "& .content": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .today": {
      border: '1px solid #6F05EA',
      padding: '10px 10px',
      borderRadius: '10px',

      [theme.breakpoints.up("sm")]: {
        padding: '12px 16px',
      },
    },
    "& .active-day": {
      fontSize: '14px',
      fontWeight: 600
    },
    "& .active-today-day": {
      fontSize: '14px',
      fontWeight: 600,
      color: '#6F05EA'
    },
    "& .not-active-day": {
      fontSize: '14px',
      fontWeight: 600,
      color: '#B7B7B7'
    },
    "& .event-dot": {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      zIndex: 16,
    },

    "& .event-active": {
      background: "#6F05EA",
    }
}))

const DayCell: React.FC<DayCellWrapperProps> = ({
  children,
  value,
  events,
  calendarDate,
  view
}) => {
  const dayEvents = events.filter((event: any) => {
    const eventDate = new Date(event.start_date);
    return (
      eventDate.getFullYear() === value.getFullYear() &&
      eventDate.getMonth() === value.getMonth() &&
      eventDate.getDate() === value.getDate()
    );
  });
  const isCurrentMonth =
    value.getFullYear() === calendarDate.getFullYear() &&
    value.getMonth() === calendarDate.getMonth();

  const isToday = moment(value).isSame(moment(), 'day');

  const handleCurrentDate = () => {
    if(isToday) {
      return 'active-today-day'
    }

    return isCurrentMonth ? 'active-day' : 'not-active-day'
  }

  if (view === Views.WEEK) {
    return null;
  }

  return (
    <DayCellWrapper>
      <Box className={`content ${isToday && 'today'}`}>
        <Typography className={handleCurrentDate()}>
          {moment(value).format("DD")}
        </Typography>
        <Box className={`event-dot ${dayEvents.length > 0 && 'event-active'}`} />
      </Box>
    </DayCellWrapper>
  );
};

export default DayCell;
