import moment from "moment";
import { Request } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import { transPt } from "../HelperUtils";

type ReqData = {
    title: string,
    color: string,
    start?: string,
    end?: string
}

export const getRequestData = (request: Request): ReqData => {
    const data: ReqData = { title: '', color: '#D4AD2D' };
    const { created_by, status, request_type, end_date, start_date } = request;

    if(created_by === 'customer') {
        if(status === 'pending') {
            switch (request_type) {
                case 'Cancellation Request':
                    data.title = transPt('Cancellation request sent',"customerUserProfile");
                    break;
                case 'Transportation Request':
                    data.title = transPt('Transportation request sent',"customerUserProfile");
                    break;
                case 'Reschedule Request':
                    data.title = transPt("Change Request Sent","customerUserProfile");
                    if(start_date) data.start = `${transPt('Start Date',"customerUserProfile")}: ${moment(start_date).format('DD/MM/YYYY')}`;
                    if(end_date) data.end = `${transPt('End Date',"customerUserProfile")}: ${moment(end_date).format('DD/MM/YYYY')}`;
                default:
                    break;
            }
        }
    } else {
        if(request_type === 'Cancellation Request') {
            data.title = transPt('This appointment has been deleted by the vendor',"customerUserProfile")
            data.color = '#C10000'
        }
    }

    return data;
}