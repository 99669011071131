import React from 'react';
import { Box, Typography } from "@material-ui/core"
import { Request, Shedule } from "../CustomisableUserProfilesController";
import { Portrait } from '../assets';
import Field from "./Field";
import moment from "moment";
import { checkCondition, transPt } from '../../../../components/src/HelperUtils';

type Props = {
    request: Request | null;
    selectedEvent: Shedule;
    fieldList: Record<any, boolean>;
}

const getIsFromCustomer = (request: Request | null) => {
   return request?.created_by === 'customer' && request?.request_type === 'Reschedule Request' && request.status === 'pending'
}

const getCustomerFields = (selectedEvent: Shedule, fieldList: Record<any, boolean>) => {
    return (
        <>
        <Field data-test-id="date" title={fieldList.endDate ? transPt('Old Start Date',"customerUserProfile") : transPt('Old Date',"customerUserProfile")} value={moment(selectedEvent.start_date).format('DD/MM/YYYY')} />
        {fieldList.endDate && <Field data-test-id="end-date" title={transPt('Old End Date',"customerUserProfile")} value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
        {fieldList.time && <Field data-test-id="time" title={transPt('Old Time',"customerUserProfile")} value={selectedEvent.shift} />}
        <Field title={transPt('Price',"customerUserProfile")} value={`${selectedEvent.total_price}€`}/>
        </>
    )
}

const DetailsApointmentFields = ({ request, selectedEvent, fieldList }: Props) => {
    const fromCustomer = getIsFromCustomer(request);

    return (
        <Box className='section-wrapper'>
            <Box className='section-header'>
                <img src={Portrait} />
                <Typography className='section-text'>{transPt("Appointment Details", "customerUserProfile")}</Typography>
            </Box>
            <Box className='fields-wrapper'>
                <Box className='field-set'>
                    {request && fromCustomer ? 
                        getCustomerFields(selectedEvent, fieldList) :
                        <>
                            <Field title={checkCondition(fieldList.endDate,transPt("Start Date", "customerUserProfile"),transPt("Date", "customerUserProfile")) as string} value={moment(selectedEvent.start_date).format('DD/MM/YYYY')}/>
                            <Field title={transPt("Price", "customerUserProfile")} value={`${selectedEvent.total_price}€`}/>
                        </>
                    }
                </Box>
                <Box className='field-set'>
                    {request && fromCustomer ? 
                        <>
                            <Field title={checkCondition(fieldList.endDate, transPt("Updated Start Date", "customerUserProfile") , transPt("Updated Date", "customerUserProfile")) as string} value={moment(request.start_date).format("DD/MM/YYYY")} />
                            {fieldList.endDate && <Field title={transPt("Updated End Date", "customerUserProfile")} value={moment(request.end_date).format('DD/MM/YYYY')} />}
                            {fieldList.time && <Field title={transPt("Updated Time", "customerUserProfile")} value={request.shift} />}
                            <Field title={transPt("Service Type", "customerUserProfile")} value={selectedEvent.service.service_type}/>
                        </> : 
                        <>
                            {fieldList.endDate && <Field title={transPt("End Date", "customerUserProfile")} value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
                            {fieldList.time && <Field title={transPt("Time", "customerUserProfile")} value={selectedEvent.shift} />}
                            {fieldList.timeGrooming && <Field title={transPt("Time", "customerUserProfile")} value={moment(selectedEvent.start_time, "HH:mm").format("hh:mm A")} />}
                            <Field title={transPt("Service Type", "customerUserProfile")} value={selectedEvent.service.service_type}/>
                        </>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default DetailsApointmentFields;