import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import React, { CSSProperties } from "react";

import { Formik } from "formik";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import PageLoader from "../../../components/src/PageLoader.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { LanguageCtx } from "../../../components/src/context/LanguageContext";

import {
  AddIcon,
  closeIcon,
  RemoveIcon,
  SuccessIcon,
} from "./assets";
import { LoadingButton } from "../../../components/src/HelperComponents";

export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  static contextType = LanguageCtx;
  render() {
    const { languageData }=this.context;
    const contactUsfile = languageData?.translation?.contactus;
    const pageTitle = contactUsfile?.Contact_Us;
    const labelSubject = contactUsfile?.Subject;
    const subjectPlaceHolder = contactUsfile?.subjectPlaceHolder;
    const detailsPlaceHolder = contactUsfile?.detailsPlaceHolder;
    const labelDetails = contactUsfile?.labelDetails;
    const labelAttachFiles = contactUsfile?.labelAttachFiles;
    const uploadBoxText = contactUsfile?.uploadBoxText;
    const tipMessage = contactUsfile?.tipMessage;
    const btnSend = contactUsfile?.btnSend;
    const modalTitle = contactUsfile?.modalTitle;
    const modalDescription = contactUsfile?.modalDescription;
    const modalDoneBtn = contactUsfile?.modalDoneBtn

    return (
      <PageLoader loading={!this.state.token}>
        <AppHeader navigation={this.props.navigation}/>
        <ContactUsPageWrapper maxWidth="md">
          <Box
            className="ContactUs-Page-Conteiner"
            data-test-id="page-container"
          >
            <Typography className="ContactUs-Page-Title">
              {pageTitle}
            </Typography>
            <Formik
              initialValues={{
                subject: "",
                details: "",
                files: [],
              }}
              validationSchema={this.state.validationShema}
              enableReinitialize
              data-test-id="Formik-ContactUs-Form"
              onSubmit={(value) => {
                this.sendFormData(value);
              }}
            >
              {({
                touched,
                errors,
                getFieldProps,
                handleSubmit,
                setFieldValue,
                values,
                resetForm,
              }) => {
                this.resetForm = resetForm;
                return (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="ContactUs-Page-Content"
                    >
                      <Box>
                        <InputLabel className="ContactUs-Input-Label">
                         {labelSubject}
                        </InputLabel>
                        <StyledInputField
                          {...getFieldProps("subject")}
                          {...this.subjectInputProps}
                          placeholder={subjectPlaceHolder}
                          error={touched.subject && Boolean(errors.subject)}
                          helperText={
                            touched.subject && errors.subject && errors.subject
                          }
                        />
                      </Box>
                      <Box>
                        <InputLabel className="ContactUs-Input-Label">
                          {labelDetails}
                        </InputLabel>
                        <StyledInputField
                          {...getFieldProps("details")}
                          {...this.detailsInputProps}
                          multiline
                          autoComplete="off"
                          className="details-input"
                          placeholder={detailsPlaceHolder}
                          error={touched.details && Boolean(errors.details)}
                          helperText={
                            touched.details && errors.details && errors.details
                          }
                        />
                        <Box className="ContactUs-Counter-Box">
                          <Typography>{`${values.details.length}/1000`}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <InputLabel className="ContactUs-Input-Label">
                          {labelAttachFiles}
                        </InputLabel>
                        <Box className="ContactUs-Attachments-Box">
                          <input
                            {...this.attachmentInputProps}
                            ref={this.fileInputRef}
                            data-test-id="file-input"
                            onChange={(event) => {
                              const fileList = event.currentTarget.files;
                              if (fileList) {
                                const files = Array.from(fileList);
                                const newFiles = [...values.files, ...files];
                                setFieldValue('files', newFiles);
                              }
                            }
                            }
                          />
                          <Box className="ContactUs-AddFile-Wrapper">
                            <Box
                              id="file-click-box"
                              data-test-id="file-box"
                              className="ContactUs-AddFile-Box"
                              onClick={this.handleFileClick}
                            >
                              <img src={AddIcon} />
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {uploadBoxText}
                                <br />
                                {configJSON.uploadBoxExt}
                              </Typography>
                            </Box>
                            {values.files.map((file: File, index) => (
                              <Box
                                style={{ position: "relative" }}
                                key={`${file.name}-${file.size}-${index}`} 
                                data-test-id={`preview-file-${index}`}
                              >
                                <img {...this.imageInputProps(file)} />
                                <Box
                                  className="ContactUs-Delete-Icon"
                                  data-test-id={`preview-file-remove-${index}`}
                                  onClick={() => {
                                    const updatedFiles = values.files.filter((_, i) => i !== index);
                                    setFieldValue('files', updatedFiles);
                                  }} 
                                >
                                  <img src={RemoveIcon} />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        {errors.files ? (
                          <Typography className="ContactUs-Errors-File">
                            {errors.files}
                          </Typography>
                        ) : (
                          <Typography className="ContactUs-Tip">
                            {tipMessage}
                          </Typography>
                        )}
                      </Box>
                      <StyledLoadingBtn
                      buttonText={btnSend} 
                      isLoading={this.state.isLoading}
                      {...this.btnSendProps}
                      type="submit"
                      variant="contained"                        
                      />
                    </form>
                  </>
                );
              }}
            </Formik>
          </Box>
        </ContactUsPageWrapper>
        <StyledDialogContainer
        open={this.state.isShowModal}
        fullWidth
        aria-labelledby="add-services-id"
        data-test-id="success-modal"
      >
        <Box>
            <Box style={modalStyles.header}>
              <DialogTitle
                id="modal-title"
              >
                {modalTitle}
              </DialogTitle>
              <DialogCloseBtn
                data-test-id="close-icon"
                onClick={() => this.setState({ isShowModal: false })}
                aria-label="close"
              >
                <img src={closeIcon} />
              </DialogCloseBtn>
            </Box>
            <Box id="modal-description" style={modalStyles.description}>
              <img src={SuccessIcon} />
              <DialogDescription>
                {modalDescription}
              </DialogDescription>
              <DoneButton
                data-test-id="done-btn"
                onClick={() => this.setState({ isShowModal: false })}
                fullWidth
                disabled={this.state.isLoading}
              >
                {modalDoneBtn}
              </DoneButton>
            </Box>
          </Box>
      </StyledDialogContainer>
        <AppFooter navigation={this.props.navigation}/>
      </PageLoader>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const modalStyles: Record<any, CSSProperties> = {
  modal: {
    fontFamily: "Montserrat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "1000px",
    padding: "32px 40px",
    overflow: "auto",
    maxHeight: "554px",
    borderRadius: 4,
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  description: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "14px",
    paddingTop: "30px",
    textAlign: "center",
  },
  text: {
    fontWeight: 600,
    maxWidth: "400px",
    fontSize: "20px",
    fontFamily: "Montserrat",
  },
  button: {
    border: "1px solid #6F05EA",
    color: "#6F05EA",
    borderRadius: "100px",
    padding: "10px 0",
    height: "56px",
    textTransform: "none",
    background: "#fff",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 500,
    maxWidth: "586px",
  },
};

const headerStyles: Record<any, CSSProperties> = {
  wrapper: {
    minHeight: "127px",
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(#6928F2, #884FFF)",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  user: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    gap: "19px",
  },
  language: {
    display: "flex",
    gap: "18px",
    alignItems: "center",
    paddingRight: "12px",
  },
};

const ContactUsPageWrapper = styled(Container)({
  display: "flex",
  padding: '0 36px',
  flex: 1,
  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },
  "& .ContactUs-Page-Conteiner": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    paddingTop: "96px",
    paddingBottom: "80px",
    "@media (max-width: 600px)": {
      paddingTop: "10px",
      paddingBottom: "9px",
      },
      "@media (max-width: 400px)": {
        paddingTop: "10px",
        paddingBottom: "8px",
      },
    fontFamily: "Montserrat",
    "& .details-input":{
      "& .MuiInput-root":{
        height:"auto"
      }
    }  
  },
  "& .ContactUs-Page-Title": {
    fontSize: "40px",
    "@media (max-width: 600px)": {
      fontSize: "22px",
      },
      "@media (max-width: 400px)": {
        fontSize: "22px",
      },
    lineHeight: "106px",
    fontWeight: 600,
    marginBottom:"24px"
  },
  "& .ContactUs-Attachments-Box": {
    paddingTop: "12px",
  },
  "& .ContactUs-AddFile-Wrapper": {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
  },
  "& .ContactUs-AddFile-Box": {
    width: "88px",
    height: "88px",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  "& .ContactUs-Img-Preview": {
    width: "88px",
    height: "88px",
    objectFit: "cover",
    borderRadius:"6px"
  },
  "& .ContactUs-Delete-Icon": {
    position: "absolute",
    right: "-8",
    top: "-8",
    cursor:"pointer"
  },
  "& .ContactUs-Counter-Box": {
    marginTop: "15px",
    textAlign: "end",

    "& .MuiTypography-root.MuiTypography-body1": {
      color: "#6F05EA",
      fontSize: "13px",
    },
  },
  "& .ContactUs-Page-Content": {
    display: "flex",
    flexDirection: "column",
    gap: "44px",
    marginTop:"0px",
    "@media (max-width: 600px)": {
      marginTop: "-15px",
      },
      "@media (max-width: 400px)": {
        marginTop: "-15px",
      },
  },
  "& .ContactUs-Submit-Btn": {
    border: "1px solid #6F05EA",
    width: "146px",
    height: "40px",
    color: "#6F05EA",
    borderRadius: "100px",
    padding: "10px 0",
    background: "#fff",
    alignSelf: "end",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
  },
  "& .ContactUs-Errors-File": {
    color: "#FF2828",
    marginTop: "10px",
    fontSize: "16px",
  },
  "& .ContactUs-Tip": {
    marginTop: "12px",
    fontSize: "12px",
    fontWeight:600
  },
  "& .ContactUs-Input-Label": {
    fontFamily: "Montserrat",
    color: "#6F05EA",
    fontSize: "13px",
    fontWeight: 400,
    marginBottom: "4px",
  },
});

const StyledInputField = styled(TextField)({
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiInput-root":{
    height:"34px"
  },
  "& .MuiInputBase-input.MuiInput-input": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    paddingLeft: "8px",
    color: "#7D7D7D",

    "&::placeholder": {
      color: "#7D7D7D",
      fontSize: "18px",
      opacity: 1,
    },
  },
  "& .MuiInput-underline::after": {
    borderBottom: "2px solid #6F05EA",
  },
  "& .MuiInput-underline.Mui-error::after": {
    borderBottom: "2px solid #EF4444",
  },
  "& .MuiInput-underline::after:hover": {
    borderBottom: "2px solid #6F05EA",
  },
});

const StyledLoadingBtn = styled(LoadingButton)({
  boxShadow:"none",
  cursor:"pointer",
  "&:hover":{
    boxShadow:"none",
  }
})

const StyledDialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 910,
      maxHeight: 554,
      padding: "40px 36px 53px 60px",
      "@media (max-width: 600px)": {
        padding: "40px 24px 53px 24px",
      },
      "@media (max-width: 400px)": {
        padding: "40px 24px 53px 24px",
      },
    },
  },
})

const DialogCloseBtn = styled(IconButton)({
  padding: 0,
  "&:hover":{
    backgroundColor:"transparent"
  }
})

const DialogTitle = styled(Typography)({
  fontSize: "32px",
  fontWeight: 700,
  fontFamily: "Montserrat",
  "@media (max-width: 960px)": {
    fontSize: "28px",
  },
  "@media (max-width: 600px)": {
    fontSize: "24px",
  },
  "@media (max-width: 400px)": {
    fontSize: "20px",
  },
})

const DialogDescription = styled(Typography)({
  maxWidth: "400px",
  fontSize: "20px",
  fontWeight: 600,
  fontFamily: "Montserrat",
  marginBottom: "80px",
  "@media (max-width: 960px)": {
    fontSize: "18px",
  },
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
  "@media (max-width: 400px)": {
    fontSize: "14px",
  },
})

const DoneButton = styled(Button)({
  border: "1px solid #6F05EA",
    height: "56px",
    color: "#6F05EA",
    borderRadius: "100px",
    padding: "10px 0",
    background: "#fff",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 500,
    maxWidth: "586px",
    "&:hover":{
      backgroundColor:"transparent"
    },
    "@media (max-width: 960px)": {
      fontSize: "22px",
    },
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
    "@media (max-width: 400px)": {
      fontSize: "18px",
    },
})
// Customizable Area End
