import React from "react";
// Customizable Area Start
import ViewCustomerController, { Props } from "./ViewCustomerController.web";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../../components/src/AppFooter/appFooter";
import {
    Avatar, Box, styled, Typography, Grid, TextField, InputAdornment,
    Chip,
    IconButton,
} from "@material-ui/core";
import { BookingHistory, CatAvatar, CustomerAvatar, DogAvatar, female, male } from "../assets";
import PhoneInput from "react-phone-input-2";
import Flag from 'react-world-flags';
import { appointmentsList } from "./AppoinmentsDashboardController.web";
import moment from "moment";
import { KeyboardArrowLeft } from "@material-ui/icons";

const petData = [
    {
        photo: DogAvatar,
        name: "Farrusco",
        breed: "Mixed breed",
        gender: "male",
        age: "3"
    },
    {
        photo: CatAvatar,
        name: "Goldie",
        breed: "Orange tabby cat",
        gender: "female",
        age: "3"
    },
    {
        photo: DogAvatar,
        name: "Farrusco",
        breed: "Mixed breed",
        gender: "male",
        age: "3"
    },
    {
        photo: CatAvatar,
        name: "Goldie",
        breed: "Orange tabby cat",
        gender: "female",
        age: "3"
    }
]
const getGenderIcon = (gender: string) => {
    if (gender == "female") {
        return female;
    }

    return male;
};
const getChipColor = (status: string) => {
    let color = ""
    let bgColor = ""
    switch (status) {
        case "Booked":
            color = "#F59E0B"
            bgColor = "#FEF3C7"
            break;
        case "Completed":
            color = "#059669"
            bgColor = "#D1FAE5"
            break;
        case "Cancelled":
            color = "#C10000"
            bgColor = "#FEE2E2"
            break;
    }
    return { color, bgColor }

}
// Customizable Area End

export default class ViewCustomer extends ViewCustomerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderPersonalDetails = () => {
        return (
            <>
                <GridContainer container>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>First Name</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"Eleanor"}
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Last Name</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"Pena"}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Language</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"English"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Avatar style={{ width: '18px', height: '18px', overflow: 'hidden', marginLeft: "8px" }}>
                                            <Flag code="us" style={{ width: 'auto', height: '100%' }} />
                                        </Avatar>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Nationality</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"Select your origin country"}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Phone</CustomLabel>
                        <PhoneInput
                            buttonStyle={style.phoneButtonStyle}
                            inputStyle={style.phoneInputStyle}
                            dropdownStyle={style.phoneDropDownStyle}
                            placeholder="Your contact number"
                            value={"+351123456789"}
                            disabled
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Birthdate</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"27/04/2020"}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Address</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"Rua da Liberdade 34 4000-000"}
                        />

                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Country</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"Portugal"}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>City</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"Porto"}
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>Zip Code</CustomLabel>
                        <StyledField fullWidth disabled
                            value={"4000-000"}
                        />
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    renderPetDetails = () => {
        return (
            <>
                <ListWrapper>
                    <PetBoxWrapper>
                        {petData.map((record) => {
                            const petAge = `${record.age} years old`
                            return (
                                <PetBox>
                                    <PetAvatar src={record.photo} />
                                    <Box>
                                        <PetName>{record.name}</PetName>
                                        <PetBreed>{record.breed}</PetBreed>
                                    </Box>
                                    <PetAgeBox>
                                        <img src={getGenderIcon(record.gender)} />
                                        {petAge}
                                    </PetAgeBox>
                                </PetBox>
                            )
                        })}
                    </PetBoxWrapper>
                </ListWrapper>
            </>
        )
    }
    renderBookingDetails = () => {
        return (
            <>
                <BookingWrapper>
                    <TitleWrapper>
                        <img src={BookingHistory} />
                        <HistoryTitle>Booking History</HistoryTitle>
                    </TitleWrapper>
                    <BookingListWrapper>
                        {appointmentsList.map((record, index) => {
                            const status = record.service_status
                            const chipColor = getChipColor(status)
                            const serviceDateDay = moment(record.service_date).format("DD")
                            const serviceDateMonth = moment(record.service_date).format("MMM")
                            return (
                                <BookingBox key={index}>
                                    <CenterBox>
                                        <CenterBox width={60} flexDirection={"column"}>
                                            <RowFont>{serviceDateDay}</RowFont>
                                            <RowFont className="month">{serviceDateMonth}</RowFont>
                                        </CenterBox>
                                        <CenterBox width={130}>
                                            <RowFont noWrap title={record.service_type}>{record.service_type}</RowFont>
                                        </CenterBox>

                                        <CenterBox width={100}>
                                            <RowFont noWrap title={record.customer_phone}>{record.customer_phone}</RowFont>
                                        </CenterBox>
                                    </CenterBox>

                                    <CenterBox>
                                        <CenterBox width={96}>
                                            <CustomChip
                                                label={status}
                                                style={{ color: chipColor.color, backgroundColor: chipColor.bgColor }}
                                            />
                                        </CenterBox>
                                        <CenterBox width={55}>
                                            <RowFont>{record.service_charge}</RowFont>
                                        </CenterBox>
                                    </CenterBox>
                                </BookingBox>
                            )
                        })}
                    </BookingListWrapper>
                </BookingWrapper>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <NavigationMenu id={''} navigation={this.props.navigation} />
                <MainWrapper>

                    <BackBox>
                        <ArrowBtn disableRipple data-testid='back-to-list'>
                            <ArrowIcon /><BackTitle>Eleanor pena</BackTitle>
                        </ArrowBtn>
                    </BackBox>

                    <DetailsWrapper>
                        <WhiteBox>
                            <AvatarBox>
                                <StyledAvatar src={CustomerAvatar} />
                                <CustomerName>Eleanor pena</CustomerName>
                            </AvatarBox>
                            {this.renderPersonalDetails()}
                            {this.renderPetDetails()}
                            {this.renderBookingDetails()}
                        </WhiteBox>
                    </DetailsWrapper>

                </MainWrapper>
                <AppFooter navigation={this.props.navigation} />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
    backgroundColor: "#F6F4F9",
    padding: "32px"
})
const DetailsWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center"
})
const WhiteBox = styled(Box)({
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    width: "100%",
    maxWidth: "1040px",
    padding: "32px"
})

const AvatarBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 18
})
const StyledAvatar = styled(Avatar)({
    height: 142,
    width: 142
})
const CustomerName = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#000000"
})
const GridContainer = styled(Grid)({
    marginTop: "48px",
    padding: "24px"
})
const GridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    padding: "18px !important"
})
const CustomLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#777777",
    marginBottom: 4
})

const StyledField = styled(TextField)({
    "& .MuiInputAdornment-root": {
        margin: 0
    },
    "& .MuiInputBase-root": {
        height: "34px",
    },
    "& .MuiInput-underline ": {
        borderColor: "#B7B7B7 !important",
    },
    "& .MuiInput-underline.Mui-disabled::before ": {
        borderBottomStyle: "solid"
    },
    "& .MuiInput-underline::before ": {
        borderColor: "#B7B7B7 !important",
    },
    "& .MuiInput-underline::after ": {
        borderColor: "#B7B7B7 !important",
    },
    "& .MuiInputBase-input": {
        padding: "8px",
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Montserrat",
        color: "#A6A6A6",
    }
})
const ListWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "80px 0"
})
const PetBoxWrapper = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "35px",
    maxWidth: "971px",
    width: "100%",
    padding: "12px",
    maxHeight: "292px",
    overflowY: "auto",
})
const PetBox = styled(Box)({
    height: "234px",
    maxWidth: "297px",
    width: "100%",
    padding: "29px 0",
    borderRadius: "4px",
    boxShadow: "0px 0px 7.5px 0px #0000001A",

    display: "flex",
    flexDirection: "column",
    gap: "24px",
    justifyContent: "center",
    alignItems: "center"
})

const PetAvatar = styled(Avatar)({
    height: "123px",
    width: "123px"
})

const PetName = styled(Typography)({
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
    textAlign: "center"
})
const PetBreed = styled(Typography)({
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#B7B7B7",
    textAlign: "center",
    marginTop: 9
})
const PetAgeBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "13px",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
})

const BookingWrapper = styled(Box)({
    margin: "48px 0"
})
const TitleWrapper = styled(Box)({
    height: "58px",
    padding: "0 14px",
    margin: "0 40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: 7
})
const BookingListWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    maxHeight: "320px",
    overflowY: "auto",
    padding: "0 12px",
    margin: "0 22px"
})
const HistoryTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#6F05EA",

})
const BookingBox = styled(Box)({
    cursor: "pointer",
    minHeight: "49px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
})
const CenterBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
const CustomChip = styled(Chip)({
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    height: 22,
})
const RowFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
    "&.month": {
        fontSize: "10px",
        lineHeight: "4px",
        color: "#7D7D7D"
    }
})

const BackBox = styled(Box)({
    margin: "48px 32px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        margin: "32px 16px",
    },
    "@media (max-width: 600px)": {
        margin: "32px 8px",
    },
})
const ArrowBtn = styled(IconButton)({
    padding: 0,
    "&:hover": {
        background: "none !important",
    },
})
const ArrowIcon = styled(KeyboardArrowLeft)({
    color: "#6F05EA",
    width: 48,
    height: 48
})
const BackTitle = styled(Typography)({
    marginLeft: "12px",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "20px",
    color: "#0F172A",
    "@media (min-width: 600px) and (max-width: 991px)": {
        fontSize: "22px",
    },
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
})

const style = {
    phoneButtonStyle: {
        border: "none",
        background: "transparent",
    } as React.CSSProperties,
    phoneInputStyle: {
        border: "none",
        width: "100%",
        borderBottom: "1px solid #A6A6A6",
        borderRadius: "0px",
        height: "34px",
        paddingLeft: "40px",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "Montserrat",
        cursor: "auto"
    } as React.CSSProperties,
    phoneDropDownStyle: {
        fontSize: "13px"
    } as React.CSSProperties,
}
// Customizable Area End
