import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
  import { FormikErrors, FormikTouched } from "formik";
import { AppointemntDetailsData } from "../../../blocks/customisableuserprofiles/src/types";
  const styles = {
    filledLabel: {
      fontSize: "13px",
      fontWeight: 400,
      color: "#6F05EA",
      fontFamily: "Montserrat",
    },
    secondaryLabel: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: '18px',
      color: '#777777'
    },
  };
  interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
  }
 
  interface ITreatsForm {
    id?: number;
    calm: string | undefined;
    relaxed: string | undefined;
    easy_going: string | undefined;
    friendly: string | undefined;
    gentle: string | undefined;
    obedient: string | undefined;
    walks: number | undefined;
    walking_schedule: string;
    potty: number | undefined;
    potty_schedule: string;
    meal: number | undefined;
    meal_schedule: string;
  }
  export interface IMedicalForm {
    last_health_check_up: string;
    updateddate: string;
    time:string;
    updatedEndDate:string
  }
  interface IPersonalInfo {
    first_name: string,
    last_name: string,
    phone_number: string,
    date_of_birth: string,
    nationality: string,
    city: string,
    country: string,
    language: string,
    zipcode: string,
    address: string,
    id?: string,
  }
  const defaultTreats = {
    calm: undefined,
    relaxed: undefined,
    easy_going: undefined,
    friendly: undefined,
    gentle: undefined,
    obedient: undefined,
    walks: 0,
    walking_schedule: "",
    potty: 0,
    potty_schedule: "",
    meal: 0,
    meal_schedule: "",
  };
  const EstablishmentListErrorDetails=[{
    account: "Email invalid",
    token: "Invalid token",
    detail: "Invalid value",
    error: 'Invalid Data',
  }]
  const defaultPersonal = {
    first_name: "",
    last_name: "",
    phone_number: "",
    date_of_birth: "",
    nationality: "",
    city: "",
    country: "",
    language: "portuguese",
    zipcode: "",
    address: ""
  }
  const defaultMedical = {
    last_health_check_up: "",
    updateddate: '',
    time:'',
    updatedEndDate:''
}
export interface EstablishmentAttributes {
  id: number,
  status:string,
  request_type: string
  start_date: string | null,
  end_date:string  | null,
  shift:  string  | null,
  duration:string  | null,
  created_by: string,
  week_days:string[],
  schedule_detail: {
      id: number,
      start_date: string
      end_date:string
      shift: string
      price:string
      week_days?: string
      search_start_date?: string,
      search_end_date?: string,
      start_time?: string
  },
  customer: {
      customer_detail: {
          id: number,
          first_name: string,
          last_name:string,
          full_phone_number: string,
          country_code: string  | null,
          phone_number: number,
          activated: boolean,
          email: string,
          
          device_id: null,
          unique_auth_id: string,
          password_digest: string,
          created_at: string,
          platform: null,
          user_type: null,
          updated_at:string,
          user_name: null,
          
          app_language_id: null,
          last_visit_at: null,
          is_blacklisted: boolean,
          suspend_until: null,
          status: string,
          role_id:number,
          gender: null,
          date_of_birth: null,
          age: null,
          gpa: null,
          country: string,
          city: string,
          language: string,
          nationality: string,
          confirm_email: null,
          stripe_id: string,
          vendor_type: string,
          address: string,
          zipcode: string,
          tax_number: string,
          company_name: string,
          services_type: string[],
          facebook_url:  string | null,
          instagram_url: string | null,
          linkedin_url: string | null,
          tiktok_url: string | null,
          client_id: string,
          iban: null
      },
      image_url: string,
  },
  pet: {
      pet_detail: {
          id: number,
          pet_category: string,
          name:string,
          gender: string,
          size: string,
          breed: string,
          birthdate: string,
          weight: string,
          fur:string,
          species: string,
          account_id: number,
          created_at:string,
          updated_at: string,
      },
      image_url: string,
  },
  service_provider: {
      service_provider_detail: {
          id: number,
          first_name: string,
          country_code: number,
          phone_number: number,
          last_name:string,
          full_phone_number: string,
          email: string,
          activated: boolean,
          device_id: null,
          unique_auth_id:string,
          password_digest:string,
          user_name: null,
          created_at: string,
          updated_at:string,
          platform: null,
          user_type: null,
          app_language_id: null,
          last_visit_at: null,
          is_blacklisted: boolean,
          suspend_until: null,
          status:string,
          role_id: number,
          gender: null,
          date_of_birth: null,
          age: null,
          gpa: null,
          country:string,
          city: string,
          language: string,
          nationality:string,
          address: string,
          zipcode:string,
          confirm_email: null,
          stripe_id: string,
          vendor_type:string,
          tax_number: string,
          facebook_url: string,
          instagram_url: string,
          linkedin_url: string,
          tiktok_url: string,
          company_name: string,
          services_type: string[],
          client_id: string,
          iban: string,
      },
      image_url: string,
  }
}
interface ListEstablishment {
  id: string;
  type: string;
  attributes: EstablishmentAttributes;
} 
interface MTdataList{
  id: string;
  type: string;
  attributes:{
    pet_trait: 
      {
          id?: number;
          calm: string | undefined;
          relaxed: string | undefined;
          easy_going: string | undefined;
          friendly: string | undefined;
          gentle: string | undefined;
          obedient: string | undefined;
          walks: number | null;
          walking_schedule: string;
          potty: number | null;
          potty_schedule: string;
          meal: number | null;
          meal_schedule: string;
          animal_type_id:number,
          created_at:string,
          updated_at: string,
      }
      pet_medical: 
        {
            id: number,
            medical_conditions: string,
            allergies:string,
            usual_medication: string,
            last_health_check_up:string,
            microchip:string| null,
            vaccines_up_to_date: boolean,
            sterilized: boolean,
            hospital: string,
            hospital_address:string,
            hospital_number: string,
            doctor_name:string,
            company: string,
            policy_number:string,
            primary_name:string,
            primary_contact: string,
            secondary_name:string,
            secondary_contact: string,
            animal_type_id:number,
            created_at:string,
            updated_at: string,
        }
    

  }
}
interface MetaMessage{
  message:string
}
export interface EstablishmentListErrorDetail {
  detail?: string;
  token?: string;
  account?: string;
  error?:string;
}
 interface RequestDataShow{
  data: ListEstablishment;
  meta:MetaMessage;
  errors: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}
interface MedicalAndTraitsDataShow{
  data: MTdataList;
  meta:MetaMessage;
  errors: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}
const defaultRequestDetails={
  data:{
   id: "",
   type: "",
   attributes: {
       id: 0,
       status: "",
     request_type: "",
       start_date: "",
       end_date: "",
       shift: "",
       duration: "",
       created_by: "customer",
       week_days:[],
       schedule_detail: {
           id: 19,
           start_date: "2024-09-05",
           end_date: "2024-09-14",
           shift: "12.30",
           price:'750'
       },
       customer: {
           customer_detail: {
               id: 366,
               first_name: "Deepika Sept",
               last_name: "",
               full_phone_number: "8754219632",
               country_code: "",
               phone_number: 8754219632,
               email: "test6@yopmail.com",
               activated: true,
               device_id: null,
               unique_auth_id: "WRIU92WFa6cPC3sPd651gQtt",
               password_digest: "",
               created_at: "2024-09-05T06:37:06.668Z",
               updated_at: "2024-09-05T06:40:24.544Z",
               user_name: null,
               platform: null,
               user_type: null,
               app_language_id: null,
               last_visit_at: null,
               is_blacklisted: false,
               suspend_until: null,
               status: "regular",
               role_id: 2,
               gender: null,
               date_of_birth: null,
               age: null,
               gpa: null,
               country: "AF",
               city: "indore",
               language: "portuguese",
               nationality: "portuguese",
               address: "indore",
               zipcode: "5555",
               confirm_email: null,
               stripe_id: "cus_Qn9cnLZZJmiuuR",
               vendor_type: "company",
               tax_number: "778",
               company_name: "RK Tech",
               services_type: [
                   '',
                   ''
               ],
               facebook_url: "",
               instagram_url: "",
               linkedin_url:"",
               tiktok_url: "",
               client_id: "",
               iban: null
           },
           image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/nwhxbvfzf7hy6089f0esb753qavn?response-content-disposition=inline%3B%20filename%3D%22image_product-image.png%22%3B%20filename%2A%3DUTF-8%27%27image_product-image.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=ae80338e7b3e90a5cb2ba626f1d73f8c2b13ddfc1a0c47c05005c16c4118e967"
       },
       pet: {
           pet_detail: {
               id: 156,
               pet_category: "",
               name: "",
               breed: "",
               birthdate: "",
               gender: "",
               size: "",
               weight: "",
               fur: '',
               species: "",
               account_id: 0,
               created_at: "",
               updated_at: ""
           },
           image_url: ""
       },
       service_provider: {
           service_provider_detail: {
               id: 0,
               first_name: "",
               last_name: "",
               full_phone_number: "",
               country_code: 56895,
               phone_number: 17234567,
               email: "",
               activated: true,
               device_id: null,
               unique_auth_id: "",
               password_digest: "",
               created_at: "",
               updated_at: "",
               user_name: null,
               platform: null,
               user_type: null,
               app_language_id: null,
               last_visit_at: null,
               is_blacklisted: false,
               suspend_until: null,
               status: "",
               role_id: 1,
               gender: null,
               date_of_birth: null,
               age: null,
               gpa: null,
               country: "Albania",
               city: "Bajram Curri",
               language: "portuguese",
               nationality: "portuguese",
               address: "202-Maple-St,-Gotham,-IL-61550",
               zipcode: "54567",
               confirm_email: null,
               stripe_id: "cus_QcgdgE0kBiyGoC",
               vendor_type: "company",
               tax_number: "-",
               company_name: "-",
               services_type: [
                   '',
                   ""
               ],
               facebook_url: "",
               instagram_url: "",
               linkedin_url: "",
               tiktok_url: "",
               client_id: "",
               iban: ""
           },
           image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/uatqex3qf4zyosf1hyiy79ugchw1?response-content-disposition=inline%3B%20filename%3D%2213-gallery%202.png%22%3B%20filename%2A%3DUTF-8%27%2713-gallery%25202.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=bb29dc419ff2cfeadc9e87be412534652286d76ff94b31ad7e775c407aa78ba3"
       }
   
 }
  },
  meta:{
  message: "Request retrieved successfully"
  },
  error:EstablishmentListErrorDetails,
  errors:EstablishmentListErrorDetails
 }
 const defaultMedicalTraitsData={
  data:{
  id: '',
  type: '',
  attributes:{
    pet_trait: {
          id: 26,
          calm: undefined,
          relaxed: undefined,
          easy_going:undefined,
          friendly:undefined,
          gentle:undefined,
          obedient:undefined,
          walks: null,
          walking_schedule: "",
          potty: null,
          potty_schedule: "",
          meal: null,
          meal_schedule: "",
          animal_type_id: 0,
          created_at: "",
          updated_at: ""
      },
      pet_medical:{
        id: 26,
        medical_conditions: "",
        allergies: "",
        usual_medication: "",
        last_health_check_up: "",
        microchip: null,
        vaccines_up_to_date: false,
        sterilized: false,
        hospital: "",
        hospital_address: "",
        hospital_number: "",
        doctor_name: "",
        company: "",
        policy_number: "",
        primary_name: "",
        primary_contact: "",
        secondary_name: "",
        secondary_contact: "",
        animal_type_id: 0,
        created_at: "",
        updated_at: ""
    }
      }
},
  meta:{
  message: "Request retrieved successfully"
  },
  error:EstablishmentListErrorDetails,
  errors:EstablishmentListErrorDetails
 }

 interface CategoryType {
  time:string
}
export interface IFilteredService {
  name: string,
  service_id: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedRequestId: {
    name: string,
    service_id: string,
},
isShowrequestscreen :boolean,
appointmentData?: AppointemntDetailsData, 
  showRequestScreen :() => void,
  selectedService: {
    name: string,
    service_id: string,
},
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentMenuItem:number;
  isSideBarOpen:boolean;
  currentSettingsTab: number;
  profileImageUrl: string;
  isEditGeneral: boolean;
  iscancelAppointment:boolean;
  isOpenLanguageModal:boolean;
  cheap:boolean;
  cheapNumber:boolean
  isSelectedGeneral:boolean;
  personal: IPersonalInfo;
  isEditMedical: boolean;
  isEditTreats: boolean;
  isEditRequest:boolean;
  open:boolean;
  medical: IMedicalForm;
  RequestDetails:RequestDataShow | AppointemntDetailsData;
  reshedulerrorMsg:string;
  isaccepted:boolean;
  rejectedMsg:string;
  newDate:string,
  shift:string,
  reccurence: string,
  catagorydata: CategoryType[];
  isResheduleRequest:boolean;
  isRequestType:boolean;
  isRequestPendingaccepted:boolean;
  isRequestNotPending:boolean;
  acceptbuttonloader:boolean;
  rejectbuttonloader:boolean;
  MedicalandTraitsDataShow:MedicalAndTraitsDataShow;
  newEndDate:string;
  cancelAppointmentErrorMessage:string;
  radioValue:string
  //recurrence
  isAllSelected:boolean;
  selectedDays:string[];
  backendDays:any;
  isDisabledDays:boolean;
  isYellow:boolean

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GeneralRequestTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetRequestDetailsApi: string = "";
  resheduleRequestAPi: string = "";
  rejectacceptAPICall: string = "";
  cancelAppointmentAPi: string = "";
  GetMecialandTraitsAPIcall: string = "";

  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
    ];

    this.state = {
        personal: defaultPersonal,
        isEditMedical: false,
        isEditTreats: false,
        currentMenuItem:0,
        currentSettingsTab: 0,
        isSideBarOpen:false,
        iscancelAppointment:false,
        profileImageUrl: "",
        isEditGeneral: false,
        isOpenLanguageModal: false,
        cheap:true,
        cheapNumber:false,
        isSelectedGeneral:true,
        isEditRequest:true,
        open: false,
        medical: defaultMedical,
        RequestDetails:defaultRequestDetails,
        reshedulerrorMsg:'',
        isaccepted:false,
        rejectedMsg:'',
        newDate:'',
        shift:'',
        reccurence: '',
        catagorydata: configJSON.dummyTime,
        isResheduleRequest:true,
        isRequestType:true,
        isRequestPendingaccepted:false,
        isRequestNotPending:false,
        acceptbuttonloader:true,
        rejectbuttonloader:true,
        MedicalandTraitsDataShow:defaultMedicalTraitsData,
        newEndDate:'',
        cancelAppointmentErrorMessage:'',
        radioValue:'one',
        //Recurrence
        isAllSelected:true,
        selectedDays:[],
        backendDays:{},
        isDisabledDays:false,
        isYellow:false

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId===this.GetRequestDetailsApi) {
      this.handlebookingDetails(responseJson);
     }
     if (apiRequestCallId===this.resheduleRequestAPi) {
      this.handleresheduleRequest(responseJson);
     }
     if (apiRequestCallId===this.rejectacceptAPICall) {
      this.handlerejectacceptresponse(responseJson);
     }
     if (apiRequestCallId===this.cancelAppointmentAPi) {
      this.handleCancelAppointmentRequest(responseJson);
     }
     if (apiRequestCallId===this.GetMecialandTraitsAPIcall) {
      this.handleMedicalTraitsResponse(responseJson)
     }
    // Customizable Area End
  }

  // Customizable Area Start
  getDotStyle = (value: string | undefined, dotNumber: number) => {
    const notActiveStyles = {
      width: "11px",
      height: "11px",
      background: "#E7E7E7",
      borderRadius: "50%",
      transition: "background-color 0.3s, width 0.3s, height 0.3s",
    };

    const activeStyle = {
      width: "20px",
      height: "20px",
      background: "#6F05EA",
      borderRadius: "50%",
      transition: "background-color 0.3s, width 0.3s, height 0.3s",
    };

    if (value !== undefined && value !== null) {
      if (Number(value) === dotNumber) {
        return activeStyle;
      }

      return { ...notActiveStyles, background: "#E2CDFB" };
    } else{
      return notActiveStyles;
    }
   
    
  };
  getInputProps = <T extends object>(
    field: keyof T,
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    values: T,
  ) => {
    return {
      id: field,
      name: field,
      fullWidth: true,
      error: Boolean(errors[field]) && touched[field],
      helperText: (touched[field] && errors[field])?.toString(),
      labelStyle: values[field] ? styles.filledLabel : styles.secondaryLabel,
    };
  };
  getUserGeneralInfo = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    const requestId = this.props.selectedRequestId.service_id
    this.GetRequestDetailsApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.requestdetailsEndpoint}/${requestId}`,
      token: userInfoToken,
    });
  };
  getMedicalandTraitsInfo=async () =>{
    const userInfoToken = localStorage.getItem("login_token");
    const requestId=this.props.selectedRequestId.service_id
    this.GetMecialandTraitsAPIcall = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.medicaltraitsEndpoints}/${requestId}`,
      token: userInfoToken,
    });
  }
  rejectRequestCall=async(type:string)=>{
    if(type!=='accepted'){
      this.setState({rejectbuttonloader:false})
    }else{
      this.setState({acceptbuttonloader:false})
    }
    const userInfoToken = localStorage.getItem("login_token");
    let rejectrequest = new FormData();
    rejectrequest.append("status", type);
    const attr = {
      status: type,
    };
    this.rejectacceptAPICall = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.patchcall,
      endPoint: `${configJSON.requestdetailsEndpoint}/${this.state.RequestDetails.data.attributes.id}`,
      token: userInfoToken,
      body:attr,
      isJsonStringify:true
    });
  }

  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  async componentDidMount() {
    if(!this.props.appointmentData) {
      this.getUserGeneralInfo();
      this.getMedicalandTraitsInfo();
    } else {
      const medicalAndTreats = {
        ...defaultMedicalTraitsData,
        data: {
          ...defaultMedicalTraitsData.data,
          attributes: {
            pet_trait: {...defaultMedicalTraitsData.data.attributes.pet_trait, ...(this.props.appointmentData.data.attributes.pet_trait ?? {})},
            pet_medical: {...defaultMedicalTraitsData.data.attributes.pet_medical, ...(this.props.appointmentData.data.attributes.pet_medical ?? {}) }
          }
       }
      }
      const request_type = this.props.appointmentData.data.attributes.status;
      this.setState({ 
        RequestDetails: this.props.appointmentData, 
        MedicalandTraitsDataShow: medicalAndTreats, 
        isRequestNotPending: request_type !== 'pending',
        selectedDays: this.props.appointmentData.data.attributes.week_days || [] 
      })
    }
  }
  setnewDate(date:any){
    this.setState({newDate:date,reshedulerrorMsg:''})
  }
  setnewEndDate(date:any){
    this.setState({newEndDate:date,reshedulerrorMsg:''})
  }

  /* istanbul ignore next */
  validateForm = () => {
    let isValid = true;
    if(this.state.newDate === '') {
      isValid = false;
      this.setState({ reshedulerrorMsg: 'Please select Updated date' })
    } else if(this.state.reccurence === '') {
      isValid = false;
      this.setState({ reshedulerrorMsg: 'Please select recurrence' })
    } else if(this.state.selectedDays.length === 0) {
      isValid = false;
      this.setState({ reshedulerrorMsg: 'Please select recurrence days' })
    } else if(Number(this.state.reccurence) !== this.state.selectedDays.length) {
      isValid = false;
      this.setState({ reshedulerrorMsg: 'Please select same number of days as recurrence' })
    }

    return isValid;
  }

  /* istanbul ignore next */
  getRequestAttributes = () => {
    if(this.state.radioValue === 'all') {
      let attr: any = {
        start_date: this.state.newDate,
        recurrence_booking: true,
        request_type: 'Reschedule Request',
        schedules_id: this.state.RequestDetails.data.attributes.schedule_detail.id,
      };
      if(this.state.newEndDate) {
        attr.end_date = this.state.newEndDate
      }
      if(this.state.shift) {
        attr.shift = this.state.shift
      }
      if(this.state.selectedDays) {
        attr.weekdays = this.state.selectedDays
      }
      if(this.state.reccurence) {
        attr.recurrences = this.state.reccurence
      }
      return attr
    } else {
      return {
        start_date: this.state.newDate,
        end_date:this.state.newEndDate, 
        request_type: 'Reschedule Request',
        schedules_id: this.state.RequestDetails.data.attributes.schedule_detail.id,
        shift: this.state.shift,
      };
    }
  }

  /* istanbul ignore next */
  createResheduleCalll = async () => {
    const isValid = this.validateForm();
    if (isValid) {
      this.setState({ reshedulerrorMsg: '' })
      const userInfoToken = localStorage.getItem("login_token");
      const attr = this.getRequestAttributes();
      this.resheduleRequestAPi = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: `${configJSON.requestdetailsEndpoint}`,
        token: userInfoToken,
        body: attr,
        isJsonStringify: true,
      });
    } 
  }

  handlePendingStatus = (responseJson: any) => {
    if (responseJson.data.attributes.status !== 'pending') { 
      this.setState({ open: !this.state.open, reshedulerrorMsg: '', isResheduleRequest: false, iscancelAppointment: true, isaccepted: false, isRequestNotPending: false })
      this.setState((prevState:any) => ({
        ...prevState,
        RequestDetails: {
          ...prevState.RequestDetails,
          data: {
            ...prevState.RequestDetails.data,
            attributes: {
              ...prevState.RequestDetails.data.attributes,
              start_date: responseJson.data.attributes.start_date,
              end_date:responseJson.data.attributes.end_date, // Update the start_date
            },
          },
        },
      })) 
    } else {
    this.setState({ isEditGeneral: false, isRequestNotPending: false })
    }
  }
 
  handleresheduleRequest(responseJson: any) {
    if (responseJson.data) {
      if (responseJson.data.attributes.request_type === 'Reschedule Request') {
      this.handlePendingStatus(responseJson);
      if (responseJson.data.attributes.created_by !== 'customer') {// it is for vendor
        
        if (responseJson.data.attributes.status == 'pending') {
          this.setState({ isResheduleRequest: false, open: !this.state.open, reshedulerrorMsg: '', iscancelAppointment: true, isaccepted: false })
          this.setState((prevState:any) => ({
            ...prevState,
            RequestDetails: {
              ...prevState.RequestDetails,
              data: {
                ...prevState.RequestDetails.data,
                attributes: {
                  ...prevState.RequestDetails.data.attributes,
                  start_date: responseJson.data.attributes.start_date,
                  end_date:responseJson.data.attributes.end_date, // Update the start_date
                },
              },
            }
        }))
        }
      }
    }
  }
  this.handleResheduleError(responseJson)
   }
   handleResheduleError(responseJson: any){
    if (responseJson?.error) {
      if(responseJson.error=='Invalid date')
      this.setState({ reshedulerrorMsg:'Please select a valid date. The end date must be greater than the start date.' })
    }
    if (responseJson?.errors) {
      if(responseJson.errors=='Invalid date')
      this.setState({ reshedulerrorMsg: 'Please select a valid date. The end date must be greater than the start date.' })
    } 
   }
  handlerejectacceptresponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ rejectbuttonloader: true, acceptbuttonloader: true })
      if (responseJson.data.attributes.request_type == 'Cancellation Request') {
        this.handlecancellationRequest(responseJson)
      }else if(responseJson.data.attributes.request_type == 'Reschedule Request'){
        //update attributes start end date
        this.setState({ isRequestType: true, isRequestNotPending: true, iscancelAppointment: false, isResheduleRequest: true, isaccepted: false, })
        this.setState((prevState:any) => ({
          ...prevState,
          RequestDetails: {
            ...prevState.RequestDetails,
            data: {
              ...prevState.RequestDetails.data,
              attributes: {
                ...prevState.RequestDetails.data.attributes,
                start_date: responseJson.data.attributes.start_date,
                end_date:responseJson.data.attributes.end_date, // Update the start_date
                schedule_detail:{
                  ...prevState.RequestDetails.data.attributes.schedule_detail,
                  start_date: responseJson.data.attributes.schedule_detail.start_date,
                  end_date:responseJson.data.attributes.schedule_detail.end_date,
                }
              },
            },
          }
      }))
      }else{
        this.setState({ isRequestType: true, isRequestNotPending: true, iscancelAppointment: false, isResheduleRequest: true, isaccepted: false, })
      }
    }
    if (responseJson?.errors) {
      this.setState({ rejectedMsg: responseJson?.errors, rejectbuttonloader: true, acceptbuttonloader: true })
    }
  }
  handlecancellationRequest(responseJson: RequestDataShow) {
    if (responseJson.data.attributes.status == 'rejected') {
      this.setState({ iscancelAppointment: true, isResheduleRequest: false, isRequestType: true,isaccepted:false })
    } else {
      this.setState({ iscancelAppointment: true, isResheduleRequest: true,isRequestType: true,isaccepted:true })
    }
  }
  handleCancelAppointmentRequest(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ isOpenLanguageModal: !this.state.isOpenLanguageModal, isaccepted: true, isResheduleRequest: true, iscancelAppointment: true, isEditGeneral: false })
    }
    if (responseJson?.errors) {
      this.setState({ cancelAppointmentErrorMessage: responseJson?.errors })
    }
  }
  handlebookingDetails(responseJson: RequestDataShow) {
    if (responseJson.data) {
      this.setState({ RequestDetails: responseJson });
    }
    if (this.state.RequestDetails.data.attributes.request_type === 'Reschedule Request') {
      this.handleResheduleResquestCustomer()
    } else if (responseJson.data.attributes.request_type == 'Cancellation Request') {
      this.handleCancellationResponse(responseJson)

    } else if (responseJson.data.attributes.request_type == 'Transportation Request') {
      this.handleTransportationResponseRequest(responseJson)
    } else if (responseJson.data.attributes.request_type == 'Change Request') {
      this.handleChangeRequestResponse(responseJson);
    }
    if (this.state.RequestDetails.data.attributes.status !== 'pending' && this.state.RequestDetails.data.attributes.request_type === 'Reschedule Request') {
      this.setState({ isRequestNotPending: true })
    }
  }
  handleChangeRequestResponse(responseJson: RequestDataShow) {
    if (this.state.RequestDetails.data.attributes.created_by === 'customer') {
      if (responseJson.data.attributes.status == 'pending') {
        this.setState({ isRequestType: false })
      }
    }
  }
  handleTransportationResponseRequest(responseJson: RequestDataShow) {
    if (this.state.RequestDetails.data.attributes.created_by === 'customer') {
      if (responseJson.data.attributes.status == 'pending') {
        this.setState({ isRequestType: false })
      } else {
        this.setState({ isRequestNotPending: true })
      }
    }
  }
  handleCancellationResponse(responseJson: RequestDataShow) {
    if (this.state.RequestDetails.data.attributes.created_by === 'customer') {
      if (responseJson.data.attributes.status == 'accepted') {
        this.setState({ iscancelAppointment: true, isResheduleRequest: true, isRequestType: true, isaccepted: true })
      } if (responseJson.data.attributes.status == 'rejected') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isRequestType: true, isaccepted: false })
      } else if (responseJson.data.attributes.status == 'pending') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isRequestType: false, isaccepted: false })
      }
    } else {
      if (responseJson.data.attributes.status == 'accepted') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isRequestType: true, isaccepted: true })
      } if (responseJson.data.attributes.status == 'rejected') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isRequestType: true, isaccepted: false })
      } else if (responseJson.data.attributes.status == 'pending') {
        this.setState({ iscancelAppointment: true, isResheduleRequest: false, isRequestType: true, isaccepted: false })
      }
    }
  }
  handleResheduleResquestCustomer() {
    if (this.state.RequestDetails.data.attributes.created_by === 'customer') {
      if (this.state.RequestDetails.data.attributes.status === 'pending') {
        this.setState({ isRequestType: false })
      } else if (this.state.RequestDetails.data.attributes.status === 'accepted') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isaccepted: false })
      }
    } else {
      if (this.state.RequestDetails.data.attributes.status === 'pending') {
        this.setState({ isResheduleRequest: false, iscancelAppointment: true, isaccepted: false })
      } if (this.state.RequestDetails.data.attributes.status === 'rejected') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isaccepted: false })
      } if (this.state.RequestDetails.data.attributes.status === 'accepted') {
        this.setState({ iscancelAppointment: false, isResheduleRequest: true, isaccepted: false })
      }
    }
  }
  handleMedicalTraitsResponse(responseJson: MedicalAndTraitsDataShow) {
    if (responseJson?.data) {
      const { pet_medical, pet_trait } = responseJson.data.attributes;
      const updatedTraits = pet_trait ? pet_trait : this.state.MedicalandTraitsDataShow.data.attributes.pet_trait;
      const updatedMedical = pet_medical ? pet_medical : this.state.MedicalandTraitsDataShow.data.attributes.pet_medical;
        this.setState({
          MedicalandTraitsDataShow: {
            ...this.state.MedicalandTraitsDataShow,
            data: {
              ...this.state.MedicalandTraitsDataShow.data,
              attributes: {
                pet_medical: updatedMedical,
                pet_trait: updatedTraits,  
              }
            }
          }
        });
    }
  }
  openSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  };
  closeModal=()=>{
    this.setState({isOpenLanguageModal: !this.state.isOpenLanguageModal})
  }
  GetCancelAppointment = async () => {
    this.setState({ reshedulerrorMsg: '',cancelAppointmentErrorMessage:'' })
    const userInfoToken = localStorage.getItem("login_token");
    const attr = {
      request_type: 'Cancellation Request',
      schedules_id: this.state.RequestDetails.data.attributes.schedule_detail.id,
    };

    this.cancelAppointmentAPi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.requestdetailsEndpoint}`,
      token: userInfoToken,
      body: attr,
      isJsonStringify: true,
    });
  }
  toggleActions = () => {
    this.setState({ isEditGeneral: !this.state.isEditGeneral })
  }
  GetRejectCancelAppointment=()=>{
    this.setState({iscancelAppointment:false,isOpenLanguageModal:false})
  }
  
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }

  handleOpen = () => {
    this.setState({ open: !this.state.open, reshedulerrorMsg:'' });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  // Customizable Area End
}
