Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.petListApiEndPoint = 'account_block/animal_types'

exports.btnExampleTitle = "CLICK ME";

exports.cardData = [
  {
    title: "VillaDog - Hotel Canino",
    location: "Vila Nova de Gaia, Porto",
    price: "14€",
  },
  {
    title: "Amore Albergo",
    location: "7508 Roselee Mission, Lake Darcey",
    price: "20€",
  },
  {
    title: "Costa Coral Lorem,",
    location: "342 Rampa Gabriel, 68, León",
    price: "10€",
  },
  {
    title: "Lorem, ipsum dolor sit",
    location: "Puerta Marcela, 82 Esc,",
    price: "25€",
  },
  {
    title: "Flashy Fortuna",
    location: "Washington Square South Str",
    price: "18€",
  },
];
exports.findServiceCardData = [
  {
    title: "VillaDog - Hotel Canino",
  },
  {
    title: "Amore Albergo",
  },
  {
    title: "Costa Coral",
  },
  {
    title: "Neon Nest",
  },
  {
    title: "Flashy Fortuna",
  },
];
exports.dummyBookingTableData = [
  {
    Id:1,
    ServiceType:"Individual Training",
    Capacity:"1 Dog",
    Price:"165 €"
  },
  {
    Id:2,
    ServiceType:"Aggressive behavior",
    Capacity:"1 Dog",
    Price:"185 €"
  },
  {
    Id:3,
    ServiceType:"Lorem ipsum dolor sit amet consectetur.",
    Capacity:"1 Dog",
    Price:"167 €"
  },
  {
    Id:4,
    ServiceType:"Individual Junior",
    Capacity:"1 Dog",
    Price:"165 €"
  },
  {
    Id:5,
    ServiceType:"Individual Junior Puppy Training and Aggressive behavior",
    Capacity:"1 Dog",
    Price:"165 €"
  }
];
exports.landingPageFindServiceListApiEndPoint = "account_block/services/find_services";
exports.getDiscountBannerApiEndPoint = "bx_block_content_management/content_managements?service_type=";
exports.landingPageRecentlyBookedListApiEndPoint = "account_block/booked_service";
exports.showbooking = "account_block/services";
exports.BookNow = "bx_block_scheduling/schedules";
exports.getPetendpoint = "account_block/animal_types";
exports.getunreadcountEndpoint="bx_block_chat/chats/total_unread_message_count";
exports.landingPageRecentlyBookedListApiEndPoint = "bx_block_scheduling/schedules/recently_booked?service_type=";

exports.DEFAULT_MSG = {
  PAGE_TITLE: "Welcome to the",
  ANIMATION_ARRAY: ['Pet hotels.', 1000, 'Dog walkers.', 1000, 'Pet sitters.', 1000, 'Day care.', 1000, "Trainers.", 1000]
}

exports.SIMPLE_DIALOG = {
  CONFIRM_MESSAGE:"Yes, I'm Sure",
  CANCEL_MESSAGE:"No, keep me Logged In",
  MESSAGE:"To become a service provider please Logout of Petzai are you sure ?"
}

// Customizable Area End