import React, { useState } from "react";
import GenericModal from "../../../../components/src/GenericModal";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from "formik";
import InputField from "../../../../components/src/InputField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FormWrapper } from "./FormWrapper";
import useBreakpoints from "../../../../components/src/hooks/useBreakpoints";
import { transPt } from "../../../../components/src/HelperUtils";

interface IProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    onSubmit: (values: EmailFormValues) => void;
    goToForgotPassword: () => void;
    getInputProps: <T extends object>(
        field: keyof T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: T,
        isEdit: boolean,
    ) => any;
    error?: string;
}

export type EmailFormValues = {
    email: string;
    confirm_email: string;
    current_password: string;
}

const initialValues = {
    email: '',
    confirm_email: '',
    current_password: ''
}

const validationSchema = Yup.object().shape({
    current_password: Yup.string()
      .required('*Current password is required').trim(),
    email: Yup.string()
      .email("*Invalid email address.")
      .required("*Email is required.")
      .test("email", "*Invalid email address", (value) => {
        if (!value) return false;
        return /^[a-zA-Z0-9._+~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
      })
    .trim(),
    confirm_email: Yup.string()
      .when('email', {
        is: (email) => (email && email.length > 0),
        then: Yup.string().oneOf([Yup.ref('email')], '*Email do not match.')
      })
      .required('*Email is required').trim()
});

const stylesLabel = {
    filledLabel: {
        fontSize: "13px",
        fontWeight: 400,
        fontFamily: "Montserrat",
        color: "#6F05EA",
    },
    secondaryLabel: {
        fontWeight: 600,
        fontSize: '18px',
        color: '#334155',
        fontFamily: "Montserrat",
        lineHeight: "21.94px"
    },
}

const ResetEmailModal = (props: IProps) => {
    const { isOpen, title = "", onClose, onSubmit, getInputProps, goToForgotPassword, error  } = props;
    const [currentVisible, setCurrentVisible] = useState(true);

    const updateCurrentVisible = (visibility: boolean) => {
        setCurrentVisible(visibility)
    }

    const { less600 } = useBreakpoints();

    const renderEndAdornment = () => (
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => updateCurrentVisible(!currentVisible)}
          edge="end"
        >
          {currentVisible ? <VisibilityOff  style={{ fill: "#A6A6A6" }}/> : <Visibility />}
        </IconButton>
    );

    return (
        <GenericModal 
            id='email-reset' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose}
            bodyStyle={{ padding: less600 ? '16px 25px' : '30px 50px'}} 
            headerStyle={{ color: '#6F05EA'}}
            withoutCloseBtn
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            data-test-id="reset-email-form"
          >
            {({
                values,
                errors,
                touched,
                dirty,
                handleSubmit,
                getFieldProps,
                resetForm,
              }) => {
                return (
                    <FormWrapper>
                        {error && <Box className="error-box">{error}</Box>}
                        <form onSubmit={handleSubmit} className="form">
                            <Box style={{marginTop: "40px"}}>
                            <style>
                                {`
                                    .MuiInputAdornment-positionStart{
                                        margin-left: 0px !important;
                                        margin-right: 0px !important;
                                    }
                                `}
                            </style>
                                <InputField
                                    {...getFieldProps("email")}
                                    {...getInputProps("email", errors, touched, values, true)}
                                    placeholder={transPt("Insert new email","customerUserProfile")}
                                    label={transPt("New email","customerUserProfile")}
                                    labelStyle={dirty ? stylesLabel.filledLabel : stylesLabel.secondaryLabel}
                                    data-test-id="email"
                                    name="email"
                                />
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("confirm_email")}
                                    {...getInputProps("confirm_email", errors, touched, values, true)}
                                    placeholder={transPt("Confirm New email","customerUserProfile")}
                                    label={transPt("Confirm New email","customerUserProfile")}
                                    labelStyle={!dirty ? stylesLabel.secondaryLabel : stylesLabel.filledLabel}
                                />
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("current_password")}
                                    type={
                                        currentVisible
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("current_password", errors, touched, values, true)}
                                    placeholder={transPt("Password","customerUserProfile")}
                                    label={transPt("Password","customerUserProfile")}
                                    endAdornment={renderEndAdornment()}
                                    labelStyle={!dirty ? stylesLabel.secondaryLabel : stylesLabel.filledLabel}
                                />
                                <Typography className="hint">
                                {transPt("Forgot your password? Click","customerUserProfile")} <span onClick={() => goToForgotPassword()} className="redirect-link">{transPt("here","customerUserProfile")}.</span>.
                                </Typography>
                            </Box>
                            <Box className="box-wrapper">
                                    {dirty 
                                        ? <Button className="secondary-btn" data-test-id="email-clear-btn" onClick={() => resetForm()}>{transPt("Clear","customerUserProfile")}</Button> 
                                        : <Button className="secondary-btn" onClick={() => onClose()} data-test-id="email-cancel-btn">{transPt("Cancel","customerUserProfile")}</Button>
                                    }
                                    <Button data-test-id="email-save-btn" className="main-btn" type="submit">{transPt("Save","customerUserProfile")}</Button>
                            </Box>
                        </form>
                    </FormWrapper>
                )
              }}
          </Formik>
        </GenericModal>
    )
}

export default ResetEmailModal;