import { Box } from '@material-ui/core';
import React from 'react'
import Field from '../Field';
import moment from 'moment';
import DatePickerInput from '../../../../../components/src/DatePickerInput';
import { GenericFormikProps } from '../../types';

const StartDateChange = ({ 
        event, errors, touched, getFieldProps, setFieldValue, values, isReccurence, removeError, isAll 
    }: GenericFormikProps & { isReccurence: boolean}) => {
    const isSelectedAll = isAll && isReccurence;
    const oldTimeValue = isSelectedAll && event.search_start_date ? moment(event.search_start_date) : moment(event.start_date)

    return (
        <Box className="form-row">
            <Box style={{ width: '100%', borderBottom: '1px solid #D9D9D9' }}>
                <Field
                    title={isReccurence ? "Old Start Date" : "Old Appointment Date"}
                    withPadding
                    withOpacity
                    value={oldTimeValue.format("DD/MM/YYYY")}
                />
            </Box>
            <DatePickerInput
              removeError={removeError}
              errors={errors}
              field="start_date"
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              isReccurence={isReccurence}
              isFullWidth
            />
        </Box>
    )
}

export default StartDateChange;