import React, { CSSProperties } from "react";
// Customizable Area Start
import { Box, Button, MenuItem, Select, SelectProps, styled, Typography } from "@material-ui/core";
import ServiceDashboardController, { Props } from "./ServiceDashboardController.web";
import { chartBlue, chartGreen, chartRed, lossBlack, profitBlack, profitGreen, userGroup } from "../assets";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DoughnutChart from "./DoughnutChart.web";
import AppoinmentsDashboard from "./AppoinmentsDashboard.web";
import CustomersDashboard from "./CustomersDashboard.web";

const configJSON = require("../config.js");
const selectMenuProps: Partial<SelectProps['MenuProps']> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    horizontal: "left",
    vertical: "bottom",
  },
  transformOrigin: {
    horizontal: "left",
    vertical: "top",
  },
};
const CustomSelectMenuProps = {
  ...selectMenuProps,
  PaperProps: {
    style: {
      borderRadius: "8px"
    }
  }
}
interface IMenu {
  label: string,
  value: string,
}
interface IChartLabel {
  label: string,
  value: string,
  color: string
}
// Customizable Area End

export default class ServiceDashboard extends ServiceDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFilter = () => {
    return (
      <>
        <FilterWrapper>
          <SelectFilter
            defaultValue={""}
            displayEmpty
            disableUnderline
            MenuProps={CustomSelectMenuProps}
            IconComponent={CustomMenuIcon}
          >
            <CustomMenuItem value={""} disabled>Select Date Range</CustomMenuItem>
            {(configJSON.filterMenuList as IMenu[]).map((item) => {
              return <CustomMenuItem value={item.value}>
                {item.label}
              </CustomMenuItem>
            })}
          </SelectFilter>
        </FilterWrapper>
      </>
    )
  }
  renderFirstSection = () => {
    return (
      <RowBox>
        <SmallCard>
          <LeftSubBox>
            <StyledFont1 style={defaultStyle.colorBlue}>50</StyledFont1>
            <Typography style={defaultStyle.smallFont}>All Booking</Typography>
            <RowCenterBox>
              <img src={profitBlack} />
              <Typography style={defaultStyle.smallFont3}>20,3%</Typography>
            </RowCenterBox>
            <Typography style={defaultStyle.smallFont2}>( last Week )</Typography>
          </LeftSubBox>
          <RightSubBox>
            <img src={chartBlue} />
          </RightSubBox>
        </SmallCard>
        <SmallCard>
          <LeftSubBox>
            <StyledFont1 style={defaultStyle.colorGreen}>32</StyledFont1>
            <Typography style={defaultStyle.smallFont}>Completed</Typography>
            <RowCenterBox>
              <img src={profitBlack} />
              <Typography style={defaultStyle.smallFont3}>20,3%</Typography>
            </RowCenterBox>
            <Typography style={defaultStyle.smallFont2}>( last Week )</Typography>
          </LeftSubBox>
          <RightSubBox>
            <img src={chartGreen} />
          </RightSubBox>
        </SmallCard>
        <SmallCard>
          <LeftSubBox>
            <StyledFont1 style={defaultStyle.colorRed}>18</StyledFont1>
            <Typography style={defaultStyle.smallFont}>Cancelled</Typography>
            <RowCenterBox>
              <img src={lossBlack} />
              <Typography style={defaultStyle.smallFont3}>20,3%</Typography>
            </RowCenterBox>
            <Typography style={defaultStyle.smallFont2}>( last Week )</Typography>
          </LeftSubBox>
          <RightSubBox>
            <img src={chartRed} />
          </RightSubBox>
        </SmallCard>
      </RowBox>
    )
  }
  renderSecondSection = () => {
    return (
      <Section2Wrapper>
        <SubSection>
          {this.renderChart()}
        </SubSection>
        <SubSection>
          {this.renderMediumCards()}
        </SubSection>
      </Section2Wrapper>
    )
  }
  renderChart = () => {
    return (
      <ChartWrapper>
        <ChartTitleFont>Service Popularity</ChartTitleFont>
        <ChartBox>
          <DoughnutChart />
          <ChartLabelWrapper>
            {(configJSON.chartLabels as IChartLabel[]).map((item) => {
              return (
                <LabelBox>
                  <DotBox style={{ backgroundColor: item.color }}> </DotBox>
                  <Typography style={defaultStyle.chartLabelFont}>{item.label}</Typography>
                  <Typography style={defaultStyle.labelValueFont}>{item.value}</Typography>
                </LabelBox>
              )
            })}
          </ChartLabelWrapper>
        </ChartBox>
      </ChartWrapper>
    )
  }
  renderMediumCards = () => {
    return (
      <ColumnBox>
        <MediumCard>
          <LeftMediumBox>
            <TotalLabelFont>Total Revenue</TotalLabelFont>
            <TotalRevenueFont style={{ marginTop: 28 }}>49,209 €</TotalRevenueFont>
          </LeftMediumBox>
          <RightMediumBox>
            <Typography style={defaultStyle.mediumFont}>Change</Typography>
            <RowCenterBox2>
              <img src={profitGreen} />
              <StyledMediumFont style={defaultStyle.colorGreen}>+12%</StyledMediumFont>
            </RowCenterBox2>
            <Typography style={defaultStyle.smallFont4}>( last Week )</Typography>
          </RightMediumBox>
        </MediumCard>

        <MediumCard>
          <LeftMediumBox>
            <TotalLabelFont>Total Customers</TotalLabelFont>
            <RowCenterBox style={{ marginTop: 16 }}>
              <Typography style={defaultStyle.mediumFont1}>340</Typography>
              <img src={userGroup} />
            </RowCenterBox>
            <ViewDetailsBtn disableTouchRipple disableRipple onClick={this.openCustomerModal}>
              View Details <ViewRightIcon />
            </ViewDetailsBtn>
          </LeftMediumBox>
          <RightMediumBox>
            <Typography style={defaultStyle.mediumFont}>Change</Typography>
            <RowCenterBox2>
              <img src={profitGreen} />
              <StyledMediumFont style={defaultStyle.colorGreen}>+12%</StyledMediumFont>
            </RowCenterBox2>
            <Typography style={defaultStyle.smallFont4}>( last Week )</Typography>
          </RightMediumBox>
        </MediumCard>

      </ColumnBox>
    )
  }
  renderAppoinments = () => {
    return (
      <>
        <AppoinmentsDashboard navigation={this.props.navigation} />
      </>
    )
  }
  renderCustomerModal = () => {
    const { openCustomerModal } = this.state
    return (
      <>
        {openCustomerModal ?
          <CustomersDashboard
            navigation={this.props.navigation}
            handleClose={this.closeCustomerModal}
          />
          : <></>}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        {this.renderFilter()}
        {this.renderFirstSection()}
        {this.renderSecondSection()}
        {this.renderAppoinments()}
        {this.renderCustomerModal()}
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  backgroundColor: "#F6F4F9",
  padding: "64px"
})
const RowBox = styled(Box)({
  padding: "0 10px",
  display: "flex",
  flexDirection: "row",
  gap: "15px"
})

const SmallCard = styled(Box)({
  width: "100%",
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
  padding: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  height: "128px",
})
const LeftSubBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  alignItems: "center"
})
const RightSubBox = styled(Box)({
  height: "100%",
  display: "flex",
  alignItems: "end",
})
const StyledFont1 = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "36px",
  lineHeight: "36px",
})
const FilterWrapper = styled(Box)({
  marginBottom: "40px"
})

const SelectFilter = styled(Select)({
  background: "#FFF",
  borderRadius: "8px",
  height: "40px",
  width: "100%",
  maxWidth: "223px",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  color: "#94A3B8",
  "& .MuiSelect-select": {
    backgroundColor: "transparent",
    height: "40px",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  }
})
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: '#6F05EA',
  width: 32,
  height: 32,
  marginTop: "-4px",
  marginRight: "8px"
});
const CustomMenuItem = styled(MenuItem)({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "17px",
  fontFamily: "Montserrat",
  height: 56,
  padding: "0 30px",
  backgroundColor: "#FFF",
  color: "#6F05EA",
  "&.Mui-disabled": {
    color: "#94A3B8",
    backgroundColor: "#FFF",
  },
  "&.Mui-selected.Mui-disabled": {
    color: "#94A3B8",
    backgroundColor: "#FFF",
  },
  "&.Mui-selected": {
    backgroundColor: "#6F05EA",
    color: "#FFF"
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#6F05EA",
    color: "#FFF"
  },
})

const RowCenterBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "2px"
})

const Section2Wrapper = styled(Box)({
  margin: "15px 0",
  display: "flex",
  gap: "15px"
})
const SubSection = styled(Box)({
  flex: 1
})

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px"
})

const MediumCard = styled(Box)({
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 36px",
  height: 132
})
const LeftMediumBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
})
const RightMediumBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: '12.5px',
  alignItems: "center"
})
const RowCenterBox2 = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "9px"
})
const StyledMediumFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "26px",
  lineHeight: "18px",
  fontFamily: "Montserrat",
})
const TotalLabelFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "14px",
  fontFamily: "Montserrat",
  color: "#292D32"
})
const TotalRevenueFont = styled(Typography)({
  fontWeight: 500,
  fontSize: "40px",
  lineHeight: "28px",
  fontFamily: "Montserrat",
  color: "#292D32"
})
const ViewDetailsBtn = styled(Button)({
  textTransform: "none",
  padding: 0,
  marginTop: 9,
  height: 22,
  width: 'fit-content',
  backgroundColor: "transparent",
  "&:hover": {
    background: "none !important",
  },
  "& .MuiButton-label": {
    justifyContent: "start",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    fontFamily: "Montserrat",
    color: "#6F05EA"
  }
})
const ViewRightIcon = styled(KeyboardArrowRight)({
  marginLeft: 6
})

const ChartWrapper = styled(Box)({
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
  padding: "40px 36px",
})

const ChartTitleFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "14px",
  fontFamily: "Montserrat",
  color: "#000000",
  marginBottom: "28px"
})
const ChartBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  gap: "50px"
})
const ChartLabelWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "12px"
})
const LabelBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center"
})
const DotBox = styled(Box)({
  height: 12,
  width: 12,
  borderRadius: "50%"
})
const defaultStyle = {
  colorBlue: {
    color: "#6F05EA"
  } as CSSProperties,
  colorGreen: {
    color: "#059669"
  } as CSSProperties,
  colorRed: {
    color: "#C10000"
  } as CSSProperties,
  smallFont: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    color: "#34424B"
  } as CSSProperties,
  smallFont2: {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    fontFamily: "Montserrat",
    color: "#292D32"
  } as CSSProperties,
  smallFont3: {
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    color: "#34424B"
  } as CSSProperties,
  smallFont4: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "8px",
    fontFamily: "Montserrat",
    color: "#292D32"
  } as CSSProperties,
  chartLabelFont: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
    color: "#292D32"
  } as CSSProperties,
  labelValueFont: {
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
    color: "#0F172A"
  } as CSSProperties,
  mediumFont: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "13px",
    fontFamily: "Montserrat",
    color: "#292D32"
  } as CSSProperties,
  mediumFont1: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "15px",
    fontFamily: "Montserrat",
    color: "#292D32"
  } as CSSProperties,
}
// Customizable Area End
