export const enGeneralInfo = {
    "General":"General",
    "Services":"Services",
    "Prices":"Prices",
    "Commodities":"Commodities",
    "Rooms":"Rooms",
    "Spaces":"Spaces",
    "No results found!":"No results found!",
    "Allowed Pets":"Allowed Pets",
    //GENERAL
        "Schedule Information":"Schedule Information",
        "Opening & Closing Days/Hours":"Opening & Closing Days/Hours",
        "Select all":"Select all",
        "Accepted Animals":"Accepted Animals",
        "Lunch Break":"Lunch Break",
        //DAYS
        "Monday":"Monday",
        "Tuesday":"Tuesday",
        "Wednesday":"Wednesday",
        "Thursday":"Thursday",
        "Friday":"Friday",
        "Saturday":"Saturday",
        "Sunday":"Sunday",
        //ANIMALS
        "Dogs":"Dogs",
        "Cats":"Cats",
        "Bird":"Bird",
        "Fish":"Fish",
        "Rabbit":"Rabbit",
        "Reptile":"Reptile",
        "Rodents":"Rodents",
        //FIELD
        "Bio/About":"Bio/About",
        "Cancellation Policy":"Cancellation Policy",
        //ERROR
        "*Please enter Bio/About":"*Please enter Bio/About",
        "*Please enter Cancellation Policy":"*Please enter Cancellation Policy",
        "*Please select at least One":"*Please select at least One",
        "*Please select time range":"*Please select time range",
        "*End time is required":"*End time is required",
        //PLACEHOLDER
        "Write a description from your establishment.":"Write a description from your establishment.",
        "Write a Cancellation Policy.":"Write a Cancellation Policy.",
    //SPACE
        "To create a space, we'll need some basic information.":"To create a space, we'll need some basic information.",
        "Please fill in the general details and commodity specifics first!":"Please fill in the general details and commodity specifics first!",
        "Edit Spaces":"Edit Spaces",
        "Add Spaces":"Add Spaces",
    //SERVICE
        "To create a service, we'll need some basic information.":"To create a service, we'll need some basic information.",
        "Please fill in the general details first!":"Please fill in the general details first!",
        "Edit Services":"Edit Services",
    //ROOMS
        "To create a Room, we'll need some basic information.":"To create a Room, we'll need some basic information.",
        "Edit Rooms":"Edit Rooms",
    //PRICE
        "Select Recurrence":"Select Recurrence",
        "Once a week":"Once a week",
        "Twice a week":"Twice a week",
        "3 times a week":"3 times a week",
        "4 times a week":"4 times a week",
        "5 times a week":"5 times a week",
        "6 times a week":"6 times a week",
        "7 times a week":"7 times a week",
        "Transportation":"Transportation",
        "Medication":"Medication",
        "To add a Price, we'll need some basic data.":"To add a Price, we'll need some basic data.",
        "Please create the spaces first!":"Please create the spaces first!",
        "Please create the services first!":"Please create the services first!",
        "Please create the rooms first!":"Please create the rooms first!",
        "All prices are per day.":"All prices are per day.",
        "Room Name":"Room Name",
        "Price in €/day":"Price in €/day",
        "Service Name":"Service Name",
        "Price in €":"Price in €",
        "*Please enter valid Number":"*Please enter valid Number",
    //FORM
        //FIELD
        "Service":"Service",
        "Capacity":"Capacity",
        "Allowed Pet":"Allowed Pet",
        "Service Time":"Service Time",
        "Description":"Description",
        "Service Gallery":"Service Gallery",
        "Room Title":"Room Title",
        "Room Gallery":"Room Gallery",
        "Service Duration":"Service Duration",
        "Space Title":"Space Title",
        "Space Gallery":"Space Gallery",
        //PLACEHOLDER
        "Select Service Type":"Select Service Type",
        "Enter Capacity":"Enter Capacity",
        "Select Pet":"Select Pet",
        "Select Time":"Select Time",
        "Enter Description":"Enter Description",
        "upload file (png,jpg)":"upload file (png,jpg)",
        "Select Room Type":"Select Room Type",
        "Enter Service":"Enter Service",
        //ERROR
        "*Service Title is required":"*Service Title is required",
        "*Capacity is required":"*Capacity is required",
        "*Capacity must be a number":"*Capacity must be a number",
        "*Allowed Pet is required":"*Allowed Pet is required",
        "*Service Time is required":"*Service Time is required",
        "*Description is required":"*Description is required",
        "*One image is required":"*One image is required",
        "*Room Title is required":"*Room Title is required",
        "*Service Duration is required":"*Service Duration is required",
        "*Space Title is required":"*Space Title is required",
    //BUTTON
    "Edit":"Edit",
    "Cancel":"Cancel",
    "Save":"Save",
    "Add New Spaces":"Add New Spaces",
    "Add Services":"Add Services",
    "Add Rooms":"Add Rooms"
}

export const ptGeneralInfo = {
    "General": "Em geral",
    "Services": "Serviços",
    "Prices": "Preços",
    "Commodities": "Mercadorias",
    "Rooms": "Quartos",
    "Spaces": "Espaços",
    "No results found!": "Nenhum resultado encontrado!",
    "Allowed Pets": "Animais de estimação permitidos",
    //GENERAL
        "Schedule Information": "Informações de programação",
        "Opening & Closing Days/Hours": "Dias/Horários de Abertura e Fechamento",
        "Select all": "Selecionar tudo",
        "Accepted Animals": "Animais Aceitos",
        "Lunch Break": "Pausa para almoço",
        //DAYS
        "Monday": "Segunda-feira",
        "Tuesday": "Terça-feira",
        "Wednesday": "Quarta-feira",
        "Thursday": "Quinta-feira",
        "Friday": "Sexta-feira",
        "Saturday": "Sábado",
        "Sunday": "Domingo",
        //ANIMALS
        "Dogs": "Cães",
        "Cats": "Gatos",
        "Bird": "Pássaro",
        "Fish": "Peixe",
        "Rabbit": "Coelho",
        "Reptile": "Réptil",
        "Rodents": "Roedores",
        //FIELD
        "Bio/About": "Biografia/Sobre",
        "Cancellation Policy": "Política de Cancelamento",
        //ERROR
        "*Please enter Bio/About": "*Por favor, insira Bio/Sobre",
        "*Please enter Cancellation Policy": "*Por favor, insira a política de cancelamento",
        "*Please select at least One": "*Selecione pelo menos um",
        "*Please select time range": "*Selecione o intervalo de tempo",
        "*End time is required":"*O horário de término é obrigatório",
        //PLACEHOLDER
        "Write a description from your establishment.": "Escreva uma descrição do seu estabelecimento.",
        "Write a Cancellation Policy.": "Escreva uma política de cancelamento.",
    //SPACE
        "To create a space, we'll need some basic information.": "Para criar um espaço, precisaremos de algumas informações básicas.",
        "Please fill in the general details and commodity specifics first!": "Por favor, preencha primeiro os detalhes gerais e as especificidades da mercadoria!",
        "Edit Spaces":"Editar espaços",
        "Add Spaces":"Adicionar espaços",
    //SERVICE
        "To create a service, we'll need some basic information.": "Para criar um serviço, precisaremos de algumas informações básicas.",
        "Please fill in the general details first!": "Por favor, preencha os detalhes gerais primeiro!",
        "Edit Services":"Editar serviços",
    //ROOMS
        "To create a Room, we'll need some basic information.": "Para criar uma Sala, precisaremos de algumas informações básicas.",
        "Edit Rooms":"Editar salas",
    //PRICE
        "Select Recurrence": "Selecione Recorrência",
        "Once a week": "Uma vez por semana",
        "Twice a week": "Duas vezes por semana",
        "3 times a week": "3 vezes por semana",
        "4 times a week": "4 vezes por semana",
        "5 times a week": "5 vezes por semana",
        "6 times a week": "6 vezes por semana",
        "7 times a week": "7 vezes por semana",
        "Transportation": "Transporte",
        "Medication": "Medicamento",
        "To add a Price, we'll need some basic data.": "Para adicionar um Preço, precisaremos de alguns dados básicos.",
        "Please create the spaces first!": "Por favor, crie os espaços primeiro!",
        "Please create the services first!": "Por favor, crie os serviços primeiro!",
        "Please create the rooms first!": "Por favor, crie os quartos primeiro!",
        "All prices are per day.": "Todos os preços são por dia.",
        "Room Name": "Nome do quarto",
        "Price in €/day": "Preço em €/dia",
        "Service Name": "Nome do serviço",
        "Price in €": "Preço em €",
        "*Please enter valid Number": "*Por favor insira um número válido",
    //FORM
        //FIELD
        "Service": "Serviço",
        "Capacity": "Capacidade",
        "Allowed Pet": "Animal de estimação permitido",
        "Service Time": "Tempo de serviço",
        "Description": "Descrição",
        "Service Gallery": "Galeria de serviços",
        "Room Title": "Título da sala",
        "Room Gallery": "Galeria de salas",
        "Service Duration": "Duração do serviço",
        "Space Title": "Título do espaço",
        "Space Gallery": "Galeria Espacial",
        //PLACEHOLDER
        "Select Service Type": "Selecione o tipo de serviço",
        "Enter Capacity": "Insira a capacidade",
        "Select Pet": "Selecione animal de estimação",
        "Select Time": "Selecione a hora",
        "Enter Description": "Insira a descrição",
        "upload file (png,jpg)": "enviar arquivo (png,jpg)",
        "Select Room Type": "Selecione o tipo de quarto",
        "Enter Service": "Entrar no serviço",
        //ERROR
        "*Service Title is required": "*Título do serviço é obrigatório",
        "*Capacity is required": "*A capacidade é necessária",
        "*Capacity must be a number": "*A capacidade deve ser um número",
        "*Allowed Pet is required": "*É necessário animal de estimação permitido",
        "*Service Time is required": "*É necessário tempo de serviço",
        "*Description is required": "*A descrição é obrigatória",
        "*One image is required": "*É necessária uma imagem",
        "*Room Title is required": "*O título do quarto é obrigatório",
        "*Service Duration is required": "*A duração do serviço é obrigatória",
        "*Space Title is required": "*O título do espaço é obrigatório",
    //BUTTON
    "Edit": "Editar",
    "Cancel": "Cancelar",
    "Save": "Salvar",
    "Add New Spaces": "Adicionar novos espaços",
    "Add Services": "Adicionar serviços",
    "Add Rooms": "Adicionar salas",
}