import React from "react";
// Customizable Area Start
import ViewPetController, { Props } from "./ViewPetController.web";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../../components/src/AppFooter/appFooter";
import { Avatar, Box, IconButton, styled, Typography, Tabs, Tab, TextField, Badge } from "@material-ui/core";
import { CatAvatar, CheckedSwitch, PawBlue, UnCheckedSwitch } from "../assets";
import { KeyboardArrowLeft } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
const webConfigJSON = require("../config.js");

interface ITraits {
    left: string,
    right: string,
    key: string,
    value: string,
}
// Customizable Area End

export default class ViewPet extends ViewPetController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderTabSection = () => {
        return (
            <ContentWrapper>
                <CenterBox>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={this.state.tab}
                        onChange={(event, newValue) => {
                            this.setState({ tab: newValue })
                        }}
                        data-testid="tabs-wrapper"
                        TabIndicatorProps={{
                            style: style.tabIndicator,
                        }}
                    >
                        <CustomTab label={"General"} value={1} />
                        <CustomTab label={"Medical"} value={2} />
                        <CustomTab label={"Traits"} value={3} />
                    </Tabs>

                    {this.renderTabContent()}
                </CenterBox>
            </ContentWrapper>
        )
    }
    renderTabContent = () => {
        const { tab } = this.state
        switch (tab) {
            case 1:
                return this.generalSection()
            case 2:
                return this.medicalSection()
            case 3:
                return this.traitsSection()
        }
    }
    generalSection = () => {
        return (
            <StyledCard>
                <GeneralTitleBox>
                    <img src={PawBlue} />
                    About
                </GeneralTitleBox>
                <GeneralWrapper>
                    <RowBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Animal</LabelFieldFont>
                            <StyledField fullWidth disabled
                                value={"Cat"}
                            />
                        </StyledBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Gender</LabelFieldFont>
                            <StyledField fullWidth disabled
                                value={"Female"}
                            />
                        </StyledBox>
                    </RowBox>
                    <RowBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Breed</LabelFieldFont>
                            <StyledField fullWidth disabled
                                value={"Orange Tabby Cat"}
                            />
                        </StyledBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Birthdate</LabelFieldFont>
                            <StyledField fullWidth disabled
                                value={"09/04/2020"}
                            />
                        </StyledBox>
                    </RowBox>
                    <RowBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Weight</LabelFieldFont>
                            <StyledField fullWidth disabled
                                value={"25 kg"}
                            />
                        </StyledBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Size</LabelFieldFont>
                            <StyledField fullWidth disabled
                                value={"Medium (12-28 kg)"}
                            />
                        </StyledBox>
                    </RowBox>
                    <RowBox>
                        <StyledBox maxWidth={361}>
                            <LabelFieldFont>Fur</LabelFieldFont>
                            <StyledField fullWidth disabled value={"Medium coat"} />
                        </StyledBox>
                    </RowBox>
                </GeneralWrapper>
            </StyledCard>
        )
    }
    medicalSection = () => {
        return (
            <StyledCard display={"flex"} flexDirection={"column"} gridGap={"57px"}>
                <Box>
                    <Styledheading>Health bulletin</Styledheading>
                    <Box maxWidth={730}>
                        <StyledBox marginBottom={"35px"}>
                            <LabelFieldFont>Medical conditions</LabelFieldFont>
                            <StyledField fullWidth disabled value={""} />
                        </StyledBox>
                        <StyledBox marginBottom={"35px"}>
                            <LabelFieldFont>Allergies</LabelFieldFont>
                            <StyledField fullWidth disabled value={""} />
                        </StyledBox>
                        <StyledBox marginBottom={"35px"}>
                            <LabelFieldFont>Usual medication</LabelFieldFont>
                            <StyledField fullWidth disabled value={""} />
                        </StyledBox>
                        <StyledBox>
                            <LabelFieldFont>Last health check up</LabelFieldFont>
                            <StyledField fullWidth disabled value={"03/02/2024"} />
                        </StyledBox>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} gridRowGap={"22px"}>
                    <ChipBox>
                        <img src={CheckedSwitch} />
                        <TraitFonts isActive>Microchip</TraitFonts>
                    </ChipBox>
                    <StyledBox>
                        <LabelFieldFont>Microchip number</LabelFieldFont>
                        <StyledField fullWidth disabled value={"FECAVA"} />
                    </StyledBox>
                    <ChipBox>
                        <img src={UnCheckedSwitch} />
                        <TraitFonts isActive>Vaccines up to date</TraitFonts>
                    </ChipBox>
                    <ChipBox>
                        <img src={CheckedSwitch} />
                        <TraitFonts isActive>Sterilized</TraitFonts>
                    </ChipBox>

                </Box>

                <Box maxWidth={730}>
                    <Styledheading>Usual veterinary</Styledheading>
                    <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                        <StyledBox>
                            <LabelFieldFont>Clinic Address</LabelFieldFont>
                            <StyledField fullWidth disabled value={""} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Doctor</LabelFieldFont>
                            <StyledField fullWidth disabled value={"Dra. Maria Santos"} />
                        </StyledBox>
                    </RowBox>
                    <RowBox rowGap={27} columnGap={27}>
                        <StyledBox>
                            <LabelFieldFont>Clinic/Hospital</LabelFieldFont>
                            <StyledField fullWidth disabled value={"Centro Veterinário do Porto"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Phone</LabelFieldFont>
                            <PhoneInputWrapper>
                                <PhoneInput
                                    inputClass="custom-phone"
                                    buttonStyle={style.phoneButtonStyle}
                                    inputStyle={style.phoneInputStyle}
                                    dropdownStyle={style.phoneDropDownStyle}
                                    placeholder="Your contact number"
                                    value={"+351123456789"}
                                    disabled
                                />
                            </PhoneInputWrapper>
                        </StyledBox>
                    </RowBox>
                </Box>

                <Box maxWidth={730}>
                    <Styledheading>Insurance</Styledheading>
                    <RowBox rowGap={27} columnGap={27}>
                        <StyledBox>
                            <LabelFieldFont>Company</LabelFieldFont>
                            <StyledField fullWidth disabled value={"Fidelidade"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Policy number</LabelFieldFont>
                            <StyledField fullWidth disabled value={"3737910474719"} />
                        </StyledBox>
                    </RowBox>
                </Box>

                <Box maxWidth={730}>
                    <Styledheading>Emergency contacts</Styledheading>
                    <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                        <StyledBox>
                            <LabelFieldFont>Primary Contact</LabelFieldFont>
                            <StyledField fullWidth disabled value={"Joana Esteves"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Phone</LabelFieldFont>
                            <PhoneInputWrapper>
                                <PhoneInput
                                    inputClass="custom-phone"
                                    buttonStyle={style.phoneButtonStyle}
                                    inputStyle={style.phoneInputStyle}
                                    dropdownStyle={style.phoneDropDownStyle}
                                    placeholder="Your contact number"
                                    value={"+351123456789"}
                                    disabled
                                />
                            </PhoneInputWrapper>
                        </StyledBox>
                    </RowBox>
                    <RowBox rowGap={27} columnGap={27}>
                        <StyledBox>
                            <LabelFieldFont>Secondary Contact</LabelFieldFont>
                            <StyledField fullWidth disabled value={"Marco Dias"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Phone</LabelFieldFont>
                            <PhoneInputWrapper>
                                <PhoneInput
                                    inputClass="custom-phone"
                                    buttonStyle={style.phoneButtonStyle}
                                    inputStyle={style.phoneInputStyle}
                                    dropdownStyle={style.phoneDropDownStyle}
                                    placeholder="Your contact number"
                                    value={"+351123456789"}
                                    disabled
                                />
                            </PhoneInputWrapper>
                        </StyledBox>
                    </RowBox>
                </Box>

            </StyledCard>
        )
    }
    traitsSection = () => {
        const dotBoxes = (value: string, className: "mobile" | "desktop") => {
            return (
                <>
                    <DotWrapper className={className}>
                        <CenterDotBox className="content-start"><CustomDot selected={value.includes("1")} /></CenterDotBox>
                        <CenterDotBox><CustomDot selected={value.includes("2")} /></CenterDotBox>
                        <CenterDotBox className="content-end"><CustomDot selected={value.includes("3")} /></CenterDotBox>
                    </DotWrapper>
                </>
            )
        }
        return (
            <StyledCard display={"flex"} flexDirection={"column"} gridGap={"48px"}>
                <Box>
                    <Styledheading>Personality</Styledheading>
                    <TraitFonts isActive>How would you describe your pet in unknown environments?</TraitFonts>
                    {webConfigJSON.petTraits.map((record: ITraits) => {
                        return (
                            <>
                                <TraitBox>
                                    <Box width={"110px"}><TraitFonts isActive={record.value}>{record.left}</TraitFonts></Box>
                                    {dotBoxes(record.value, "desktop")}
                                    <TraitFonts isActive={record.value}>{record.right}</TraitFonts>
                                </TraitBox>
                                {dotBoxes(record.value, 'mobile')}
                            </>
                        )
                    })}
                </Box>
                <Box maxWidth={730}>
                    <Styledheading>Habits & Routines</Styledheading>
                    <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                        <StyledBox>
                            <LabelFieldFont>How many walks</LabelFieldFont>
                            <StyledField fullWidth disabled value={"3"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Walking schedules</LabelFieldFont>
                            <StyledField fullWidth disabled value={"7:00, 15:00, 23:00"} />
                        </StyledBox>
                    </RowBox>
                    <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                        <StyledBox>
                            <LabelFieldFont>How many potty</LabelFieldFont>
                            <StyledField fullWidth disabled value={"3"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Potty schedules</LabelFieldFont>
                            <StyledField fullWidth disabled value={"7:00, 15:00, 23:00"} />
                        </StyledBox>
                    </RowBox>
                    <RowBox rowGap={27} columnGap={27}>
                        <StyledBox>
                            <LabelFieldFont>How many meals</LabelFieldFont>
                            <StyledField fullWidth disabled value={"3"} />
                        </StyledBox>
                        <StyledBox >
                            <LabelFieldFont>Meal schedules</LabelFieldFont>
                            <StyledField fullWidth disabled value={"8:00, 20:00"} />
                        </StyledBox>
                    </RowBox>
                </Box>
            </StyledCard>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <NavigationMenu id={''} navigation={this.props.navigation} />
                <MainWrapper>
                    <BackButtonBox>
                        <BackButton disableRipple data-testid='back-to-list'>
                            <BackArrowIcon /><BackButtonTitle>Goldie</BackButtonTitle>
                        </BackButton>
                    </BackButtonBox>

                    <AvatarBox>
                        <StyledAvatar src={CatAvatar} />
                        <CustomerName>Goldie</CustomerName>
                    </AvatarBox>

                    {this.renderTabSection()}

                </MainWrapper>
                <AppFooter navigation={this.props.navigation} />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
    padding: "32px",
    backgroundColor: "#F6F4F9",
    "@media (max-width: 600px)": {
        padding: "24px",
    },
    "@media (max-width: 400px)": {
        padding: "12px",
    },
})
const BackButtonBox = styled(Box)({
    margin: "48px 32px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        margin: "32px 16px",
    },
    "@media (max-width: 600px)": {
        margin: "32px 8px",
    },
})
const BackButton = styled(IconButton)({
    padding: 0,
    "&:hover": {
        background: "none !important",
    },
})
const BackArrowIcon = styled(KeyboardArrowLeft)({
    color: "#6F05EA",
    width: 48,
    height: 48
})
const BackButtonTitle = styled(Typography)({
    marginLeft: "12px",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "20px",
    color: "#0F172A",
    "@media (min-width: 600px) and (max-width: 991px)": {
        fontSize: "22px",
    },
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
})

const AvatarBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 24
})
const StyledAvatar = styled(Avatar)({
    height: 148,
    width: 148,
    "@media (max-width: 600px)": {
        height: 120,
        width: 120,
    },
})
const CustomerName = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#000000"
})
const ContentWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
})


const CustomTab = styled(Tab)({
    minHeight: 40,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    color: '#B7B7B7',
    fontWeight: 500,
    flex: 1,
    textTransform: 'none',
    maxWidth: "33%",
    borderBottom: "1px solid #B7B7B7",
    "&.Mui-selected": {
        color: '#6F05EA',
        fontWeight: 700,
    },
    "@media (max-width: 600px)": {
        fontSize: "14px",
    },
})
const CenterBox = styled(Box)({
    width: "100%",
    maxWidth: "966px",
    marginTop: "28px"
})
const StyledCard = styled(Box)({
    margin: "40px 0",
    padding: "32px 38px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    "@media (max-width: 600px)": {
        padding: "24px",
    },
})
const GeneralTitleBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "7px",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
    marginBottom: 32
})
const GeneralWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "32px"
})
const RowBox = styled(Box)(({ rowGap, columnGap }: any) => ({
    display: "flex",
    flexDirection: "row",
    rowGap: rowGap || 32,
    columnGap: columnGap || 52,
    "@media (max-width: 600px)": {
        flexWrap: 'wrap'
    },
}));
const StyledBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "4px",
    "@media (max-width: 600px)": {
        maxWidth: "unset",
    },
})
const LabelFieldFont = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6F05EA",
})
const StyledField = styled(TextField)({
    "& .MuiInputAdornment-root": {
        margin: 0
    },
    "& .MuiInputBase-root": {
        height: "34px",
    },
    "& .MuiInput-underline ": {
        borderColor: "#D9D9D9 !important",
    },
    "& .MuiInput-underline.Mui-disabled::before ": {
        borderBottomStyle: "solid"
    },
    "& .MuiInput-underline::before ": {
        borderColor: "#D9D9D9 !important",
    },
    "& .MuiInput-underline::after ": {
        borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputBase-input": {
        padding: "8px",
        fontWeight: 500,
        fontSize: "18px",
        fontFamily: "Montserrat",
        color: "#000000",
        "@media (max-width: 600px)": {
            fontSize: "16px",
        },
        "@media (max-width: 400px)": {
            fontSize: "14px",
        },
    }
})
const Styledheading = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#6F05EA",
    marginBottom: "35px",
    "@media (max-width: 600px)": {
        fontSize: "20px",
    },
    "@media (max-width: 400px)": {
        fontSize: "18px",
    },
})
const ChipBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "22px",
    alignItems: "center"
})
const TraitFonts = styled(Typography)(({ isActive }: any) => ({
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    color: isActive ? "#000000" : "#777777",
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
    "@media (max-width: 400px)": {
        fontSize: "14px",
    },
}))
const DotWrapper = styled(Box)({
    "&.desktop": {
        display: "flex",
        "@media (max-width: 751px)": {
            display: "none"
        }
    },
    "&.mobile": {
        display: "none",
        "@media (max-width: 751px)": {
            marginTop: "12px",
            display: "flex",
            width: "unset"
        }
    }
})
const CenterDotBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "105",
    "&.content-start": {
        "@media (max-width: 751px)": {
            justifyContent: "start",
        }
    },
    "&.content-end": {
        "@media (max-width: 751px)": {
            justifyContent: "end",
        }
    },
    "@media (max-width: 751px)": {
        width: "100%"
    }

})
const CustomDot = styled(Badge)(({ selected }: any) => ({
    borderRadius: "50%",
    height: selected ? 20 : 11,
    width: selected ? 20 : 11,
    backgroundColor: selected ? "#6F05EA" : "#E2CDFB",
}))
const TraitBox = styled(Box)({
    marginTop: "35px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 751px)": {
        justifyContent: "space-between"
    }
})
const PhoneInputWrapper = styled(Box)({
    "& .react-tel-input input": {
        "@media (max-width: 600px)": {
            fontSize: "16px !important",
        },
        "@media (max-width: 400px)": {
            fontSize: "14px !important",
        },
    }

})

const style = {
    tabIndicator: {
        backgroundColor: '#6F05EA',
        height: "3px",
        bottom: "6px"
    } as React.CSSProperties,
    phoneButtonStyle: {
        border: "none",
        background: "transparent",
    } as React.CSSProperties,
    phoneInputStyle: {
        border: "none",
        width: "100%",
        height: "34px",
        borderRadius: "0px",
        borderBottom: "1px solid #D9D9D9",
        paddingLeft: "40px",
        cursor: "auto",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "16px",
        color: "#000000",
        fontFamily: "Montserrat",
    } as React.CSSProperties,
    phoneDropDownStyle: {
        fontSize: "13px"
    } as React.CSSProperties,
}
// Customizable Area End
