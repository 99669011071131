export const enVendorLanding = {
    "Select Your Establishment": "Select Your Establishment",
    "Add New Establishment": "Add New Establishment",
    "Please add an establishment":"Please add an establishment",
    //NEW ESTABLISHMENT
        //TITLE
        "Contact us": "Contact us",
        "Social Media Link": "Social Media Link",
        //FIELD
        "Establishment Name": "Establishment Name",
        "Address": "Address",
        "Country": "Country",
        "City": "City",
        "Zip Code": "Zip Code",
        "Email": "Email",
        "Phone": "Phone",
        "Facebook": "Facebook",
        "Instagram": "Instagram",
        "Linkedin": "Linkedin",
        "TikTok": "TikTok",
        //PLACEHOLDER
        "Your address": "Your address",
        "Your country": "Your country",
        "Your city": "Your city",
        "Your Email": "Your Email",
        "Enter Facebook Link": "Enter Facebook Link",
        "Enter Instagram Link": "Enter Instagram Link",
        "Enter Linkedin Link": "Enter Linkedin Link",
        "Enter TikTok Link": "Enter TikTok Link",
        //ERROR
        "File must be a PNG, JPEG, or JPG and less than 5 MB.": "File must be a PNG, JPEG, or JPG and less than 5 MB.",
        "*Please Enter Name": "*Please Enter Name",
        "*Address is Required.": "*Address is Required.",
        "*Please Select Country": "*Please Select Country",
        "*Please Select city": "*Please Select city",
        "*Please Enter ZipCode": "*Please Enter ZipCode",
        "*Please enter email": "*Please enter email",
        "Invalid email address": "Invalid email address",
        "*Please Enter Your Phone Number": "*Please Enter Your Phone Number",
        "Enter Valid link for facebook link": "Enter Valid link for facebook link",
        "Enter Valid link for instagram link": "Enter Valid link for instagram link",
        "Enter Valid link for linkedin link": "Enter Valid link for linkedin link",
        "Enter Valid link for tikTok link": "Enter Valid link for tikTok link",
        //BUTTON
        "Back": "Back",
        "Cancel": "Cancel",
        "Save": "Save",
    //LOGOUT
        "You're Logging Out of Petzai, are you sure?":"You're Logging Out of Petzai, are you sure?",
        //BUTTON
        "Yes, I'm Sure":"Yes, I'm Sure",
        "No, keep me Logged In":"No, keep me Logged In",
    //MANAGEMENT
        //TAB 
        "General Information":"General Information",
        "Password & Security":"Password & Security",
        "Establishments Control":"Establishments Control",
        "Finances":"Finances",
        //GENERAL INFORMATION
            "Name":"Name",
            "General Info":"General Info",
            "To change your account details, please contact Petzai admins.":"To change your account details, please contact Petzai admins.",
            "Update Email":"Update Email",
            "Update Phone":"Update Phone",
            //FIELD
            "Full Name":"Full Name",
            "Company Name":"Company Name",
            "IBAN":"IBAN",
            "Tax Number":"Tax Number",
            "Client ID":"Client ID",
            //PLACEHOLDER
            "Your name":"Your name",
            "Your Company name":"Your Company name",
            "IBAN number":"IBAN number",
            "Your Tax Number":"Your Tax Number",
            "Your contact number":"Your contact number",
            //ERROR
            "*Enter your password.":"*Enter your password.",
            "Password should include at least 8 characters, one number, and one letter.":"Password should include at least 8 characters, one number, and one letter.",
            "*Please Enter Email Confirmation":"*Please Enter Email Confirmation",
            "New phone number and confirmation phone number do not match":"New phone number and confirmation phone number do not match",
            "*Please Enter Your Old Phone Number":"*Please Enter Your Old Phone Number",
            "*Email does not match.":"*Email does not match.",
            //MODEL
            "Current Password":"Current Password",
            "Forget Password":"Forget Password",
            "Add Email":"Add Email",
            "Confirm New Email":"Confirm New Email",
            "New Phone Number":"New Phone Number",
            "Confirm Phone Number":"Confirm Phone Number",
            //BUTTON
            "Edit":"Edit",
            "Continue":"Continue",
        //PASSWORD SECURITY
            "Password reset":"Password reset",
            "Password Reset":"Password Reset",
            "Reset Password":"Reset Password",
            "Logged sessions":"Logged sessions",
            "You're currently logged in on these devices:":"You're currently logged in on these devices:",
            "This device":"This device",
            "Are You Sure you want to End Session with ":"Are You Sure you want to End Session with ",
            "No results found!":"No results found!",
            //FIELD
            "New Password":"New Password",
            "Confirm New Password":"Confirm New Password",
            //ERROR
            "New password must be different from current password":"New password must be different from current password",
            "*Enter your new password.":"*Enter your new password.",
            "*Enter your Confirm Password.":"*Enter your Confirm Password.",
            "Confirm passwords does not match with password.":"Confirm passwords does not match with password.",
            //BUTTON
            "End Session":"End Session",
        //FINANCES
            "Payments":"Payments",
            "Search":"Search",
            "Status":"Status",
            "Pending":"Pending",
            "Paid":"Paid",
            //TABLE
            "Order ID":"Order ID",
            "Appointment Date":"Appointment Date",
            "Amount":"Amount",
            "Action":"Action"
}

export const ptVendorLanding = {
    "Select Your Establishment": "Selecione seu estabelecimento",
    "Add New Establishment": "Adicionar novo estabelecimento",
    "Please add an establishment":"Adicione um estabelecimento",
    //NEW ESTABLISHMENT
        //TITLE
        "Contact us": "Contate-nos",
        "Social Media Link": "Link de mídia social",
        //FIELD
        "Establishment Name": "Nome do Estabelecimento",
        "Address": "Endereço",
        "Country": "País",
        "City": "Cidade",
        "Zip Code": "CEP",
        "Email": "E-mail",
        "Phone": "Telefone",
        "Facebook": "Facebook",
        "Instagram": "Instagram",
        "Linkedin": "Linkedin",
        "TikTok": "TikTok",
        //PLACEHOLDER
        "Your address": "Seu endereço",
        "Your country": "Seu país",
        "Your city": "Sua cidade",
        "Your Email": "Seu e-mail",
        "Enter Facebook Link": "Digite o link do Facebook",
        "Enter Instagram Link": "Digite o link do Instagram",
        "Enter Linkedin Link": "Entre no link do Linkedin",
        "Enter TikTok Link": "Digite o link do TikTok",
        //ERROR
        "File must be a PNG, JPEG, or JPG and less than 5 MB.": "O arquivo deve ser PNG, JPEG ou JPG e ter menos de 5 MB.",
        "*Please Enter Name": "*Por favor, insira o nome",
        "*Address is Required.": "*O endereço é obrigatório.",
        "*Please Select Country": "*Selecione o país",
        "*Please Select city": "*Selecione a cidade",
        "*Please Enter ZipCode": "*Insira o CEP",
        "*Please enter email": "*Insira o e-mail",
        "Invalid email address": "Endereço de e-mail inválido",
        "*Please Enter Your Phone Number": "*Por favor, insira seu número de telefone",
        "Enter Valid link for facebook link": "Insira um link válido para o link do Facebook",
        "Enter Valid link for instagram link": "Insira o link válido para o link do Instagram",
        "Enter Valid link for linkedin link": "Insira um link válido para o link do LinkedIn",
        "Enter Valid link for tikTok link": "Insira um link válido para o link do tikTok",
        //BUTTON
        "Back": "Voltar",
        "Cancel": "Cancelar",
        "Save": "Salvar",
    //LOGOUT
        "You're Logging Out of Petzai, are you sure?":"Você está saindo do Petzai, tem certeza?",
        //BUTTON
        "Yes, I'm Sure":"Sim, tenho certeza",
        "No, keep me Logged In":"Não, mantenha-me conectado",
    //MANAGEMENT
        //TAB 
        "General Information": "Informações gerais ",
        "Password & Security": "Senha e segurança ",
        "Establishments Control": "Controle de Estabelecimentos ",
        "Finances": "Finanças ",
        //GENERAL INFORMATION
            "Name": "Nome ",
            "General Info": "Informações Gerais ",
            "To change your account details, please contact Petzai admins.": "Para alterar os detalhes da sua conta, entre em contato com os administradores do Petzai. ",
            "Update Email": "Atualizar e-mail ",
            "Update Phone": "Atualizar telefone ",
            //FIELD
            "Full Name": "Nome completo ",
            "Company Name": "nome da empresa ",
            "IBAN": "IBAN ",
            "Tax Number": "Número fiscal ",
            "Client ID": "ID do cliente ",
            //PLACEHOLDER
            "Your name": "Seu nome ",
            "Your Company name": "Nome da sua empresa ",
            "IBAN number": "Número IBAN ",
            "Your Tax Number": "Seu número fiscal ",
            "Your contact number": "Seu número de contato ",
            //ERROR
            "*Enter your password.": "*Digite sua senha.",
            "Password should include at least 8 characters, one number, and one letter.": "A senha deve incluir pelo menos 8 caracteres, um número e uma letra. ",
            "*Please Enter Email Confirmation": "*Por favor, insira a confirmação do e-mail ",
            "New phone number and confirmation phone number do not match": "O novo número de telefone e o número de telefone de confirmação não coincidem",
            "*Please Enter Your Old Phone Number": "*Insira seu número de telefone antigo",
            "*Email does not match.":"*E-mail não corresponde.",
            //MODEL
            "Current Password": "Senha atual",
            "Forget Password": "Esqueci a senha",
            "Add Email": "Adicionar e-mail",
            "Confirm New Email": "Confirmar novo e-mail",
            "New Phone Number": "Novo número de telefone",
            "Confirm Phone Number": "Confirmar número de telefone",
            //BUTTON
            "Edit": "Editar ",
            "Continue": "Continuar",
        //PASSWORD SECURITY
            "Password reset": "Redefinição de senha",
            "Password Reset":"Redefinição de senha",
            "Reset Password": "Redefinir senha",
            "Logged sessions": "Sessões registradas",
            "You're currently logged in on these devices:": "Você está conectado nestes dispositivos:",
            "This device": "Este dispositivo",
            "Are You Sure you want to End Session with ": "Tem certeza de que deseja encerrar a sessão com ",
            "No results found!":"Nenhum resultado encontrado!",
            //FIELD
            "New Password":"Nova Senha",
            "Confirm New Password":"Confirme a nova senha",
            //ERROR
            "New password must be different from current password":"A nova senha deve ser diferente da senha atual",
            "*Enter your new password.":"*Digite sua nova senha.",
            "*Enter your Confirm Password.":"*Digite sua Confirmação de Senha.",
            "Confirm passwords does not match with password.":"Confirme se as senhas não correspondem à senha.",
            //BUTTON
            "End Session": "Encerrar sessão",
        //FINANCES
            "Payments":"Pagamentos",
            "Search":"Procurar",
            "Status":"Status",
            "Pending":"Pendente",
            "Paid":"Pago",
            //TABLE
            "Order ID":"ID do pedido",
            "Appointment Date":"Data da Consulta",
            "Amount":"Quantia",
            "Action":"Ação"
}