export const MarkerIcon = require("./marker.svg");
export const Cellout = require("./locationpin.png");

export const Grooming= require("./Grooming.png")
export const pet= require("./pet.png")
export const Training= require("./Traning.png")
export const Hotel= require("./Hotel.png")
export const Daycare= require("./Daycare.png")
export const DogWalking= require("./DogWalking.png")
export const addServices = require("./add.png")
export const setting = require("./setting.png")
export const Calendar= require("./calendar-icon.svg");
export const Dashboard= require("./dashboard.png");
export const Gallery= require("./gallery.png")
export const avtarprofile= require("./avatar_.png");
export const editprofile= require("./editimage.png");
export const employees= require("./image_graph.png");
export const countryIcon= require("./countryicon.png");
export const cityIcon= require("./cityicon.png");
export const zipIcon= require("./zipicon.png");
export const profileavtar= require("./profileavtar.png");

export const headerCalendarIcon = require("./header-calendar-icon.svg");
export const headerFootprintIcon = require("./header-footprint-icon.svg");
export const headerSettingIcon = require("./header-setting-icon.svg");
export const headerLogoutSelectIcon = require("./header-logout-select-icon.svg");
export const headerWalletIcon = require("./header-wallet-icon.svg");

export const headerCalendarSelectIcon = require("./header-calendar-select-icon.svg");
export const headerFootprintSelectIcon = require("./header-footprint-select-icon.svg");
export const headerSettingSelectIcon = require("./header-setting-select-icon.svg");
export const headerLogoutIcon = require("./header-logout-icon.svg");
export const headerWalletSelectIcon = require("./header-wallet-select-icon.svg");
export const headerEstablishmentSelectIcon = require("./header-establishment-grey-icon.svg");
export const headerDocumentIcon = require("./header-document-grey-icon.svg");
export const headerManagementIcon = require("./header-management-grey-icon.svg");
export const group_switch = require("../assets/group_switch.png");
export const defaultCustomerUserProfile = require('./default-img.svg');


export const cookieIcon = require("./cookies.png");
export const CommonBlackClose = require("./black-close-icon.svg")