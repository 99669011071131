export const enEmployeeManagement = {
    //EMP LIST
    "Employees list":"Employees list",
    "Add New Employee":"Add New Employee",
    "Email id":"Email id",
    "Edit Profile":"Edit Profile",
    "Delete Profile":"Delete Profile",
    "The employee list is empty.":"The employee list is empty.",
    "Please click 'Add New Employee' to get started.":"Please click 'Add New Employee' to get started.",
    "Are you sure you want to permanently remove this employee?":"Are you sure you want to permanently remove this employee?",
    "Yes, I'm sure":"Yes, I'm sure",
    "No, don't Remove":"No, don't Remove",
    "Employee Management":"Employee Management",
    //ADD-EDIT EMP
        "Select Availability":"Select Availability",
        "Employee Calendar":"Employee Calendar",
        "Add Event":"Add Event",
        //FIELD
        "Employee Picture":"Employee Picture",
        "Employee Name":"Employee Name",
        "Select Role":"Select Role",
        "Email":"Email",
        "Phone":"Phone",
        "Select Service":"Select Service",
        "Select Sub Service":"Select Sub Service",
        //PLACEHOLDER
        "upload file (png,jpg)":"upload file (png,jpg)",
        "Name":"Name",
        "Role":"Role",
        "Email address":"Email address",
        "Service":"Service",
        "Sub Service":"Sub Service",
        "Your contact number":"Your contact number",
        //ERROR
        "File size should not exceed 5MB":"File size should not exceed 5MB",
        "Invalid file type. Please upload a PNG or JPG image":"Invalid file type. Please upload a PNG or JPG image",
        "Please enter valid Email":"Please enter valid Email",
        "Please enter Start time":"Please enter Start time",
        "Please enter End time":"Please enter End time",
        "Please enter Name":"Please enter Name",
        "Please select Sub Service":"Please select Sub Service",
        "Please enter valid Phone":"Please enter valid Phone",
    //ADD EVENT
        //FIELD
        "Event Name":"Event Name",
        "Start date":"Start date",
        "End date":"End date",
        //PLACEHOLDER
        "Choose a Date":"Choose a Date",
    //BUTTON
    "Cancel":"Cancel",
    "Next":"Next",
    "Okay":"Okay",
    "Back":"Back",
    "Add":"Add",
    "Edit":"Edit",
    "Save":"Save",
    //DAYS
    "Monday":"Monday",
    "Tuesday":"Tuesday",
    "Wednesday":"Wednesday",
    "Thursday":"Thursday",
    "Friday":"Friday",
    "Saturday":"Saturday",
    "Sunday":"Sunday"
}

export const ptEmployeeManagement = {
    //EMP LIST
    "Employees list":"Lista de funcionários",
    "Add New Employee":"Adicionar novo funcionário",
    "Email id":"ID de e-mail",
    "Edit Profile":"Editar perfil",
    "Delete Profile":"Excluir perfil",
    "The employee list is empty.":"A lista de funcionários está vazia.",
    "Please click 'Add New Employee' to get started.":"Clique em 'Adicionar novo funcionário' para começar.",
    "Are you sure you want to permanently remove this employee?":"Tem certeza de que deseja remover permanentemente este funcionário?",
    "Yes, I'm sure":"Sim, tenho certeza",
    "No, don't Remove":"Não, não remova",
    "Employee Management":"Gestão de Funcionários",
    //ADD-EDIT EMP
        "Select Availability":"Selecione Disponibilidade",
        "Employee Calendar":"Calendário de funcionários",
        "Add Event":"Adicionar Evento",
        //FIELD
        "Employee Picture":"Foto do funcionário",
        "Employee Name":"Nome do funcionário",
        "Select Role":"Selecione a função",
        "Email":"E-mail",
        "Phone":"Telefone",
        "Select Service":"Selecione o serviço",
        "Select Sub Service":"Selecione o subserviço",
        //PLACEHOLDER
        "upload file (png,jpg)":"enviar arquivo (png,jpg)",
        "Name":"Nome",
        "Role":"Papel",
        "Email address":"Endereço de email",
        "Service":"Serviço",
        "Sub Service":"Subserviço",
        "Your contact number":"Seu número de contato",
        //ERROR
        "File size should not exceed 5MB":"O tamanho do arquivo não deve exceder 5 MB",
        "Invalid file type. Please upload a PNG or JPG image":"Tipo de arquivo inválido. Faça upload de uma imagem PNG ou JPG",
        "Please enter valid Email":"Por favor insira um e-mail válido",
        "Please enter Start time":"Insira a hora de início",
        "Please enter End time":"Insira o horário de término",
        "Please enter Name":"Por favor insira o nome",
        "Please select Sub Service":"Selecione o subserviço",
        "Please enter valid Phone":"Por favor insira um telefone válido",
    //ADD EVENT
        //FIELD
        "Event Name":"Nome do Evento",
        "Start date":"Data de início",
        "End date":"Data de término",
        //PLACEHOLDER
        "Choose a Date":"Escolha uma data",
    //BUTTON
    "Cancel":"Cancelar",
    "Next":"Próxima",
    "Okay":"OK",
    "Back":"Voltar",
    "Add":"Adicionar",
    "Edit":"Editar",
    "Save":"Salvar",
    //DAYS
    "Monday":"Segunda-feira",
    "Tuesday":"Terça-feira",
    "Wednesday":"Quarta-feira",
    "Thursday":"Quinta-feira",
    "Friday":"Sexta-feira",
    "Saturday":"Sábado",
    "Sunday":"Domingo"
}