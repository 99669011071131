import React, { useEffect, useState } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities"; // Assuming setStorageData is available
export const english = require("../../../blocks/languagesupport/src/languages/en.json");
export const spanish = require("../../../blocks/languagesupport/src/languages/es.json");
export const french = require("../../../blocks/languagesupport/src/languages/fr.json");
export const portuguese = require("../../../blocks/languagesupport/src/languages/pt.json");

interface LanguageCtxtype {
  language: string;
  languageData: any;
  alternateLanguage: (value: string) => void;
}

export const LanguageCtx = React.createContext<LanguageCtxtype>({
  language: "en",
  languageData: english,
  alternateLanguage: () => {},
});

export const LanguageContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
 const initialState=localStorage.getItem("chosenLanguage") || "en";
  const [language, setLanguage] = useState<string>(initialState);
  const [languageData, setLanguageData] = useState(english);

  useEffect(() => {
    const fetchChosenLanguage =  () => {
      const storedLang =  localStorage.getItem("chosenLanguage");
      const lang = storedLang || "en";
      setLanguage(lang);
      changeLanguage(lang);
    };
    fetchChosenLanguage();
  }, []);

  const changeLanguage = (lang: string) => {
    let data;
    switch (lang) {
      case "en":
        data = english;
        break;
      case "es":
        data = spanish;
        break;
      case "fr":
        data = french;
        break;
      case "pt":
        data = portuguese;
        break;
      default:
        data = english;
    }
    setLanguageData(data);
    setLanguage(lang);
  };

  const alternateLanguage = (value: string) => {
    localStorage.setItem("chosenLanguage", value); 
    changeLanguage(value);
  };

  const value: LanguageCtxtype = {
    language,
    languageData,
    alternateLanguage,
  };

  return <LanguageCtx.Provider value={value}>{children}</LanguageCtx.Provider>;
};

export const useLanguageContext = () => React.useContext(LanguageCtx);
export type { LanguageCtxtype };
