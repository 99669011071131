export const chartBlue = require("../assets/chart_blue.svg");
export const chartGreen = require("../assets/chart_green.svg");
export const chartRed = require("../assets/chart_red.svg");
export const profitBlack = require("../assets/profit_black.svg");
export const profitGreen = require("../assets/profit_green.svg");
export const lossBlack = require("../assets/loss_black.svg");
export const lossRed = require("../assets/loss_red.svg");
export const userGroup = require("../assets/user_group.svg");
export const NoAppoinment = require("../assets/no_appoinment.svg");
export const downloadIcon = require("../assets/download_btn.svg");

// CUSTOMERS
export const CustomerAvatar = require("../assets/customer_avatar.svg");
export const CatAvatar = require("../assets/cat_dummy.png");
export const DogAvatar = require("../assets/dog_dummy.png");
export const female = require("../assets/female_icon.svg");
export const male = require("../assets/male_icon.svg");
export const BookingHistory = require("../assets/booking_history.svg");
export const PawBlue = require("../assets/paw_blue.svg");
export const CheckedSwitch = require("../assets/switch_checked.svg");
export const UnCheckedSwitch = require("../assets/switch_unchecked.svg");