import { Box, Button, Typography, styled } from '@material-ui/core';
import React from 'react';
import { Visa, Amex, Mastercard, DeleteIcon, add, DefaultCard } from "../assets";
import { defaultButton } from './FormWrapper';
import { IPaymentCard } from '../../../../components/src/interfaces.web';
import AddCardStripe from '../../../../blocks/scheduling/src/component/AddCardStripe.web';
import { transPt } from '../../../../components/src/HelperUtils';

const PaymentWrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    paddingTop: '40px',

    "& .card-wrapper": {
        marginTop: '18px'
    },

    "& .page-header": {
        fontSize: '24px',
        fontWeight: 600,
        color: '#6F05EA'
    },
    "& .add-method": {
        padding: '20px 30px',
        backgroundColor: '#F7F7F7',
        display: 'flex',
        gap: '24px',
        cursor: 'pointer',
        alignItems: 'center',
        [theme.breakpoints.up(600)]: {
            padding: '43px 60px',
            gap: '60px',
          },
    },
    "& .add-icon": {
        width: '43px',
        height: '43px'
    },
    "& .add-text": {
        color: '#B7B7B7',
        fontSize: '18px',
        fontWeight: 600,
        [theme.breakpoints.up(600)]: {
            fontSize: '20px',
          },
    },
    "& .card-text": {
        fontSize: '18px',
        fontWeight: 500,
        color: '#000'
    },
    "& .MuiTypography-root.MuiTypography-body1": {
        fontFamily: 'Montserrat',
    },
    "& .cards-list": {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginTop: '45px'
    },
    "& .card-box": {
        padding: '24px 36px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 7.5px 0px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        gap: '16px'
    },
    "& .top-content": {
        display: 'flex',
        gap: '34px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    "& .card-box.default": {
        background: '#E2CDFB'
    },
    "& .brand-wrapper": {
        padding: '10px', 
        background: '#fff',
        maxWidth: '60px',
        widht: '60px',
        minWidth: '60px',
        height: '41px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up(600)]: {
            maxWidth: '90px',
            widht: '90px',
            minWidth: '90px',
            height: '71px',
          },
    },
    "& .card-info": {
        flex: 1, 
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        color: '#424242'
    },
    "& .card-title": {
        fontSize: '14px',
        fontWeight: 600,
        [theme.breakpoints.up(600)]: {
            fontSize: '20px',
          },
    },
    "& .card-desc": {
        fontSize: '12px',
        fontWeight: 400,
        [theme.breakpoints.up(600)]: {
            fontSize: '16px',
          },
    },
    "& .card-default-text": {
        color: '#6F05EA',
        fontSize: '16px',
        fontWeight: 400,
        cursor: 'pointer',
        [theme.breakpoints.down(600)]: {
            display: 'none',
          },
    },
    "& .default-icon": {
        width: '26px',
        maxWidth: '26px',
        minWidth: '26px',
        height: 'auto',
        [theme.breakpoints.up(600)]: {
            width: '40px',
            maxWidth: '40px',
            minWidth: '40px',
          },
    },
    "& .delete-icon": {
        width: '18px',
        maxWidth: '18px',
        minWidth: '18px',
        height: 'auto',
        [theme.breakpoints.up(600)]: {
            width: '21px',
            maxWidth: '21px',
            minWidth: '21px',
          },
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        fontSize: '16px',
        lineHeight: '16px',
        [theme.breakpoints.up(600)]: {
            display: 'none'
          },
    }
}))

type PaymentProps = {
    cardList: IPaymentCard[];
    isAddCardModalOpen: boolean;
    openAddCardModal: () => void;
    addCardModalLoading: boolean;
    onCloseAddCardModal: () => void;
    onSubmitAddCardModal: (card_token: string) => void;
    handleSetAsDefault: (payment_method_id: string) => void;
    handleDeleteCard: (payment_method_id: string) => void;
    addCardModalError: string
    clearAddCardModalError: () => void;
    addCardModalDefault: boolean;
    changeAddCardModalDefault: (default_status: boolean) => void; 
}


const PaymentTab = (props: PaymentProps) => {
    const { cardList } = props;

    const handleBrandIcon = (brand: string) => {
        if(brand.toLowerCase() === 'visa') {
            return Visa;
        }
        if(brand.toLowerCase() === 'mastercard') {
            return Mastercard;
        }
        if(brand.toLowerCase() === 'amex') {
            return Amex;
        }
    }

    const getExpMonth = (month: number) => {
        if(Number(month) < 10) {
            return `0${month}`
        }
        return month
    }

    return (
        <PaymentWrapper data-test-id='payments-tab'>
            <Typography className='page-header'>{transPt("Card details","customerUserProfile")}</Typography>
            <Box className='card-wrapper'>
                <Typography className='card-text'>{transPt("Update your payment cards","customerUserProfile")}</Typography>
                <Box className='cards-list'>
                    {cardList?.map(card => {
                        const isDefault = card.is_default
                        return (
                            <Box className={isDefault ? 'card-box default' : 'card-box'}>
                                <Box className='top-content'>
                                    <Box className='brand-wrapper'>
                                        <img src={handleBrandIcon(card.brand)} style={{ width: '100%', height: 'auto'}}/>
                                    </Box>
                                    <Box className='card-info'>
                                        <Typography className='card-title'>{`${card.brand} ending ${card.last4}`}</Typography>
                                        <Typography className='card-desc'>{`Expiry ${getExpMonth(card.exp_month)}/${card.exp_year}`}</Typography>
                                        {!isDefault && <Typography data-test-id='set-default' className='card-default-text' onClick={() => props.handleSetAsDefault(card.id)}>{transPt("Set as default","customerUserProfile")}</Typography>}
                                    </Box>
                                    <Box data-test-id='delete-icon' onClick={() => !isDefault && props.handleDeleteCard(card.id)}>
                                        {isDefault ? <img src={DefaultCard} className='default-icon'/> : <img style={{cursor: 'pointer'}} src={DeleteIcon} className='delete-icon'/> }
                                    </Box>
                                </Box>
                                {!isDefault && <Button className='main-btn' data-test-id="main-btn-set" onClick={() => props.handleSetAsDefault(card.id)}>{transPt("Set as default","customerUserProfile")}</Button>}
                            </Box>
                        )
                    })}
                    <Box className='add-method' onClick={() => props.openAddCardModal()} data-test-id='add-icon'>
                        <img src={add} className='add-icon' />
                        <Typography className='add-text'>{transPt("Add new payment method","customerUserProfile")}</Typography>
                    </Box>
                </Box>
            </Box>
            {props.isAddCardModalOpen ?
                <AddCardStripe data-testid="add-card-modal"
                    navigation={undefined}
                    isOpen={props.isAddCardModalOpen}
                    onClose={props.onCloseAddCardModal}
                    onSubmit={props.onSubmitAddCardModal}
                    submitLoader={props.addCardModalLoading}
                    // DEFAULT CARD
                    setAsDefault={props.addCardModalDefault}
                    handleChangeDefault={props.changeAddCardModalDefault}
                    // ERROR
                    error={props.addCardModalError}
                    clearError={props.clearAddCardModalError}
                />
                : <></>}
        </PaymentWrapper>
    )
}

export default PaymentTab;