export const userProfile = require("../assets/user-profile.png");
// Customizable Area Start
export const headerCalendarIcon = require("../assets/header-calendar-icon.svg");
export const headerFootprintIcon = require("../assets/header-footprint-icon.svg");
export const headerSettingIcon = require("../assets/header-setting-icon.svg");
export const headerLogoutSelectIcon = require("../assets/header-logout-select-icon.svg");
export const headerWalletIcon = require("../assets/header-wallet-icon.svg");
export const CalendarIcon = require("../assets/calendar-icon.svg");
export const headerCalendarSelectIcon = require("../assets/header-calendar-select-icon.svg");
export const documentIcon= require("../assets/document-icon.svg");
export const establishmentIcon= require("../assets/establishment-icon.svg");
export const managementIcon= require("../assets/management-icon.svg");
export const managementIconselected= require("../assets/view_m.svg");

export const headerEstablishmentSelectIcon= require("../assets/header-establishment-grey-icon.svg");
export const headerEstablishmentSelectIconselected= require("../assets/image_establishment.svg");

export const headerLogo = require('../assets/header-logo.svg');
export const languageIcon = require('../assets/language-icon.svg');
export const headerFootprintSelectIcon = require("../assets/header-footprint-select-icon.svg");
export const headerSettingSelectIcon = require("../assets/header-setting-select-icon.svg");
export const headerLogoutIcon = require("../assets/header-logout-icon.svg");
export const headerWalletSelectIcon = require("../assets/header-wallet-select-icon.svg");
export const chatIcon=require('../../../blocks/customisableuserprofiles/assets/chat-icon.svg');
// Customizable Area End