import React from "react";
import { EventProps } from "react-big-calendar";
import { Box, Typography, styled } from "@material-ui/core";
import { FormattedAppointment } from "../../../blocks/customisableuserprofiles/src/types";

const WeekWrapper = styled("div")({
  height: "100%",
  border: "1px solid #6F05EA!important",
  backgroundColor: "#F6F0FF",
  color: "#000",
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  padding: "0 4px",
  overflow: "hidden",
  fontFamily: 'Montserrat',
  textAlign: 'center',

  "& .top-time": {
    fontSize: "10px", whiteSpace: "nowrap", fontWeight: 600
  },
  "& .event-content": {
    overflow: "hidden",
    alignSelf: "flex-start",
    width: "100%",
    fontSize: "11px",
  },
  "& .event-title": {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500
  }
});

const WeekEventVendor: React.FC<EventProps<FormattedAppointment>> = ({ event }) => {
  return (
    <WeekWrapper className="week-event-wrapper">
      <Box className="event-content">
        <Typography className="event-title">{`${event.pet.data.attributes.name}-${ !!event.sub_service.title ? event.sub_service.title : event.service.service_type}`}</Typography>
      </Box>
    </WeekWrapper>
  );
};

export default WeekEventVendor;
