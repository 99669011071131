import { Box } from "@material-ui/core";
import React from "react";
import CommonDatePicker from "./Calendar.web";
import { ResheduleRequestFields } from "../../blocks/customisableuserprofiles/src/types";
import { FormikErrors, FormikTouched } from "formik";
import moment from "moment";
import InputField from "./InputField";

const labelStyle = {
    fontSize: '12px',
    fontWeight: 400,
    color: "#6F05EA",
    fontFamily: "Montserrat",
    marginBottom: 0,
    lineHeight: "18px"
} as React.CSSProperties;

type IProps = {
    field: keyof ResheduleRequestFields,
    errors: FormikErrors<ResheduleRequestFields>,
    touched: FormikTouched<ResheduleRequestFields>,
    setFieldValue: (f: string, v: string) => void,
    getFieldProps: (field: string) => any,
    values: ResheduleRequestFields,
    isFullWidth?: boolean;
    isReccurence?: boolean;
    removeError?: () => void;
}

const DatePickerInput = ({ values, setFieldValue, getFieldProps, errors, touched, isFullWidth, isReccurence = true, removeError = () => undefined }: IProps) => {
  return (
    <Box style={{ position: "relative", ...(isFullWidth && { width: '100%'}) }}>
      <CommonDatePicker
        data-test-id="new-end-date"
        selectedDate={
          values.start_date
            ? moment(values.start_date, "DD-MM-YYYY").startOf("day").toDate()
            : null
        }
        minDate={new Date()}
        handleDateChange={(date: Date | null) => {
          setFieldValue("start_date", moment(date).format("DD/MM/YYYY"));
          removeError();
        }}
      />
      <InputField
        {...getFieldProps("start_date")}
        error={Boolean(errors["start_date"]) && touched["start_date"]}
        id="start_date"
        name="start_date"
        fullWidth={true}
        helperText={(touched["start_date"] && errors["start_date"])?.toString()}
        labelStyle={labelStyle}
        label={isReccurence ? "Updated Start Date" : "Updated Appointment Date"}
        placeholder="Select date"
        {...(isFullWidth && values["start_date"] && {inputProps: { style: { fontFamily: "Montserrat", fontSize: '18px', fontWeight: 600 }}})}
        {...(isFullWidth && { style: { width: '100%' }})}
        withoutStartAdornment 
      />
    </Box>
  );
};


export default DatePickerInput;