import React from "react";
// Customizable Area Start
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography, Button, Modal
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import pluralize from "pluralize";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import WebMapView from "../../../components/src/WebMapView";
import Filters from "./components/FilterSection";
import GeneralSelect from "../../../components/src/inputs/GeneralSelect";
import ListItem from "./components/ListItem";
import { noResults, MarkerSmall, crossButton, image_minus, image_plus } from "./assets";
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { SearchItem } from './AdvancedSearchController';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";
import { LanguageCtx } from "../../../components/src/context/LanguageContext";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType = LanguageCtx;
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { languageData } = this.context;
    const {
      from,
      night,
      book,
      noresultsFound,
      HightoLow,
      lowtohigh,
      viewMap,
      showing,
      result
    } = languageData?.translation?.advanceSearch || {};

    const renderMapViewModal = (item: SearchItem) => {
      return (
        <>
          {this.handleMapModal(item)}
          {this.state.enableModal &&
            <Modal
              open={this.state.open}
              onClose={() => this.handleClose('backdropClick')}
              aria-labelledby="modal-modal-title"
              data-test-id="handleModal"
              aria-describedby="modal-modal-description"
              style={{ backgroundColor: "transparent" }} // Keeps modal content area transparent
              BackdropProps={{
                style: {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)', // Thin, semi-transparent background (10% opacity)
                },
              }}
            >
              <PageWrapper2 maxWidth="xl" data-test-id="advanced-search-wrapper">
                <ContainerBox style={{ display: 'flex' }}>
                  <Column className="filterslist" >
                    {this.state.searchData &&
                      <Box
                        style={{
                          width: 306,
                          marginLeft: -16,
                          background: '#fff',
                          padding: 20,
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          boxShadow: '0px 4px 8px 0px #00000008',
                          borderRadius: '8px',
                          marginBottom: 70
                        }}
                      >
                        <Filters
                          data-test-id="filters"
                          type={this.state.searchTab}
                          values={this.state.filters}
                          onChange={this.changeFilterValue}
                          onSearch={this.applyFilters}
                        />
                      </Box>
                    }
                  </Column>
                  <Column className="list-block2" >
                    <Box style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: ' 14px',
                      marginLeft: -10,
                    }}>
                      {this.state.searchItems.map((item: SearchItem) => (
                        <ItemWrapper
                          data-test-id='listItem'
                          key={item.id} style={{
                            backgroundColor: this.checkBackgroundColor(item),
                            cursor: 'pointer'
                          }} onClick={() => this.handleItemClick(item)}>
                          <Box className='image-containerlist' style={{ maxWidth: '100%', borderBottomLeftRadius: 16, backgroundImage: `url(${item.search_gallery_url})` }} >
                          </Box>
                          <Box className='main-content'>
                            <Typography className='title'>{item.establishment_name}</Typography>
                            <Box className='content-line'>
                              <img src={MarkerSmall} />
                              <Typography className='address-style2'>{item.address}</Typography>
                            </Box>
                            <Box className='price-list'>
                              <Typography className='from-tag'>{from}</Typography><Typography className='main-price'>{item.service_av.price}€/{night}</Typography>
                            </Box>
                          </Box>
                          <Box className='price-block2'>
                            <Button data-test-id='bookscreen' onClick={() => this.handleSeeOffer(item)} className='offer-btn2'>{book}</Button>
                          </Box>
                        </ItemWrapper>
                      ))}
                    </Box>
                  </Column>
                  <Column className="map-wrapper2" >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      data-test-id='googleid'
                      center={{ lat: this.state.centerMarker.lat, lng: this.state.centerMarker.lng }}
                      zoom={this.state.zoomLevel}
                      onLoad={this.onMapLoad}
                      options={{
                        draggable: true, // Enable dragging
                        zoomControl: false, // Enable zoom controls
                        disableDefaultUI: true, // Disable default UI components
                        scrollwheel: true,
                        streetViewControl: true
                      }}
                      onDragEnd={() => this.onMapDragEnd()}  // Update the center after the map is dragged
                      onClick={() => this.handleCloseInfoWindow()} // Close InfoWindow on map click
                    >
                      {this.state.searchItems.map((marker) => (
                        <Marker
                          key={marker.id}
                          data-test-id='markerid'
                          position={{
                            lat: marker.latitude,
                            lng: marker.longitude,
                          }}
                          onClick={() => this.handleMarkerClick(marker)}
                          icon={this.checkmarkerIcon(marker)}
                        />
                      ))}
                      {this.state.selectedMarker && (
                        <InfoWindow
                          position={{
                            lat: this.state.selectedMarker.latitude,  // Use marker's latitude
                            lng: this.state.selectedMarker.longitude  // Use marker's longitude
                          }}
                          data-test-id='infowindowid'
                          onCloseClick={() => this.handleCloseInfoWindow}
                          options={{ disableAutoPan: true, pixelOffset: new window.google.maps.Size(0, -40), }}
                        >
                          <InfoWindowWrapper style={{ overflow: 'hidden' }}>
                            <Box className='image-container2' style={{ overflow: 'hidden', maxWidth: '100%', backgroundImage: `url(${this.state.selectedMarker?.search_gallery_url})` }} >
                            </Box>
                            <Box className='main-content'>
                              <Box >
                                <Typography className='title'>{this.state.selectedMarker.establishment_name}</Typography>
                                <Box className='content-line'>
                                  <img src={MarkerSmall} />
                                  <Typography className='address-style2'>{this.state.selectedMarker.address}</Typography>
                                </Box>
                                <Box className='price-list'>
                                  <Typography className='from-tag'>from {this.state.selectedMarker?.service_av?.price}€</Typography><Typography className='main-price'>/{night}</Typography>
                                </Box>
                              </Box>
                              <Box className='price-block2'>
                                <Button data-test-id='searchbtn' onClick={() => this.handleSeeOffer(this.state.selectedMarker)} className='offer-btn2'>{book}</Button>
                              </Box>
                            </Box>
                          </InfoWindowWrapper>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                    <Box className="custom-zoom-controls">
                      <Button data-test-id='zoomin' onClick={this.zoomIn} className="zoom-btn zoom-in" style={{
                        width: '50px',
                        height: '50px',
                        minWidth: '50px',
                        minHeight: '50px',
                        padding: 0
                      }}><Typography><img style={{ width: 24, height: 24 }} src={image_plus} /></Typography></Button>
                      <Button data-test-id='zoomout' onClick={this.zoomOut} className="zoom-btn zoom-out" style={{
                        width: '50px',
                        height: '50px',
                        minWidth: '50px',
                        minHeight: '50px',
                        padding: 0, // Remove any internal padding
                        backgroundColor: '#fff',
                        marginTop: 10
                      }} ><img style={{ width: 24, height: 24 }} src={image_minus} /></Button>
                    </Box>
                  </Column>
                  <Box onClick={() => { this.handleClose('') }} data-test-id="closemap" className="crossButton-box"><img style={{ width: 56, height: 56 }} src={crossButton} /></Box>
                </ContainerBox>
              </PageWrapper2>
            </Modal>
          }
        </>
      )
    }
    const isRecurrenceShow = [1, 2, 3, 4].includes(this.state.searchTab);
    const handleRenderContent = () => {
      if (this.state.isLoading) {
        return (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )
      } else if (this.state.searchItems && this.state.searchItems.length) {
        return (
          <>
            {this.state.searchItems?.map((item) => (
              <ListItem
                item={item}
                key={item.id}
                searchData={this.state.searchData}
                onSeeOffer={this.handleSeeOffer}
                MapButton={renderMapViewModal}
              />
            ))}
          </>
        )
      } else {
        return (
          <Box className="no-results-wrapper">
            <img src={noResults} />
            <Typography className="no-results-text">{noresultsFound}</Typography>
          </Box>
        )
      }
    }
    return (
      <>
        <AppHeader navigation={this.props.navigation} />
        <LandingPageHeader
          data-test-id="page-header"
          tabValue={this.state.searchTab}
          onTabChange={(_, value) =>
            this.setState({ searchTab: Number(value) })
          }
          SearchDataFromHeader={(data) => this.makeSearch(data)}
          updateSearchData={(data) => this.updateSearchData(data)}
          petList={this.state.petsList}
          hideLabel={true}
          isRepeatShow={isRecurrenceShow}
        />
        <PageWrapper maxWidth="xl" data-test-id="advanced-search-wrapper" style={{ paddingTop: isRecurrenceShow ? '140px' : '90px' }}>
          <Box className="sort-wrapper">
            <Typography className="result-text">
              {`${showing} ${pluralize(
                `${result}`,
                this.state.searchItems?.length || 0,
                true
              )}`}
            </Typography>
            <SelectWrapper elevation={3}>
              <GeneralSelect data-test-id='general-select' onChange={this.handleChangeSortOrder} value={this.state.sortOption} list={[
                { label: `${lowtohigh}`, value: "asc" },
                { label: `${HightoLow}`, value: "desc" },
              ]}
                paperProps={{ width: "193px" }}
              />
            </SelectWrapper>
          </Box>
          <Box className="content">
            <Box className="map-filter-block">
              <Box className="map-wrapper">
                <WebMapView
                  center={this.state.region}
                  searchItems={this.state.searchItems}
                />
                {this.state.searchItems?.length > 0 &&
                  <Button className="view-map-btn" data-test-id='handleopenbtn' onClick={this.handleOpen}>
                    <Typography style={{ fontSize: 16, fontWeight: 600, color: '#6F05EA', fontFamily: 'Montserrat', textTransform: 'capitalize' }}>{viewMap}</Typography>
                  </Button>
                }
                {this.state.openViewMap &&
                  renderMapViewModal(this.state.searchItems[0])
                }
              </Box>
              {this.state.searchData &&
                <Box className="filters" >
                  <Filters
                    data-test-id="filters"
                    type={this.state.searchTab}
                    values={this.state.filters}
                    onChange={this.changeFilterValue}
                    onSearch={this.applyFilters}
                  />
                </Box>
              }
            </Box>
            <Box className="list-block">
              {handleRenderContent()}
            </Box>
          </Box>
        </PageWrapper>
        <AppFooter />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const SelectWrapper = styled(Paper)({
  "& .MuiList-root.MuiMenu-list.MuiList-padding": {
    padding: 0,
    width: "195px",
  },
});
const LoaderWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const PageWrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "46px",
  paddingBottom: "50px",
  paddingTop: "90px",
  width: "calc(100% - 135px)",
  paddingLeft: 0,
  paddingRight: 0,

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 80px)",
  },
  [theme.breakpoints.down("xs")]: {
    width: "calc(100% - 40px)",
  },
  "& *": {
    boxSizing: "border-box",
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },
  "& .content": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",

    [theme.breakpoints.up(960)]: {
      flexDirection: "row",
      gap: '28px',
    },
    [theme.breakpoints.up(1450)]: {
      flexDirection: "row",
      gap: '2.875rem',
    },
  },
  "& .filters": {
    width: "100%",
    background: "#F7F7F7",
    display: "flex",
    padding: "32px",
    borderRadius: "8px",
  },
  "& .sort-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'column',
    alignItems: "flex-start",
    gap: '16px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
      alignItems: "center",
      marginTop: "20px",
    },

  },
  "& .result-text": {
    color: "#6F05EA",
    fontWeight: 700,
    fontSize: "16px",
  },
  "& .view-map-btn": {
    position: "absolute",
    top: "9%",
    right: -47,
    transform: "translate(-50%, -50%)",
    background: "#fff",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#6F05EA",
    fontSize: "16px",
    fontWeight: 600,
    cursor: "pointer",
    height: 40
  },

  "& .no-results-wrapper": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px'
  },

  "& .no-results-text": {
    color: '#B7B7B7',
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
    },
  },

  "& .map-filter-block": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    [theme.breakpoints.up(960)]: {
      width: "325px",
    },
  },

  "& .map-wrapper": {
    position: "relative",
  },

  "& .list-block": {
    width: "100%",
    display: "flex",
    gap: "2.5rem",
    flexDirection: "column",
  },
}));
//ViewMap
const ContainerBox = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  width: '98%',
  gap: '10px',

  [theme.breakpoints.down(1040)]: {
    flexDirection: 'column',
  },

});
const Column = styled("div")(({ theme }) => ({
  flex: "1 1 33%",
  padding: theme.spacing(2),
  boxSizing: "border-box",
  [theme.breakpoints.down(960)]: {
    flex: "1 1 100%",
  },
  // Adjust layout for smaller screens
  [theme.breakpoints.down(1440)]: {
    flex: "1 1 50%",
  },
  [theme.breakpoints.down(1040)]: {
    flex: "1 1 100%",
    marginBottom: '20px',
  },
}));
const PageWrapper2 = styled(Container)(({ theme }) => ({

  maxHeight: ' 90%',
  display: 'flex',
  width: '85%',
  background: '#F6F4F9',
  overflow: 'hidden',
  flexDirection: "column",
  top: '5%',
  left: '5%',
  marginTop: 54,
  borderRadius: 4,
  alignItems: 'flex-start',

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 532px)",
  },
  [theme.breakpoints.up(962)]: {
    width: "calc(100% - 300px)",
  },
  [theme.breakpoints.down("xs")]: {
    width: "calc(100% - 40px)",
  },
  "& *": {
    boxSizing: "border-box",
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },

  "& .filterslist": {
    flex: '5 1 14%',
    width: "100%",
    background: "#F6F4F9",
    maxWidth: '315px',
    display: "flex",
    flexDirection: "column",
    marginTop: 15,
    minHeight: '300px',
    padding: '20px',
    height: 800,
    maxHeight: 'auto',
    overflowY: 'scroll',  // Scrollable content
    scrollbarWidth: 'thin',

  },
  "& .crossButton-box": {
    position: 'absolute',
    width: '44px',
    height: '44px',
    zIndex: 1,
    marginTop: '34px',
    marginLeft: 563,
    marginRight: 169,
    right: 0,
    cursor: 'pointer',

    [theme.breakpoints.up(1900)]: {
      marginRight: 137,
    },
    [theme.breakpoints.down(1040)]: {
      visibility: 'collapse'
    },
    [theme.breakpoints.up(1240)]: {
      marginRight: 158,
      marginTop: -23
    },
    [theme.breakpoints.up(1040)]: {
      marginTop: -26,
      marginRight: 169
    },
  },
  "& .sort-wrapper": {
    marginTop: "20px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  "& .result-text": {
    color: "#6F05EA",
    fontWeight: 700,
    fontSize: "16px",
  },

  "& .map-wrapper2": {
    position: "relative",
    width: '100%',
    flex: '22 0 6%',
    height: '100vh',
    zIndex: 1,
  },
  "& .map-filter-block": {
    width: "103%",
    gap: "24px",
    flexDirection: "row",

  },


  "& .content": {
    width: '103%',
    height: '100%',
    background: '#F6F4F9',
    alignItems: 'flex-start',
    flexDirection: "column",
    overflow: 'scroll',
    borderRadius: 4,
  },


  "& .Modal-container": {
    background: '#F6F4F9',
    width: '80%'
  },
  "& .list-block2": {
    display: "flex",
    gap: "20px",
    marginTop: 15,
    maxWidth: '305px',
    flexDirection: "column",
    paddingBottom: 116,
    flex: '14 0 1%',
    overflowY: 'auto',
    top: 54,
    right: 0,
    left: 0,
    bottom: 30,
    height: '90vh', width: "100%",
    // position: 'absolute',
    zIndex: 8,
    maxHeight: "85%",
    // marginLeft: '31%'
  },
  "& .custom-zoom-controls": {
    position: 'absolute',
    bottom: '108px',
    right: '-7px',
    display: 'flex',
    flexDirection: 'column'
  },
  "& .zoom-btn": {
    width: '56px',
    height: '56px',
    background: '#E1CCFF',
    borderRadius: '8px',
    fontSize: '20px',
    color: '#3730A3',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
    boxShadow: ' 0 2px 6px rgba(0, 0, 0, 0.3)'
  },
  '& :focus': {
    outline: 'none'
  }
}));
const ItemWrapper = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
  borderTopLeftRadius: 16,
  overflow: 'hidden',
  display: 'flex',
  borderBottomLeftRadius: 16,
  flexDirection: 'column',
  width: '305px',
  height: 'auto',
  "& .from-tag": {
    color: '#B7B7B7',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat',
    fontWeight: 500,

  },

  "& .main-content": {
    padding: '20px',
    gap: '14px',
    display: 'flex',
    flexDirection: 'column',

  },
  "& .image-containerlist": {
    width: '100%',
    height: '191px',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

  },
  "& .image": {
    position: 'absolute',
    width: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    height: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  "& .title": {
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontSize: '18px',

  },
  "& .content-line": {
    display: 'flex',
    gap: '12px',
    fontSize: '12px',
    color: '#000',
    alignItems: 'flex-start',
    fontWeight: 300,
    fontFamily: 'Montserrat'

  },

  "& .address-style2": {
    fontSize: '12px',
    fontWeight: 300,
    color: '#000',
    fontFamily: 'Montserrat'
  },



  "& .fees-text": {
    marginTop: '8px',
    marginBottom: '20px',
    color: '#777777',
    fontSize: '12px',
    fontWeight: 500
  },
  "& .warning-text": {
    color: '#FFCE00',
    fontSize: '14px',
    fontWeight: 600
  },

  "& .link": {
    color: '#909090',
    fontSize: '12px',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  "& .price-block2": {
    marginTop: -10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '30px',

    alignItems: 'center',

  },
  "& .offer-btn2": {
    color: '#fff',
    textTransform: 'none',
    backround: '#6F05EA',
    padding: '10px 36px',
    borderRadius: '35px',
    fontSize: '18px',
    backgroundColor: '#6F05EA',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    letterSpacing: 0,
    border: '1px solid #6F05EA',
    whiteSpace: 'nowrap',
    lineHeight: '14px',
    height: ' 53px',
    width: '188px',
  },
  "& .price-section": {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(1600)]: {
      alignItems: 'end',
    },
  },
  "& .price-text": {
    color: '#777777',
    fontWeight: 500,
    marginBottom: '21px',
    fontSize: '14px',

  },
  "& .price-list": {
    display: 'flex',
    alignItems: 'center',
  },
  "& .no-tax-price": {
    color: '#777777',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  "& .main-price": {
    color: '#6F05EA',
    fontSize: '16px',
    marginLeft: '10px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat'
  }

}))
const InfoWindowWrapper = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
  // overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '270px',
  borderRadius: 25,
  height: '306px',
  "& .image-container2": {
    width: '100%',
    height: '144px',
    backgroundSize: 'cover',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  "& .from-tag": {
    color: '#6F05EA',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat',
    fontWeight: 800,
    marginLeft: 5

  },
  "& .main-content": {
    flexDirection: 'column',
    display: 'flex',
    height: 'auto',
    marginLeft: 10,
    marginTop: 10
  },

  "& .address-style2": {
    fontSize: '12px',
    fontWeight: 300,
    color: '#000',
    fontFamily: 'Montserrat'
  },
  "& .content-line": {
    display: 'flex',
    gap: '12px',
    fontSize: '12px',
    color: '#000',
    alignItems: 'flex-start',
    fontWeight: 300,
    fontFamily: 'Montserrat',
    marginTop: 5

  },
  "& .title": {
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontSize: '18px',
    marginLeft: 3

  },

  "& .fees-text": {
    marginTop: '8px',
    marginBottom: '20px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#777777',

  },

  "& .warning-text": {
    color: '#FFCE00',
    fontSize: '14px',
    fontWeight: 600
  },

  "& .price-block2": {
    marginTop: 9,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '30px',
    display: 'flex',
    alignItems: 'center',

  },
  "& .link": {
    color: '#909090',
    fontSize: '12px',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  "& .offer-btn2": {
    color: '#fff',
    textTransform: 'none',
    backround: '#6F05EA',
    padding: '10px 36px',
    borderRadius: '35px',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    letterSpacing: 0,
    border: '1px solid #6F05EA',
    backgroundColor: '#6F05EA',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    width: '160px',
    height: '32px'
  },
  "& .price-section": {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(1600)]: {
      alignItems: 'end',
    },
  },
  "& .price-text": {
    color: '#777777',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '21px',
  },
  "& .no-tax-price": {
    color: '#777777',
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  "& .price-list": {
    display: 'flex',
    alignItems: 'center',
  },

  "& .main-price": {
    color: '#6F05EA',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat',
    fontWeight: 500,
  }

}))
const containerStyle = {
  width: '110%',
  height: '1083px',
  marginTop: -20,

};
// Customizable Area End
