import React from 'react';
import { Box, Button, CircularProgress, Typography, styled } from '@material-ui/core';
import GenericModal from '../../../../components/src/GenericModal';
import { defaultButton } from './FormWrapper';
import { ILoggedSession } from '../CustomisableUserProfilesController';
import { transPt } from '../../../../components/src/HelperUtils';


type IProps = {
    isOpen: boolean;
    title: string;
    device: ILoggedSession | null;
    onClose: () => void;
    onSubmit: (dId: string) => void;
}

const ContentWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '14px',

    [theme.breakpoints.up('md')]: {
        padding: '30px',
    },
    "& .text": {
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontWeight: 700,
        color: '#000'
    },
    "& .text-purple": {
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontWeight: 700,
        color: '#6F05EA'
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        width: '100%',
        fontWeight: 600,
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #DC2626",
        color: '#DC2626',
        width: '100%',
        fontWeight: 600,
    },
    "& .box-wrapper": {
        marginTop: '25px',
        display: "flex",
        gap: "14px",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          gap: "26px",
          marginTop: '55px',
        },
    },
}))

const EndSessionModal = (props: IProps) => {
    const { isOpen, title, onClose, device, onSubmit } = props;

    return (
        <GenericModal
            id='end-session' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose}
            bodyStyle={{ padding: '26px 60px', width: 'auto' }} 
            headerStyle={{ color: '#6F05EA', fontSize: '24px' }}
            withoutCloseBtn
        >
            {device ? <ContentWrapper>
                <Typography className='text'>
                    {transPt("Are You Sure you want to End Session with","customerUserProfile")}
                </Typography>
                <Typography className='text-purple'>
                    {device.device_name} ?
                </Typography>
                <Box className="box-wrapper">
                    <Button className="secondary-btn" onClick={() => onClose()}>{transPt("Cancel","customerUserProfile")}</Button> 
                    <Button className="main-btn" onClick={() => onSubmit(device.device_id)}>{transPt("End session","customerUserProfile")}</Button>
                </Box>
            </ContentWrapper> : <CircularProgress />
            }
        </GenericModal>
    )
}


export default EndSessionModal