import React from "react";
// Customizable Area Start
import CustomersDashboardController, { Props } from "./CustomersDashboardController.web";
import {
    Dialog, styled, Box, IconButton, Button, Typography, InputAdornment,
    TextField,
    Avatar
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { CustomerAvatar, downloadIcon } from "../assets";

interface ICustomer {
    photo: string,
    name: string,
    phone_number: string,
    email: string,
    address: string
}
const customerList: ICustomer[] = [
    {
        photo: CustomerAvatar,
        name: "Eleanor Pena",
        phone_number: "(316) 555-0116",
        email: "sara.cruz@example.com",
        address: "3517 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        photo: CustomerAvatar,
        name: "Tanya Hill",
        phone_number: "(316) 555-0116",
        email: "tanya.hill@example.com",
        address: "4566 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        photo: CustomerAvatar,
        name: "Felicia Reid ASD dasdad",
        phone_number: "(316) 555-0116",
        email: "felicia.reiddasdadas@example.com",
        address: "123 W. Gray St. Utica, Pennsylvania 57867 123 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        photo: CustomerAvatar,
        name: "Eleanor Pena",
        phone_number: "(316) 555-0116",
        email: "sara.cruz@example.com",
        address: "3517 W. Gray St. Utica, Pennsylvania 57867"
    },
]
// Customizable Area End

export default class CustomersDashboard extends CustomersDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    searchField = () => {
        return (
            <SearchBox>
                <SearchField fullWidth
                    placeholder="Search"
                    value={this.state.searchValue}
                    onChange={(event) => this.handleChangeSearch(event.target.value)}
                    data-testid="search-field"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </SearchBox>
        )
    }
    getDayLine = (day: string) => {
        return (
            <DayLine>{day}</DayLine>
        )
    }

    renderCustomerList = () => {
        return (
            <>
                <ListWrapper>
                    {this.getDayLine("Yesterday")}
                    {this.customerRows()}
                    {this.getDayLine("Last week")}
                    {this.customerRows()}
                </ListWrapper>
            </>
        )
    }
    customerRows = () => {
        return (
            <RowWrapper>
                {customerList.map((record) => {
                    return (
                        <CustomerBox>
                            <StyledAvatar src={record.photo} />
                            <CenterBox maxWidth={90} width={90}>
                                <RowFont noWrap title={record.name}>{record.name}</RowFont>
                            </CenterBox>
                            <CenterBox maxWidth={85} width={85}>
                                <RowFont noWrap title={record.phone_number}>{record.phone_number}</RowFont>
                            </CenterBox>
                            <CenterBox maxWidth={160} width={160}>
                                <RowFont noWrap title={record.email}>{record.email}</RowFont>
                            </CenterBox>
                            <CenterBox maxWidth={140} width={140}>
                                <RowFont title={record.address} className="custom-address">{record.address}</RowFont>
                            </CenterBox>
                        </CustomerBox>
                    )
                })}
            </RowWrapper>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <StyledDialog
                    open
                    fullWidth
                    maxWidth={"lg"}
                    onClose={this.props.handleClose}
                >
                    <MainWrapper>
                        <CloseButtonBox>
                            <CloseIconButton onClick={this.props.handleClose}>
                                <Close />
                            </CloseIconButton>
                        </CloseButtonBox>

                        <TitleBox>
                            <TitleFont>Customers</TitleFont>
                            <DownloadButton startIcon={<img src={downloadIcon} />} >
                                Download in CSV
                            </DownloadButton>
                        </TitleBox>
                        {this.searchField()}
                        {this.renderCustomerList()}
                    </MainWrapper>
                </StyledDialog>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        maxWidth: "905px",
        borderRadius: "8px"
    },
})
const MainWrapper = styled(Box)({
    padding: "24px"
})
const CloseButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "end",
    alignItems: "end"
})
const CloseIconButton = styled(IconButton)({
    padding: 0,
    "& .MuiSvgIcon-root": {
        color: "#090909"
    }
})
const TitleBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 26px 0",
    padding: "0 48px"
})
const TitleFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "40px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
})
const DownloadButton = styled(Button)({
    height: 40,
    width: "100%",
    maxWidth: "164px",
    borderRadius: "24px",
    border: '0.5px solid #6F05EA',
    textTransform: "none",
    fontWeight: 500,
    fontSize: "12px",
    fontFamily: "Montserrat",
    lineHeight: "15px",
    color: "#6F05EA",
    boxShadow: "0px 4px 8px 0px #00000008",
    "& .MuiButton-startIcon": {
        margin: "0 6px 0 0"
    }
})

const SearchBox = styled(Box)({
    padding: "0 48px"
})
const SearchField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        height: 44,
        borderColor: "#CBD5E1",
        paddingLeft: "8",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
    },
    "& .MuiInputAdornment-root": {
        margin: "0"
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px 8px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Montserrat",
        "&::placeholder": {
            color: "#94A3B8",
            opacity: 1
        },
    }
})
const SearchIcon = styled(Search)({
    color: "#94A3B8"
})
const DayLine = styled(Box)({
    margin: "26px 0",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "8px",
    fontFamily: "Montserrat",
    color: "#6F05EA"
})

const ListWrapper = styled(Box)({
    padding: "0 48px",
    maxHeight: "400px",
    overflowY: "auto"
})
const CustomerBox = styled(Box)({
    borderRadius: "4px",
    boxShadow: "0px 2px 8px 0px #0000000F",
    backgroundColor: "#FFFFFF",
    padding: "4px 8px",
    display: "flex",
    flexDirection: "row",
    gap: 20
})

const StyledAvatar = styled(Avatar)({
    height: "32px",
    width: "32px",
    border: "1px solid #6F05EA"
})

const RowWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: 10,
})
const CenterBox = styled(Box)({
    display: "flex",
})
const RowFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
    "&.custom-address": { // Target only when the class is `custom-address`
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
})
// Customizable Area End
