import { enGeneralInfo, ptGeneralInfo } from "./generalInfo"
import { enSidebar, ptSidebar } from "./sidebarService"
import { enVendorProfile, ptVendorProfile } from "./vendorProfile"

export const enEstablishmentService = {
    sidebar:enSidebar,
    vendorProfile:enVendorProfile,
    generalInfo:enGeneralInfo
}

export const ptEstablishmentService = {
    sidebar:ptSidebar,
    vendorProfile:ptVendorProfile,
    generalInfo:ptGeneralInfo
}