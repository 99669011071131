import { Box, Button, Typography, styled } from '@material-ui/core';
import GenericModal from "../../../../components/src/GenericModal";
import { defaultButton } from './FormWrapper';
import React from 'react';
import useBreakpoints from '../../../../components/src/hooks/useBreakpoints';
import { transPt } from '../../../../components/src/HelperUtils';

interface IProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    onSubmit: () => void;
}

const ContentWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '14px',

    [theme.breakpoints.up('md')]: {
        padding: '30px',
    },

    "& .text": {
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'center',
        [theme.breakpoints.up(600)]: {
            fontSize: '24px',
        },
    },
    "& .red-text": {
        color: '#DC2626',
        fontWeight: 600
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        width: '100%',
        [theme.breakpoints.down(600)]: {
            fontSize: '16px',
            lineHeight: '16px',
        },
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #DC2626",
        color: '#DC2626',
        width: '100%',
        [theme.breakpoints.down(600)]: {
            fontSize: '16px',
            lineHeight: '16px',
        },
    },
    "& .box-wrapper": {
        marginTop: '35px',
        display: "flex",
        gap: "14px",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          gap: "26px",
        },
    },
}))

const DeleteModal = (props: IProps) => {
    const { isOpen, title = "", onClose, onSubmit } = props;
    const { less600 } = useBreakpoints();


    return (
        <GenericModal
            id='email-reset' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose}
            bodyStyle={{ padding: less600 ? '16px 25px' : '30px 50px' }} 
            withoutCloseBtn
        >
            <ContentWrapper>
                <Typography className='text'>
                    {transPt("End sessionIf you remove your account, we will cancel your appointments and NOT refund you.","customerUserProfile")}
                    {" "}  
                    <span className='red-text'>
                    {transPt("All your data will be deleted and you will be logged out.","customerUserProfile")} 
                    {transPt("Are you sure you want to proceed?","customerUserProfile")}
                    </span>
                </Typography>
                <Box className="box-wrapper">
                    <Button className="secondary-btn" onClick={() => onClose()}>{transPt("Cancel","customerUserProfile")}</Button> 
                    <Button className="main-btn" onClick={() => onSubmit()}>{transPt("Delete account","customerUserProfile")}</Button>
                </Box>
            </ContentWrapper>
        </GenericModal>
    )
}

export default DeleteModal;