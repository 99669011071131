import { Box } from '@material-ui/core';
import React from 'react'
import { GenericFormikProps } from '../../types';
import Field from '../Field';
import moment from 'moment';
import CommonDatePicker from '../../../../../components/src/Calendar.web';
import InputField from "../../../../../components/src/InputField";
import { labelStyle } from '../ChangeRequestModal';
import { checkCondition, transPt } from '../../../../../components/src/HelperUtils';


const EndDateChange = ({ 
        event, errors, touched, getFieldProps, setFieldValue, values, removeError, isAll, isReccurence 
    }: GenericFormikProps) => {
    const isSelectedAll = isAll && isReccurence;
    const oldTimeValue = isSelectedAll && event.search_end_date ? moment(event.search_end_date) : moment(event.end_date)
    const inputError = checkCondition((touched["start_date"] && errors["start_date"])?.toString() === undefined, "", (touched["start_date"] && errors["start_date"])?.toString())
    return (
        <Box className="form-row">
            <Box style={{ width: '100%', borderBottom: '1px solid #D9D9D9' }}>
            <Field
                title={transPt("Old End Date", "customerUserProfile")}
                withOpacity
                withPadding
                value={oldTimeValue.format("DD/MM/YYYY")}
            />
            </Box>
            <Box style={{ position: "relative", width: '100%' }}>
            <CommonDatePicker
                data-test-id="new-end-date"
                selectedDate={
                values.end_date && moment(values.end_date).isValid()
                    ? moment(values.end_date, "DD-MM-YYYY").toDate()
                    : null
                }
                minDate={
                    values.start_date && moment(values.start_date, 'DD/MM/YYYY').isValid() 
                    ? moment(values.start_date, 'DD/MM/YYYY').startOf('day').add(1, 'days').toDate() 
                    : new Date()
                }
                handleDateChange={(date: Date | null) => {
                    setFieldValue(
                        "end_date",
                        moment(date).format("DD/MM/YYYY")
                    );
                    removeError && removeError()
                }}
            />
            <InputField
                {...getFieldProps("end_date")}
                error={
                Boolean(errors["end_date"]) && touched["end_date"]
                }
                id="end_date"
                name="end_date"
                fullWidth={true}
                {...(values["end_date"] && {inputProps: { style: { fontFamily: "Montserrat", fontSize: '18px', fontWeight: 600 }}})}
                helperText={transPt(`${inputError}`,"customerUserProfile")}
                labelStyle={labelStyle}
                label={transPt("Updated End Date", "customerUserProfile")}
                placeholder={transPt("Select date", "customerUserProfile")}
                withoutStartAdornment
            />
        </Box>
      </Box>
    )
}

export default EndDateChange;