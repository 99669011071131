export const enSidebar = {
    "Dog Walking":"Dog Walking",
    "Hotels":"Hotels",
    "Grooming":"Grooming",
    "Day Care":"Day Care",
    "Training":"Training",
    "Pet Sitting":"Pet Sitting",
    "Add Service":"Add Service",
    "Switch Establishment":"Switch Establishment",
    "Establishment Settings":"Establishment Settings",
    //ADD SERVICE MODAL
    "Select Service Type":"Select Service Type",
    //TABS
    "Calendar":"Calendar",
    "Gallery":"Gallery",
    "Dashboard":"Dashboard",
    "General Information":"General Information",
    //BUTTON
    "Cancel":"Cancel",
    "Next":"Next"
}

export const ptSidebar = {
    "Dog Walking":"Passear com cachorro",
    "Hotels":"Hotéis",
    "Grooming":"Asseio",
    "Day Care":"Creche",
    "Training":"Treinamento",
    "Pet Sitting":"Pet Sitting",
    "Add Service":"Adicionar serviço",
    "Switch Establishment":"Estabelecimento de troca",
    "Establishment Settings":"Configurações do estabelecimento",
    //ADD SERVICE MODAL
    "Select Service Type":"Selecione o tipo de serviço",
    //TABS
    "Calendar":"Calendário",
    "Gallery":"Galeria",
    "Dashboard":"Painel",
    "General Information":"Informações gerais",
    //BUTTON
    "Cancel":"Cancelar",
    "Next":"Próximo"
}