import React from "react";
// Customizable Area Start
import EmployeeManagementController, { Props } from "./EmployeeManagementController.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box, Button, Dialog, IconButton, Menu, MenuItem, styled, Typography, Avatar, CircularProgress
} from "@material-ui/core";
import {
  EmployeeDeleteIcon, EmployeeEditIcon, ListActionIcon, NewEmployeeIcon,
} from "./assets";
import { returnTruthyString } from "../../../components/src/HelperUtils";
import { configJSON } from "./AccountGroupsController";
import { LoadingButton } from "../../../components/src/HelperComponents";
import AddEmployee from "./components/AddEmployee.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6F05EA",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

// Customizable Area End

export default class EmployeeManagement extends EmployeeManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderEmployees = () => {
    const { employeeListLoading, employeeList } = this.state
    return (
      <>
        {employeeListLoading && !employeeList.length ? this.renderLoader() : <></>}
        {!employeeListLoading && employeeList.length ? this.renderEmployeeList() : <></>}
        {!employeeListLoading && !employeeList.length ? this.renderDefaultList() : <></>}
      </>
    )
  }
  renderLoader = () => {
    return (
      <LoaderWrapper><CustomLoader /></LoaderWrapper>
    )
  }

  renderEmployeeList = () => {
    const { employeeList } = this.state
    return (
      <>
        <EmployeeListWrapper>
          {employeeList.map((record) => {
            const employee_id = returnTruthyString(record.id)
            const employee_name = returnTruthyString(record.attributes.name)
            const employee_email = returnTruthyString(record.attributes.email)
            const employee_service = returnTruthyString(record.attributes?.service?.name)
            const employee_photo = returnTruthyString(record.attributes?.employee_image)
            return (
              <EmployeeBox key={record.id}>
                <EmployeeInfo onClick={() => this.goToViewEmployee(employee_id)}>
                  <EmployeeAvatar src={employee_photo}></EmployeeAvatar>
                  <ColumnBox>
                    <EmployeeNameFont>{employee_name}</EmployeeNameFont>
                    <EmployeeEmailFont>{this.transEmpList("Email id")}: {employee_email}</EmployeeEmailFont>
                    <ServiceBox>{employee_service}</ServiceBox>
                  </ColumnBox>
                </EmployeeInfo>
                <EmployeeActionBox>
                  <EmployeeActionBtn
                    onClick={(event) => this.handleOpenActionMenu(event, employee_id)}
                    data-testid="action-menu-btn"
                  >
                    <img src={ListActionIcon} alt="action" />
                  </EmployeeActionBtn>
                  <StyledMenu
                    id="simple-menu"
                    open={this.state.employeeActionId === employee_id && Boolean( this.state.anchorElementAction)}
                    anchorEl={this.state.anchorElementAction}
                    keepMounted
                    onClose={this.handleCloseActionMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    data-testid="employee-action-menu"
                  >
                    <StyledMenuItem
                      onClick={() => this.goToEditEmployee(employee_id)}
                      data-testid="edit-action-menu"
                    >
                      <ActionIconImg src={EmployeeEditIcon} alt="edit" />
                      {this.transEmpList("Edit Profile")}
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={this.handleOpenDeleteModal}
                      data-testid="delete-action-menu"
                    >
                      <ActionIconImg src={EmployeeDeleteIcon} alt="delete" />
                      {this.transEmpList("Delete Profile")}
                    </StyledMenuItem>
                  </StyledMenu>
                </EmployeeActionBox>
              </EmployeeBox>
            )
          })}
        </EmployeeListWrapper>
      </>
    );
  };
  renderDefaultList = () => {
    return (
      <NoEmployeeWrapper>
        <AddEmployeeImg src={NewEmployeeIcon} alt="new-employee-icon" />
        <Box>
          <AddEmployeeFont>
            {this.transEmpList(configJSON.DEFAULT_MSG.EMPLOYEE_LIST_EMPTY)}
          </AddEmployeeFont>
          <AddEmployeeFont style={{ marginTop: 8 }}>
            {this.transEmpList(configJSON.DEFAULT_MSG.PLEASE_CLICK_ADD_EMPLOYEE)}
          </AddEmployeeFont>
        </Box>
      </NoEmployeeWrapper>
    );
  };

  renderDeleteModal = () => {
    return (
      <DeleteDialogContainer
        fullWidth
        maxWidth="md"
        open={this.state.openDeleteEmployeeModal}
        onClose={this.handleCloseDeleteModal}
        aria-labelledby="max-width-dialog-title"
        data-testid="delete-dialog"
      >
        <DeleteContentWrapper>
          <FontBox>
            <DeleteModalTitleFont>
              {this.transEmpList(configJSON.DEFAULT_MSG.DELETE_MODAL_TITLE)}
            </DeleteModalTitleFont>
          </FontBox>
          <DeleteButtonWrapper>
            <ConfirmDeleteBtn
              isLoading={this.state.deleteEmployeeLoading}
              onClick={this.handleConfirmDelete}
              buttonText={this.transEmpList(configJSON.DEFAULT_MSG.DELETE_MODAL_YES)}
              circularProgressStyle={style.btnLoaderStyle}
              data-testid="confirm-btn"
            >
              {this.transEmpList(configJSON.DEFAULT_MSG.DELETE_MODAL_YES)}
            </ConfirmDeleteBtn>
            <CancelDeleteBtn
              onClick={this.handleCloseDeleteModal}
              data-testid="cancel-btn"
            >
              {this.transEmpList(configJSON.DEFAULT_MSG.DELETE_MODAL_NO)}
            </CancelDeleteBtn>
          </DeleteButtonWrapper>
        </DeleteContentWrapper>
      </DeleteDialogContainer>
    );
  };

  renderAddEmployeeModal = () => {
    return (
      <>
        {this.state.openAddEmployeeModal ?
          <AddEmployee
            id="add-employee"
            navigation={this.props.navigation}
            handleCloseAddEmployee={this.handleCloseAddEmployee}
            getEmployees={this.getEmployees}
          /> : <></>
        }
      </>
    )
  }


  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <TitleBtnWrapper>
            <EmployeeListTitle>{this.transEmpList(configJSON.DEFAULT_MSG.EMPLOYEE_TITLE)}</EmployeeListTitle>
            <AddNewEmployeeBtn onClick={this.handleOpenAddEmployee}>{this.transEmpList(configJSON.DEFAULT_MSG.ADD_EMPLOYEE_BTN)}</AddNewEmployeeBtn>
          </TitleBtnWrapper>
          {this.renderEmployees()}
          {this.state.openDeleteEmployeeModal && this.renderDeleteModal()}
          {this.renderAddEmployeeModal()}
        </MainWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainWrapper = styled(Box)({
  backgroundColor: "#ffffff",
  borderRadius: "16px",
  padding: "58px 30px",
});

const TitleBtnWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "50px",
  "@media (max-width: 600px)": {
    alignItems: "center",
    gap: "12px",
  },
})
const EmployeeListTitle = styled(Typography)({
  fontSize: "20px",
  fontWeight: 700,
  "@media (max-width: 600px)": {
    fontSize: "18px",
  },
  "@media (max-width: 450px)": {
    fontSize: "16px",
  },
})
const AddNewEmployeeBtn = styled(Button)({
  minWidth: "253px",
  fontSize: "18px",
  fontWeight: 700,
  color: "#6F05EA",
  textTransform: "none",
  borderRadius: "100px",
  border: "1px solid #6F05EA",
  "@media (max-width: 600px)": {
    fontSize: "16px",
    maxWidth: "253px",
    padding: "5px 12px",
    width: "unset",
    minWidth:"unset"
  },
  "@media (max-width: 450px)": {
    fontSize: "14px",
    padding: "5px 8px",
  },
})

const NoEmployeeWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "23px",
  padding: "58px 0px",
})
const AddEmployeeImg = styled(`img`)({
  height: "68px",
  width: "68px",
})
const AddEmployeeFont = styled(Typography)({
  color: "#B7B7B7",
  fontSize: 20,
  fontWeight: 600,
  textAlign: "center",
})

const LoaderWrapper = styled(Box)({
  padding: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})

const CustomLoader = styled(CircularProgress)({
  height: "32px",
  width: "32px",
  color: "#6F05EA"
})

const EmployeeListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px"
})
const EmployeeBox = styled(Box)({
  padding: "32px",
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 600px)": {
    padding: "24px",
  },
})
const EmployeeInfo = styled(Box)({
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "32px",
  "@media (max-width: 600px)": {
    fontSize: "26px",
  },
  "@media (max-width: 450px)": {
    gap: "20px",
  },
})

const EmployeeAvatar = styled(Avatar)({
  width: "100px",
  height: "100px",
  borderRadius: "136px",
  "@media (max-width: 600px)": {
    width: "70px",
    height: "70px",
  },
  "@media (max-width: 450px)": {
    width: "50px",
    height: "50px",
  },
})

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column"
})
const EmployeeNameFont = styled(Typography)({
  fontSize: "24px",
  fontWeight: 600,
  color: "#0F172A",
  lineHeight: "30px",
  "@media (max-width: 600px)": {
    fontSize: "20px",
  },
  "@media (max-width: 450px)": {
    fontSize: "16px",
  },
})
const EmployeeEmailFont = styled(Typography)({
  marginTop: "12px",
  color: "#B7B7B7",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20px",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
  "@media (max-width: 450px)": {
    fontSize: "12px",
  },
})

const ServiceBox = styled(Box)({
  marginTop: "16px",
  width: "fit-content",
  padding: "4px 12px",
  backgroundColor: "#E2CDFB",
  color: "#6F05EA",
  fontSize: "12px",
  fontWeight: 400,
  borderRadius: 40,
  textAlign: "center",
  lineHeight: "14px",
})

const EmployeeActionBox = styled(Box)({
  "& .MuiIconButton-root": {
    padding: "0px",
  },
})
const EmployeeActionBtn = styled(IconButton)({
  width: "32px",
  height: "32px"
})
const ActionIconImg = styled(`img`)({
  height: "16px",
  width: "16px",
  marginRight: "13px",
})
const StyledMenuItem = styled(MenuItem)({
  fontSize: "12px !important",
  fontWeight: 400,
  height: 44,
  cursor: "pointer",
  color: "#0F172A"
})
const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    width: "147px !important",
    marginTop: "45px",
    borderRadius: "8px",
  },
  "& .MuiList-root": {
    padding: "0px",
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#F3EBFF",
    color: "#6F05EA",
    "& img": {
      filter:
        "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)",
    },
  },
  "& .MuiListItem-button": {
    padding: "0 12px"
  },
});

const DeleteDialogContainer = styled(Dialog)({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "850px",
  },
});

const DeleteContentWrapper = styled(Box)({
  padding: "48px 24px",
  "@media (max-width: 600px)": {
    padding: "36px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
})

const FontBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  margin: "24px",
  paddingBottom: "44px"
})

const DeleteModalTitleFont = styled(Typography)({
  maxWidth: "586px",
  fontSize: "23px",
  fontWeight: 700,
  textAlign: "center",
  "@media (max-width: 600px)": {
    fontSize: "20px",
  },
})

const DeleteButtonWrapper = styled(Box)({
  marginTop: "44px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  "@media (max-width: 960px)": {
    gap: "20px",
  },
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
})

const ConfirmDeleteBtn = styled(LoadingButton)({
  maxWidth: "265px",
  width: "100%",
  height: "48px",
  color: "#B7B7B7",
  border: "2px solid #B7B7B7",
  fontSize: "24px",
  fontWeight: 600,
  borderRadius: "40px",
  textTransform: "none",
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26) !important"
  },
  "@media (max-width: 1600px)": {
    fontSize: "20px",
  },
  "@media (max-width: 600px)": {
    fontSize: "18px",
  },
  "@media (max-width: 400px)": {
    fontSize: "16px",
  },
})

const CancelDeleteBtn = styled(Button)({
  height: "48px",
  maxWidth: "274px",
  width: "100%",
  color: "#6F05EA",
  border: "2px solid #6F05EA",
  fontSize: "24px",
  fontWeight: 600,
  borderRadius: "40px",
  textTransform: "none",
  "@media (max-width: 1600px)": {
    fontSize: "20px",
  },
  "@media (max-width: 600px)": {
    fontSize: "18px",
  },
  "@media (max-width: 400px)": {
    fontSize: "16px",
  },
})

const style = {
  btnLoaderStyle: {
    color: "#B7B7B7",
    height: "24px",
    width: "24px",
  } as React.CSSProperties
}
// Customizable Area End
