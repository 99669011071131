import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import GenericModal from "../../../../components/src/GenericModal";
import { checkedRadio, unCheckedRadio } from '../assets';
import {
  getValidationSchema,
} from "../../../../components/src/validators/validatiors";
import { ICustomSelect } from "../CustomisableUserProfiles.web";
import { Shedule } from "../CustomisableUserProfilesController";
import { ResheduleRequestFields } from "../types";
import { defaultButton } from "./FormWrapper";
import HotelContent from "./ChangeRequestModals/HotelContent";
import DogWalkingContent from "./ChangeRequestModals/DogWalkingContent";
import TrainingContent from "./ChangeRequestModals/TrainingContent";
import PetSittingContent from "./ChangeRequestModals/PetSittingContent";
import DayCareContent from "./ChangeRequestModals/DayCareContent";
import GroomingContent from "./ChangeRequestModals/GroomingContent";
import { transPt } from "../../../../components/src/HelperUtils";

interface IProps {
  isOpen: boolean;
  event: Shedule | null;
  title?: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: ResheduleRequestFields) => void;
  renderCustomField: (props: ICustomSelect) => JSX.Element;
  apiError: string;
  removeError: () => void;
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  "& .error-box": {
    padding: '16px', borderLeft: '4px solid #DC2626', backgroundColor: '#FEE2E2', borderRadius: '4px', marginTop: '16px'
  },

  "& .form-wrapper": {
    display: "flex",
    gap: "32px",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
  },

  "& .form-row": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "100%",
    alignItems: 'flex-start',
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },

  },

  "& .fields-wrapper": {
    display: "flex",
    gap: "32px",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .field-set": {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    width: "100%",
  },
  "& .secondary-btn": {
    ...defaultButton,
    border: "2px solid #6200EA",
    padding: "10px 20px",
    color: "#6200EA",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "265px",
    },
  },
  "& .main-btn": {
    ...defaultButton,
    border: "2px solid #4B4B4B",
    color: "#4B4B4B",
    whiteSpace: "nowrap",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "265px",
    },
  },
  "& .box-wrapper": {
    marginTop: "35px",
    display: "flex",
    gap: "14px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: "26px",
    },
  },
  "& .header-section": {
    dsiplay: "flex",
    flexDirection: "column",
    gap: "7px",
    marginBottom: "24px",
    [theme.breakpoints.up("lg")]: {
      marginBottom: "40px",
    },
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingBottom: 0,
    height: "28px",
  },
  "& .header-text": {
    fontFamily: "Montserrat",
    fontSize: "20px",
    color: "#000",
    fontWeight: 500,
  },
  "& .header-title": {
    fontFamily: "Montserrat",
    fontSize: "23px",
    color: "#6F05EA",
    fontWeight: 700,
  },

  '& #select-placeholder': {
    fontFamily: "Montserrat",
    fontSize: "16px !important",
    marginTop: "-5px !important",
  },
  '& #label-shift, #label-start_time':{
    fontSize: "12px !important",
    fontWeight: 400,
    color: "#6F05EA !important",
    fontFamily: "Montserrat !important",
    marginBottom: "3px !important",
    lineHeight: "22px !important",
  }
}));

export const labelStyle = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#6F05EA",
  fontFamily: "Montserrat",
  marginBottom: 0,
  lineHeight: "18px"
} as React.CSSProperties;

type ServiceType =
  | "Grooming"
  | "Dog Walking"
  | "Hotels"
  | "Training"
  | "Day Care"
  | "Pet Sitting";

const RadioWrapper = styled(Box)({
  margin: "29px 0 0 0"
})

const StyledRadioControl = styled(FormControlLabel)({
  margin: "0px 0px 20 0px"
})

const GroupWrapper = styled(RadioGroup)(({ theme }) => ({
  flexDirection: 'row',
  gap: '14px',
  [theme.breakpoints.up('md')]: {
      gap: '80px',
  },
}))

const LabelBox = styled(Box)({
  marginLeft: "2px",
  fontSize: "16px",
  lineHeight: "20px",
  fontFamily: 'Montserrat',
  fontWeight: 500,
  color: "#0F172A",
})

const ChangeRequestModal = (props: IProps) => {
  const {
    isOpen,
    title = "",
    event,
    apiError,
    onClose,
    onSubmit,
    isLoading,
    renderCustomField,
    removeError,
  } = props;
  const [radioValue, setRadioValue] = useState('one');

  /* istanbul ignore next */
  useEffect(() => {
    removeError()
  }, [radioValue])

  if (!event) {
    return null;
  }

  const isReccurence = event.repeat_weekly || false;

  const initialValues: ResheduleRequestFields = {
    start_date: "",
    end_date: "",
    shift: "",
    request_type: "Reschedule Request",
    recurrence: event?.week_days?.length || 1,
    daysSelected: radioValue === 'all' ? event?.week_days : [],
    repeatEvery: `${event.repeat_every}` || "1",
    serviceType: event.service.service_type,
    start_time: ""
  };

  const renderButtonContent = () => {
    if (isLoading) {
      return <CircularProgress style={{ color: "#6200EA" }} size="24px" />;
    } else {
      return transPt("Request", "customerUserProfile");
    }
  };

  return (
    <GenericModal
      id="cancel-request"
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      bodyStyle={{ padding: "30px 50px" }}
      withoutCloseBtn
    >
      <ContentWrapper>
        <Formik
          data-test-id='request-modal-form'
          initialValues={initialValues}
          enableReinitialize
          validationSchema={
            getValidationSchema(event.service.service_type as ServiceType, isReccurence && radioValue === 'all')
          }
          onSubmit={(values) => {
            onSubmit(values);
          }}
        > 
          {({
            values,
            errors,
            touched,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            handleBlur,
          }) => {
            const genericProps = { 
              event, 
              values, 
              errors, 
              touched, 
              setFieldValue, 
              getFieldProps, 
              handleBlur, 
              isReccurence,
              renderCustomField, 
              isAll: radioValue === 'all',
              removeError
            }
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Box className="header-section">
                  <Typography className="header-title">
                    {transPt("Request a change", "customerUserProfile")}
                  </Typography>
                  <Typography className="header-text">
                    {transPt("We will wait for vendor approval for the change request.", "customerUserProfile")}
                  </Typography>
                </Box>
                {isReccurence && <RadioWrapper>
                    <GroupWrapper aria-label="position" name="position" defaultValue="one">
                        <StyledRadioControl
                            value='one'
                            onChange={(event) => setRadioValue('one')}
                            checked={radioValue === 'one'}
                            control={
                              <Radio color="primary"
                                icon={<img src={unCheckedRadio} />}
                                checkedIcon={<img src={checkedRadio} />}
                              />
                            }
                            data-test-id="one-radio"
                            label={
                                <LabelBox style={{ opacity: 1 }}>
                                {transPt("Only this appointment", "customerUserProfile")}
                                </LabelBox>
                            }
                            labelPlacement="end"
                        />
                        <StyledRadioControl
                            value='one'
                            onChange={(event) => setRadioValue('all')}
                            checked={radioValue === 'all'}
                            control={
                                <Radio color="primary"
                                    checkedIcon={<img src={checkedRadio} />}
                                    icon={<img src={unCheckedRadio} />} />
                            }
                            label={
                                <LabelBox style={{ opacity: 1 }}>
                                {transPt("All appointment", "customerUserProfile")}
                                </LabelBox>
                            }
                            data-test-id="all-radio"
                            labelPlacement="end"
                        />
                    </GroupWrapper>
                </RadioWrapper>
                }
                <HotelContent {...genericProps} />
                <DogWalkingContent {...genericProps} />
                <TrainingContent {...genericProps} />
                <PetSittingContent {...genericProps} />
                <DayCareContent {...genericProps} />
                <GroomingContent { ...genericProps} />
                {apiError && 
                  <Box className="error-box" data-test-id='error-alert'>
                    <Typography style={{ fontFamily: 'Montserrat', fontSize: '14px', color: '#DC2626' }}>{apiError}</Typography>
                  </Box>
                }
                <Box className="box-wrapper">
                  <Button onClick={() => onClose()} className="main-btn">
                    {transPt("Cancel", "customerUserProfile")}
                  </Button>
                  <Button
                    className="secondary-btn"
                    disabled={isLoading}
                    type="submit"
                  >
                    {renderButtonContent()}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </ContentWrapper>
    </GenericModal>
  );
};

export default ChangeRequestModal;
