import { Box, FormHelperText, styled, Typography } from '@material-ui/core';
import { days } from '../../../../../components/src/helpers/convertToFullDay';
import React, { useEffect, useState } from 'react';
import { GenericFormikProps } from '../../types';
import { transPt } from '../../../../../components/src/HelperUtils';


const StyledLabel = styled(Typography)({
    color: '#6F05EA',
    fontSize: '12px',
    fontWeight: 400,
})

const ButtonWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
})

const DayElement = styled('div')(({ theme }) => ({
    width: '44px',
    height: '49px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 400,
    border: '0.5px solid rgba(119, 119, 119, 0.4667)',
    borderRadius: '6px',
    color: '#0F172A',
    [theme.breakpoints.up("md")]: {
        fontSize: '14px',
      },
}))

type Props = Omit<GenericFormikProps, 'values' | 'touched' | 'getFieldProps' | 'isAll' | 'isReccurence' | 'handleBlur' | 'renderCustomField'> & {
    isDisabled: boolean;
    label: string;
    isYellow?: boolean; 
    isDisplay?: boolean;
  };

const DaySelect = (props: Props) => {
    const { event, isDisabled, setFieldValue, label, errors, isYellow, isDisplay } = props;
    const [selectedDays, setSelectedDays] = useState<string[]>(event.week_days || []);

    useEffect(() => {
        setFieldValue('daysSelected', selectedDays);
    }, [selectedDays])

    const getItemStyle = (fullDay: string) => {
        const styles = { background: "#808080", color: '#fff', cursor: 'default' }
        if(isYellow) {
            styles.background = '#000'
        }
        if(isDisplay) {
            styles.background = '#6F05EA'
        }
        if(!isDisabled) {
            styles.cursor = 'pointer'
        }
        if(selectedDays.includes(fullDay)) {
            return styles
        }

        return { cursor: styles.cursor }
    }

    const handleChange = (day: string) => {
        if(!isDisabled) {
            setSelectedDays((prevSelectedDays) => {
                if (prevSelectedDays.includes(day)) {
                  return prevSelectedDays.filter((item) => item !== day);
                } else {
                  return [...prevSelectedDays, day];
                }
            });
        }
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <StyledLabel {...(isDisabled && { style: { opacity: 0.6 }})} {...(isYellow && { style: { color: '#D4AD2D'}})}>{label}</StyledLabel>
            <ButtonWrapper>
                {Object.entries(days).map((([abbreviation, fullDay]) => {
                    return (
                        <DayElement style={getItemStyle(fullDay)} onClick={() => handleChange(fullDay)} data-testid={`day-element-${fullDay}`}>
                            {transPt(`${abbreviation}`, "customerUserProfile")}
                        </DayElement>
                    )
                }))}
            </ButtonWrapper>
            {!isDisabled && errors["daysSelected"] && <FormHelperText style={{ color: '#f44336'}}>{errors["daysSelected"]}</FormHelperText>}
        </Box>
        
    )
}


export default DaySelect;