import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { availableResources } from "./resource";
import { getStorageData } from "../../../framework/src/Utilities";

const initI18n = async () => {
  const language = await getStorageData('chosenLanguage') || 'en';

  i18n
    .use(initReactI18next)
    .init({
      lng: language,
      resources: availableResources,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });

};

initI18n();
export default i18n;

export const i18nUpdate = async () => {
  const currentLang = await getStorageData('chosenLanguage');
  if (currentLang) {
    await i18n.changeLanguage(currentLang);
  }
}

export const ensureI18nInitialized = async (callback: () => void) => {
  if (i18n.isInitialized) {
    callback();
  } else {
    i18n.on('initialized', () => {
      callback();
    });
  }
};