import React from "react";
// Customizable Area Start
import AddEmployeeController, { Props } from "./AddEmployeeController.web";
import {
  Box, Button, Checkbox, Dialog, FormLabel, Grid, InputAdornment, MenuItem, Select,
  SelectProps, styled, TextField, Typography, Input, Avatar, FormControlLabel, FormHelperText
} from "@material-ui/core";
import {
  CheckedCheckBox, checkedSM, CommunityIcon, EmailIcon, UnCheckedCheckBox, unCheckedSM,
  uploadImage, UserIcon,
} from "../assets";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import PhoneInput from "react-phone-input-2";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { checkCondition, disableEndTime, generateTimeRange, returnTruthyString } from "../../../../components/src/HelperUtils";
import { LoadingButton, SimpleDialog } from "../../../../components/src/HelperComponents";

const selectMenuProps: Partial<SelectProps["MenuProps"]> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Adornment = (imgSrc: string) => (
  <InputAdornment position="start">
    <img src={imgSrc} />
  </InputAdornment>
);

const CustomSelectInputProp = <Input style={{ marginTop: 0 }} startAdornment={Adornment(CommunityIcon)} />

const CustomSelectMenuProp = {
  ...selectMenuProps,
  PaperProps: {
    style: {
      maxHeight: 229
    }
  }
}

// Customizable Area End

export default class AddEmployee extends AddEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  photoField = () => {
    const { employeeForm, formErrors } = this.state
    return (
      <ImageWrapper>
        <CustomFormLabel>{this.transAddEmp("Employee Picture")}</CustomFormLabel>
        <UploadWrapper>
          {!employeeForm.photo.url ?
            <UploadButton>
              <UploadBox>
                <Box>
                  <UploadIcon />
                  <UploadText>{this.transAddEmp("upload file (png,jpg)")}</UploadText>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={this.handleChangePhoto}
                    style={style.defaultImgInput}
                    data-testid="upload-default-img"
                    title=""
                  />
                </Box>
              </UploadBox>

            </UploadButton>
            :
            <PhotoBox>
              <ProfileAvatar src={employeeForm.photo.url} alt="img" />
              <UploadImg src={uploadImage} id="upload-img-id" />
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={this.handleChangePhoto}
                data-testid="upload-img-edit"
                style={style.photoImgInput}
                title=""
              />
            </PhotoBox>
          }
        </UploadWrapper>
        <FormHelperText error>{this.transAddEmp(formErrors.photo)}</FormHelperText>
      </ImageWrapper>
    )
  }
  nameField = () => {
    const { employeeForm, formErrors } = this.state
    return (
      <GridItem item md={6} sm={12} xs={12}>
        <CustomFormLabel>{this.transAddEmp("Employee Name")}</CustomFormLabel>
        <CustomField
          className="custom-field"
          type="text"
          placeholder={this.transAddEmp("Name")}
          value={employeeForm.name}
          onChange={(event) => this.handleChangeName(event.target.value)}
          InputProps={{ startAdornment: Adornment(UserIcon) }}
          data-testid="name-field"
        />
        <FormHelperText error>{this.transAddEmp(formErrors.name)}</FormHelperText>
      </GridItem>
    )
  }
  roleField = () => {
    const { employeeForm, userRoleList, formErrors } = this.state
    const placeholder = this.transAddEmp("Role")
    return (
      <GridItem item md={6} sm={12} xs={12}>
        <CustomFormLabel>{this.transAddEmp("Select Role")}</CustomFormLabel>
        <CustomField
          className="custom-field"
          select
          SelectProps={{
            MenuProps: selectMenuProps,
            IconComponent: CustomMenuIcon,
            displayEmpty: true,
            classes: {
              root: "custom-select-root",
            },
            renderValue(value) {
              const selectedRole = userRoleList.find((role) => String(role.id) == (value as string))?.name
              if (selectedRole) {
                return <ValueFont className="capital">{returnTruthyString(selectedRole)}</ValueFont>;
              }
              return <PlaceHolderFont>{placeholder}</PlaceHolderFont> // PLACEHOLDER CASE
            },
          }}
          value={employeeForm.roleId}
          onChange={(event) => this.handleChangeRole(event.target.value)}
          InputProps={{ startAdornment: Adornment(CommunityIcon) }}
          data-testid="role-field"
        >
          <MenuFont value={""} disabled>{this.transAddEmp("Role")}</MenuFont>
          {userRoleList.map((role) => {
            return (
              <MenuFont className="capital" value={role.id.toString()}>{role.name}</MenuFont>
            )
          })}
        </CustomField>
        <FormHelperText error>{this.transAddEmp(formErrors.roleId)}</FormHelperText>
      </GridItem>
    )
  }
  emailField = () => {
    const { employeeForm, formErrors } = this.state

    return (
      <GridItem item md={6} sm={12} xs={12}>
        <CustomFormLabel>{this.transAddEmp("Email")}</CustomFormLabel>
        <CustomField
          className="custom-field"
          type="email"
          placeholder={this.transAddEmp("Email address")}
          value={employeeForm.email}
          onChange={(event) => this.handleChangeEmail(event.target.value)}
          InputProps={{ startAdornment: Adornment(EmailIcon) }}
          data-testid="email-field"
        />
        <FormHelperText error>{this.transAddEmp(formErrors.email)}</FormHelperText>
      </GridItem>
    )
  }
  phoneField = () => {
    const { employeeForm, formErrors } = this.state
    return (
      <GridItem item md={6} sm={12} xs={12}>
        <CustomFormLabel>{this.transAddEmp("Phone")}</CustomFormLabel>
        <PhoneInput
          buttonStyle={style.phoneButtonStyle}
          inputStyle={style.phoneInputStyle}
          dropdownStyle={style.phoneDropDownStyle}
          placeholder={this.transAddEmp("Your contact number")}
          country={"pt"}
          value={employeeForm.phone}
          onChange={(value) => this.handleChangePhone(value)}
          data-testid="phone-field"
        />
        <FormHelperText error>{this.transAddEmp(formErrors.phone)}</FormHelperText>
      </GridItem>
    )
  }
  serviceField = () => {
    const { employeeForm, serviceList, formErrors } = this.state
    const placeHolder = this.transAddEmp("Service")
    return (
      <GridItem item md={6} sm={12} xs={12}>
        <CustomFormLabel>{this.transAddEmp("Select Service")}</CustomFormLabel>
        <CustomField
          select
          className="custom-field"
          InputProps={{
            startAdornment: Adornment(CommunityIcon),
          }}
          SelectProps={{
            MenuProps: selectMenuProps,
            IconComponent: CustomMenuIcon,
            displayEmpty: true,
            classes: {
              root: "custom-select-root",
            },
            renderValue(value) {
              const selectedService = serviceList.find((service) => String(service.service_id) == (value as string))?.service_name
              if (selectedService) {
                return <ValueFont>{returnTruthyString(selectedService)}</ValueFont>;
              }
              return <PlaceHolderFont>{placeHolder}</PlaceHolderFont> // PLACEHOLDER CASE
            },
          }}
          value={employeeForm.serviceId}
          onChange={(event) => this.handleChangeService(event.target.value)}
          data-testid="service-field"
        >
          <MenuFont value={""} disabled>{this.transAddEmp("Service")}</MenuFont>
          {serviceList.map((service) => {
            return (
              <MenuFont value={service.service_id.toString()}>{service.service_name}</MenuFont>
            )
          })}
        </CustomField>
        <FormHelperText error>{this.transAddEmp(formErrors.serviceId)}</FormHelperText>
      </GridItem>
    )
  }
  subServiceField = () => {
    const { employeeForm, subServiceList, formErrors } = this.state

    const renderLabels = (selectedIds: string[]) => {
      if (selectedIds.length === 0) {
        return <PlaceHolderFont>{this.transAddEmp("Sub Service")}</PlaceHolderFont>; // PLACEHOLDER CASE
      }
      return (
        <ValueFont>
          {selectedIds.map((eachId) => {
            const subService = subServiceList.find((service) => String(service.sub_service_id) == String(eachId));
            const label = checkCondition(Boolean(subService), subService?.sub_service_name, "") as string
            return label
          }).join(', ')}
        </ValueFont>
      )
    }
    return (
      <GridItem item md={6} sm={12} xs={12}>
        <CustomFormLabel>{this.transAddEmp("Select Sub Service")}</CustomFormLabel>
        <MultiSelect
          multiple
          displayEmpty
          name="sub_service_ids"
          classes={{
            root: "multi-select-root"
          }}
          value={employeeForm.subServiceIds}
          onChange={(event) => { this.handleChangeSubService(event) }}
          IconComponent={CustomMenuIcon}
          input={CustomSelectInputProp}
          MenuProps={CustomSelectMenuProp}
          renderValue={(selected) => { return renderLabels(selected as string[]) }}
          data-testid="sub-service-field"
        >
          <MenuFont disabled>{this.transAddEmp("Sub Service")}</MenuFont>
          {subServiceList.map((subService, index) => (
            <MenuFont key={index} value={subService.sub_service_id.toString()} disableRipple>
              <CustomCheckbox
                checked={employeeForm.subServiceIds.indexOf(subService.sub_service_id.toString()) > -1}
                checkedIcon={<img src={checkedSM} />}
                icon={<img src={unCheckedSM} />}
              />
              {subService.sub_service_name}
            </MenuFont>
          ))}
        </MultiSelect>
        <FormHelperText error>{this.transAddEmp(formErrors.subServiceIds)}</FormHelperText>
      </GridItem>
    )
  }

  renderForm1 = () => {
    return (
      <FormWrapper onSubmit={this.handleForm1Submit} data-testid="form-1">
        {this.photoField()}
        <ContainerWrapper>
          <Grid container spacing={5}>
            {this.nameField()}
            {this.roleField()}
            {this.emailField()}
            {this.phoneField()}
            {this.serviceField()}
            {this.showSubService() ? this.subServiceField() : <></>}
          </Grid>
        </ContainerWrapper>
        <ButtonWrapperMain>
          <Button
            className="addModalBtn grayBtn"
            onClick={this.handleClose}
            data-testid="cancel-btn"
          >
            {this.transAddEmp("Cancel")}
          </Button>
          <Button
            type="submit"
            className="addModalBtn purpleBtn"
            data-testid="next-btn"
            disabled={this.isFormHasError()}
          >
            {this.transAddEmp("Next")}
          </Button>
        </ButtonWrapperMain>
      </FormWrapper>
    );
  };

  rednerForm2 = () => {
    const menuRange = generateTimeRange("00:00", "23:30", 30);
    const { employeeForm, formErrors } = this.state;
    return (
      <FormWrapper data-testid="form-2">
        <AvailabilityWrapper>
          {employeeForm.availability.map((dayItem, index) => {
            const start_time = returnTruthyString(dayItem.start_time)
            const end_time = returnTruthyString(dayItem.end_time)
            return (
              <>
                <DayBoxWrapper key={index}>
                  <DayBox>
                    <DayLabel
                      label={this.transAddEmp(dayItem.day)}
                      checked={dayItem.checked}
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checkedIcon={<img src={CheckedCheckBox} />}
                          icon={<img src={UnCheckedCheckBox} />}
                          onChange={(event) => this.handleChangeAvailability(event.target.checked, dayItem.day)}
                        />
                      }
                      data-testid={`day-checkbox-${index}`}
                    />
                  </DayBox>

                  {dayItem.checked ? (
                    <>
                      <Box>
                        <TimeBox>
                          <TimeIcon />
                          <StyledSelect
                            id="start-range-day"
                            displayEmpty
                            variant="outlined"
                            IconComponent={DownArrowIcon}
                            className="edit-select"
                            data-testid={`day-start-time-${index}`}
                            MenuProps={CustomSelectMenuProp}
                            value={start_time}
                            onChange={(event) => this.handleChangeTimes(event.target.value as string, dayItem.day, "start_time")}
                          >
                            <MenuFont value={""} disabled>
                              00:00
                            </MenuFont>
                            {menuRange.map((each, index) => {
                              return (
                                <MenuFont value={each.value} key={index}>
                                  {each.name}
                                </MenuFont>
                              );
                            })}
                          </StyledSelect>
                          <StyledSelect
                            id="end-range-day"
                            variant="outlined"
                            displayEmpty
                            IconComponent={DownArrowIcon}
                            className="edit-select"
                            data-testid={`day-end-time-${index}`}
                            MenuProps={CustomSelectMenuProp}
                            value={end_time}
                            onChange={(event) => this.handleChangeTimes(event.target.value as string, dayItem.day, "end_time")}
                          >
                            <MenuFont value={""} disabled>
                              00:00
                            </MenuFont>
                            {menuRange.map((each, index) => {
                              return (
                                <MenuFont value={each.value} key={index} disabled={disableEndTime(index, start_time)}>
                                  {each.name}
                                </MenuFont>
                              );
                            })}
                          </StyledSelect>
                        </TimeBox>
                        <FormHelperText error>{this.transAddEmp(formErrors.availability[dayItem.day])}</FormHelperText>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </DayBoxWrapper>
              </>
            );
          })}
        </AvailabilityWrapper>
        <ButtonWrapperMain>
          <Button
            className="addModalBtn grayBtn"
            onClick={this.handleAddBackClick}
            data-testid="back-btn"
          >
            {this.transAddEmp("Back")}
          </Button>
          <LoadingButton
            buttonText={this.transAddEmp("Add")}
            className="addModalBtn purpleBtn"
            isLoading={this.state.addEmployeeLoading}
            data-testid="add-btn"
            onClick={this.addEmployee}
          />
        </ButtonWrapperMain>
      </FormWrapper>
    );
  };

  renderAddEmployeeModal = () => {
    const { formNumber } = this.state;
    const modalTitle = checkCondition(formNumber === 1, "Add New Employee", "Select Availability") as string
    return (
      <AddDialogContainer
        fullWidth
        maxWidth="md"
        open={true}
        aria-labelledby="max-width-dialog-title"
        data-testid="add-dialog"
      >
        <DialogContentWrapper>
          <ModalTitle>{this.transAddEmp(modalTitle)}</ModalTitle>
          <Box>
            {formNumber === 1 && this.renderForm1()}
            {formNumber === 2 && this.rednerForm2()}
          </Box>
        </DialogContentWrapper>
      </AddDialogContainer>
    );
  };

  renderMsgModal = () => {
    const { modalOpen, modalMsg } = this.state;
    return (
      <>
        {modalOpen &&
          <SimpleDialog
            open={modalOpen}
            data-testid="msg-modal"
            showRightBtn
            message={modalMsg}
            handleRightClick={this.closeMsgModal}
            rightBtnTitle={this.transAddEmp("Okay")}
          />
        }
      </>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.renderAddEmployeeModal()}
        {this.renderMsgModal()}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const AddDialogContainer = styled(Dialog)({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "883px",
    borderRadius: "16px",
    maxHeight: "96vh",
    "& .imageContainer": {
      alignItems: "center",
      display: "flex",
      marginBottom: "14px",
    },
    scrollbarWidth: "none",
  },
});
const DialogContentWrapper = styled(Box)({
  padding: "61px 36px",
  "@media (max-width: 1400px)": {
    padding: "50px 32px",
  },
  "@media (max-width: 600px)": {
    padding: "32px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  }
})

const ModalTitle = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "27px",
  color: "#6F05EA",
  "@media (max-width: 600px)": {
    fontSize: "24px",
  }
});
const CustomFormLabel = styled(FormLabel)({
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "#6F05EA",
  fontFamily: "Montserrat",
  marginBottom: "4px",
});

const FormWrapper = styled(`form`)({
  margin: 0,
  padding: "32px 24px 0 24px"
});
const ContainerWrapper = styled(Box)({
  padding: "48px 0"
})
const GridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const ImageWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
});
const UploadWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
});
const UploadButton = styled(Button)({
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  width: "84px",
  height: "84px",
  textTransform: "none",
});
const UploadIcon = styled(AddCircleOutline)({
  color: "#6F05EA",
  height: 24,
  width: 24,
});
const UploadText = styled(Typography)({
  marginTop: "8px",
  fontFamily: "Montserrat",
  fontSize: "10px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
});
const UploadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: "#A6A6A6",
});
const CustomField = styled(TextField)({
  "& .custom-select-root": {
    height: 22,
    backgroundColor: "transparent",
    paddingTop: 8,
    paddingBottom: 4
  },
  "&.custom-field input": {
    height: 22,
    paddingTop: 6,
    paddingBottom: 6,
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: 500,
    color: "#1E293B",
    "&::placeholder": {
      opacity: 1,
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "16px",
      fontFamily: "Montserrat",
      color: "#A6A6A6",
    },
  },

  "&.custom-field .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "&.custom-field .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "&.custom-field .MuiInput-underline:after": {
    borderBottom: "2px solid #6F05EA",
  },
});
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400,
  "&.capital":{
    textTransform:"capitalize"
  }
});

const ButtonWrapperMain = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: 26,
  "@media (max-width: 600px)": {
    gap: 20,
  },
  "& .grayBtn": {
    color: "#4B4B4B",
    borderColor: "#4B4B4B",
  },
  "& .addModalBtn": {
    textTransform: "none",
    width: "180px",
    height: "56px",
    borderRadius: "31px",
    fontSize: 24,
    fontWeight: 600,
    border: "1px solid",
    "@media (max-width: 960px)": {
      width: "150px",
      height: "46px",
      fontSize: 20,
    },
    "@media (max-width: 600px)": {
      height: "36px",
      fontSize: 18,
    },
  },
  "& .purpleBtn": {
    color: "#6F05EA",
    borderColor: "#6F05EA",
  },
  '& .Mui-disabled': {
    color: "#B7B7B7 !important",
    borderColor: "#B7B7B7 !important",
  }
});


// FORM 2
const AvailabilityWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  marginBottom: "48px"
});
const DayBoxWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  backgroundColor: "#F8F8F8",
  borderRadius: "4px",
  padding: "0 20px",
});
const DayBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "60px",
  width: "142px",

});
const TimeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "60px",
});
const DayLabel = styled(FormControlLabel)({
  margin: "0px",
  "&.checked-label .MuiFormControlLabel-label": {
    color: "#000000",
  },
  "&.default-label .MuiFormControlLabel-label": {
    color: "#A8A29E",
  },
  "& .MuiFormControlLabel-label": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: "Montserrat",
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px",
  },
});
const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiSelect-select": {
    backgroundColor: "transparent",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    width: 44
  },
  "&.edit-select .MuiSelect-select": {
    color: "#6F05EA",
  },
  "&.edit-select .MuiSelect-icon": {
    color: "#6F05EA",
    marginTop: "-2px",
  },
});
const DownArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
    marginTop: "-2px",
  },
});


const MultiSelect = styled(Select)({
  "& .multi-select-root": {
    backgroundColor: "transparent",
  },
  "&:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    fontFamily: 'Montserrat',
    backgroundColor: 'transparent !important',
    fontSize: "12px",
    height: 22,
    paddingTop: 8,
    paddingBottom: 4
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "transparent !important"
  },
})

const CustomCheckbox = styled(Checkbox)({
  width: 13,
  height: 13,
  backgroundColor: "transparent",
  color: "transparent",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "&.Mui-checked": {
    color: "transparent",
  },
});

const PlaceHolderFont = styled(Typography)({
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "16px",
  fontFamily: "Montserrat",
  color: "#A6A6A6"
})

const ValueFont = styled(Typography)({
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "16px",
  fontFamily: "Montserrat",
  color: "#1E293B",
  "&.capital":{
    textTransform:"capitalize"
  }
})

const ProfileAvatar = styled(Avatar)({
  height: "83px",
  width: "83px",
  position: "absolute",
})
const UploadImg = styled(`img`)({
  position: "relative",
  top: 28,
  left: 28
})

const PhotoBox = styled(Box)({
  position: "relative",
  height: "83px",
  width: "83px",
  borderRadius: "50%",
})

const TimeIcon = styled(AccessTimeIcon)({
  width: 24,
  height: 24,
  color: "#6F05EA"
})
const style = {
  phoneButtonStyle: {
    border: "none",
    background: "transparent",
  } as React.CSSProperties,
  phoneInputStyle: {
    border: "none",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
    borderRadius: "0px",
    height: "34px",
    paddingLeft: "40px",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
  } as React.CSSProperties,
  phoneDropDownStyle: {
    fontSize: "13px"
  } as React.CSSProperties,
  defaultImgInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  photoImgInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    borderRadius: "50%"
  } as React.CSSProperties,
}
// Customizable Area End
