import React from "react";
// Customizable Area Start
import {
    Box, styled, List, ListItem, ListItemIcon, ListItemText,
    Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, Avatar
} from '@material-ui/core';
import {
    DayCareBlack,
    DogWalkingBlack,
    HotelsBlack,
    GroomingBlack,
    PetSittingBlack,
    TrainingBlack,
    DayCareBlue,
    DogWalkingBlue,
    HotelsBlue,
    GroomingBlue,
    PetSittingBlue,
    TrainingBlue,
    AddServicesBlue,
    checkedRadio,
    unCheckedRadio,
    SwitchEstablishment,
    EstablishmentSetting,
    EstablishmentSettingBlue
} from "./assets";
import Dialog from '@material-ui/core/Dialog';
import { returnTruthyString, checkCondition } from "../../../components/src/HelperUtils";

export type ServiceIcons = {
    black_icon:any | string;
    blue_icon: any | string;
    label: any | string;
};
export const ServicesListImg: Record<string, ServiceIcons> = {
    "Day Care": {
        "black_icon": DayCareBlack,
        "blue_icon": DayCareBlue,
        "label": "Guardería"
    },
    "Dog Walking": {
        "black_icon": DogWalkingBlack,
        "blue_icon": DogWalkingBlue,
        "label": "Paseo de perros"
    },
    "Hotels": {
        "black_icon": HotelsBlack,
        "blue_icon": HotelsBlue,
        "label": "Hoteles"
    },
    "Grooming": {
        "black_icon": GroomingBlack,
        "blue_icon": GroomingBlue,
        "label": "Estética"
    },
    "Grooming (Coming Soon)": {
        "black_icon": GroomingBlack,
        "blue_icon": GroomingBlue,
        "label": "Estética (Próximamente)"
    },
    "Pet Sitting": {
        "black_icon": PetSittingBlack,
        "blue_icon": PetSittingBlue,
        "label": "Cuidado de mascotas"
    },
    "Training": {
        "black_icon": TrainingBlack,
        "blue_icon": TrainingBlue,
        "label": "Entrenamiento"
    }


}

import SidebarServicesController, { Props } from "./SidebarServicesController.web";
import { LanguageCtx } from "../../../components/src/context/LanguageContext";
// Customizable Area End

export default class SidebarServices extends SidebarServicesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    static contextType = LanguageCtx;
    renderDynamicMenus = () => {
        const {languageData}=this.context;
        const ServicesList = languageData?.translation?.SideBarService?.ServicesList || {};
        const AddService = languageData?.translation?.SideBarService?.AddService || {};
        
       
        
        return (
            <>
                <ListWrapper style={style.listMargin}>
                    {this.props.filtredServicesList.map((service, index) => {
                        const imageURL = this.props.selectedService.name == service.name ? ServicesListImg[service.name]?.blue_icon : ServicesListImg[service.name]?.black_icon
                        const fontClass = this.props.selectedService.name == service.name ? "blue-font" : ""
                        const menuName = this.transSidebar(ServicesList[service.name]?.label)
                        return (
                            <StyledList button key={index} data-testid={`menu-${index}`} onClick={() => this.selectListItem(service)}>
                                <ListIcon>
                                    <img src={imageURL} />
                                </ListIcon>
                                <ListLabel primary={menuName} className={fontClass} />
                            </StyledList>
                        )
                    })}
                    <StyledList button data-testid="add-service-tab" onClick={this.props.openServiceModalFn}>
                        <ListIcon>
                            <img src={AddServicesBlue} />
                        </ListIcon>
                        <ListLabel primary={AddService.label} className="blue-font" />
                    </StyledList>
                </ListWrapper>
            </>
        )
    }
    renderEstablishment = () => {
        const {languageData}=this.context;
      
        const stringObj = localStorage.getItem("establishmentInfo");
        const establishmentDetails = stringObj ? JSON.parse(stringObj) : {};
        const avatarUrl = returnTruthyString(establishmentDetails?.attributes?.establishment_photo)
        const establishment_name = returnTruthyString(establishmentDetails?.attributes?.establishment_name)
        const settingIcon = checkCondition(this.props.showsetting, EstablishmentSettingBlue, EstablishmentSetting) as string
        const fontClass = checkCondition(this.props.showsetting, "blue-font-bold","black-font") as string 
        const switchestablishment = languageData?.translation?.SideBarService?.switchestablishment || {};
        const establishment_setting = languageData?.translation?.SideBarService?.establishment_setting || {};
        return (
            <>
                <ListWrapper style={style.establishmentWrapper}>
                    <EstablishmentDetails>
                        <EstablishmentAvatar src={this.props.handleEstablishmentInfo.establishment_photo!==''?this.props.handleEstablishmentInfo.establishment_photo:avatarUrl} />
                        <EstablishmentTitle noWrap>
                            <span title={establishment_name}>{this.props.handleEstablishmentInfo.establishment_name!==''? this.props.handleEstablishmentInfo.establishment_name:establishment_name}</span>
                        </EstablishmentTitle>
                    </EstablishmentDetails>
                    <EstablishmentTab button id="switch-establishment" data-test-id="navigation-Id" onClick={this.GotoSettingScreen}  >
                        <ListIcon><img src={SwitchEstablishment} /></ListIcon>
                        <ListLabel primary={switchestablishment.label} className="black-font" />
                    </EstablishmentTab>
                    <EstablishmentTab button id="establishment-setting" data-test-id="navigation-Button" onClick={this.props.showsettingFn}>
                        <ListIcon><img src={settingIcon}/></ListIcon>
                        <ListLabel primary={establishment_setting.label} className={fontClass} />
                    </EstablishmentTab>
                </ListWrapper>
            </>
        )
    }
    renderAddServiceModal = () => {
        const {languageData,alternateLanguage}=this.context;
        
        const ServicesList = languageData?.translation?.SideBarService?.ServicesList || {};
        const Service_type = languageData?.translation?.SideBarService?.Service_type || {};
        
        return (
            <AddServicesModal
                open={this.props.addServiceModalOpen}
                fullWidth
                maxWidth={"md"}
                aria-labelledby="add-services-id"
                data-testid="add-services-modal"
            >
                <ModalTitle>{Service_type.label}</ModalTitle>
                <FormWrapper>
                    <RadioWrapper>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="position" defaultValue="top">
                                {this.state.addServicesList.map((service,index) => {
                                    const label = ServicesList[service.name].label
                                    return (
                                        <StyledRadioControl
                                            value={service.name}
                                            onChange={(event) => this.handleRadioChange(service.name)}
                                            checked={this.state.selectedAddService == service.name}
                                            control={
                                                <Radio color="primary"
                                                    checkedIcon={<img src={checkedRadio} />}
                                                    icon={<img src={unCheckedRadio} />} />
                                            }
                                            label={
                                                <LabelBox style={{ opacity: 1 }}>
                                                    <img src={ServicesListImg[service.name].black_icon} /> {this.transSidebar(label)}
                                                </LabelBox>
                                            }
                                            labelPlacement="end"
                                            data-testid={`add-service-menus-${index}`}
                                        />
                                    )
                                })}

                            </RadioGroup>
                        </FormControl>

                    </RadioWrapper>

                    <ButtonWrapper>
                        <CustomButton
                            style={style.blackBtn}
                            onClick={this.closeAddServiceModal}
                            data-testid="cancel-btn"
                        >
                            {this.transSidebar("Cancel")}
                        </CustomButton>
                        <CustomButton
                            disabled={!this.state.selectedAddService}
                            style={style.blueBtn}
                            onClick={this.handleNextBtnClick}
                            data-testid="next-btn"
                        >
                            {this.transSidebar("Next")}
                        </CustomButton>
                    </ButtonWrapper>
                </FormWrapper>
            </AddServicesModal >
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
   
        return (
            <>
                <MainWrapper>
                    {this.renderDynamicMenus()}
                    {this.renderEstablishment()}
                    {this.renderAddServiceModal()}
                </MainWrapper>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ListWrapper = styled(List)({
    padding: "0px !important"
})

const MainWrapper = styled(Box)({
    padding: "24px !important",
    "@media (max-width: 400px)": {
        width: "200px"
    },
})

const StyledList = styled(ListItem)({
    height: 48,
    padding: "12px !important",
    marginBottom: "20px",
    borderRadius: "8px !important",
})
const EstablishmentTab = styled(ListItem)({
    height: 48,
    padding: "12px !important",
    marginBottom: "8px",
    borderRadius: "8px !important",
})

const ListIcon = styled(ListItemIcon)({
    minWidth: 0,
    marginRight: 12
})
const ListLabel = styled(ListItemText)({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#0F172A",
    "& span": {
        fontFamily: 'Montserrat',
    },
    "&.blue-font": {
        color: "#6F05EA !important",
    },
    "&.black-font": {
        color: "#000 !important",
    },
    "&.blue-font-bold": {
        color: "#6F05EA !important",
        "& span": {
            fontWeight: 600,
        },        
    },
})

const AddServicesModal = styled(Dialog)({
    "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
            maxWidth: 768,
            padding: "40px",
            borderRadius: "8px"
        },
    },
})
const ModalTitle = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "27px",
    color: "#6F05EA",
})
const FormWrapper = styled(`form`)({
    margin: "0"
})
const RadioWrapper = styled(Box)({
    margin: "40px 0"
})
const ButtonWrapper = styled(Box)({
    display: "flex",
    justifyContent: "end",
    columnGap: "20px",
})
const CustomButton = styled(Button)({
    padding: "8px 0",
    height: "40px",
    width: "140px",
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none"
})

const LabelBox = styled(Box)({
    marginLeft: "22px",
    display: "flex",
    flexDirection: "row",
    columnGap: "12px",
    alignItems: "center",

    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
})
const StyledRadioControl = styled(FormControlLabel)({
    margin: "0px 0px 20 0px"
})

const EstablishmentDetails = styled(Box)({
    marginBottom: "16px",
    display: "flex",
    flexDirection: "row",
    columnGap: "20px",
    alignItems: "center",
})
const EstablishmentAvatar = styled(Avatar)({
    height: 56,
    width: 56
})
const EstablishmentTitle = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000",
})
const style = {
    listMargin: {
        marginTop: 108
    } as React.CSSProperties,
    blueBtn: {
        color: "#6F05EA",
        border: "1px solid #6F05EA",
    } as React.CSSProperties,
    blackBtn: {
        color: "#4B4B4B",
        border: "1px solid #4B4B4B",
    } as React.CSSProperties,
    establishmentWrapper: {
        marginTop: 300
    } as React.CSSProperties,
}
// Customizable Area End
