import { Formik, FormikErrors, FormikTouched } from "formik";
import GenericModal from "../../../../components/src/GenericModal";
import * as Yup from "yup";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import InputField from "../../../../components/src/InputField";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FormWrapper } from "./FormWrapper";
import useBreakpoints from "../../../../components/src/hooks/useBreakpoints";
import { transPt } from "../../../../components/src/HelperUtils";

interface IProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    onSubmit: (values: PasswordFormValues) => void;
    goToForgotPassword: () => void;
    getInputProps: <T extends object>(
        field: keyof T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: T,
        isEdit: boolean,
    ) => any;
    error?: string;
}

export type PasswordFormValues = {
    current_password: string;
    password: string;
    password_confirmation: string;
}

const initialValues = {
    current_password: '',
    password: '',
    password_confirmation: ''
}

const validationSchema = Yup.object().shape({
    current_password: Yup.string()
      .required('*Current password is required'),
    password: Yup.string()
      .required('*New password is required')
      .notOneOf([Yup.ref('current_password')], '*Your password must be different from the last password '),
    password_confirmation: Yup.string()
      .when('password', {
        is: (password) => (password && password.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], '*Passwords do not match.')
      })
      .required('*Confirm password is required')
});

const stylesLabel = {
    filledLabel: {
        fontSize: "13px",
        fontWeight: 400,
        fontFamily: "Montserrat",
        color: "#6F05EA",
    },
    secondaryLabel: {
        fontWeight: 600,
        fontSize: '18px',
        color: '#334155',
        fontFamily: "Montserrat",
        lineHeight: "21.94px"
    },
}

const ResetPasswordModal = (props: IProps) => {
    const { isOpen, title = "", onClose, onSubmit, getInputProps, goToForgotPassword, error  } = props;
    const [currentVisible, setCurrentVisible] = useState(true);
    const [newPassword, setNewVisible] = useState(true);
    const [repeat, setRepeatVisible] = useState(true);

    const updateCurrentVisible = (visibility: boolean) => {
        setCurrentVisible(visibility)
    }
    const updateNewVisible = (visibility: boolean) => {
        setNewVisible(visibility)
    }
    const updateRepeatVisible = (visibility: boolean) => {
        setRepeatVisible(visibility)
    }

    const { less600 } = useBreakpoints();

    const toggleButton = (field: keyof PasswordFormValues) => {
        switch (field) {
            case 'current_password':
                updateCurrentVisible(!currentVisible);
                break;
            case 'password': 
            updateNewVisible(!newPassword);
                break;
            case 'password_confirmation':
                updateRepeatVisible(!repeat)
                break;
            default:
                break;
        }
    }

    const getFieldVisibility = (field: keyof PasswordFormValues) => {
        switch (field) {
          case 'current_password':
            return currentVisible;
          case 'password':
            return newPassword;
          case 'password_confirmation':
            return repeat;
          default:
            return false;
        }
      };

    const renderEndAdornment = (field: keyof PasswordFormValues) => (
        <IconButton
          aria-label="toggle password visibility"
          data-test-id={`adornment-${field}`}
          onClick={() => toggleButton(field)}
          edge="end"
        >
          {getFieldVisibility(field) ? <VisibilityOff style={{ fill: "#A6A6A6" }}/> : <Visibility />}
        </IconButton>
    );

    const styles = {
        body: { padding: less600 ? '16px 25px' : '30px 50px'},
        header: { color: '#6F05EA', fontSize: less600 ? '26px' : '32px' }
    }

    return (
        <GenericModal 
            id='password-reset' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose} 
            bodyStyle={styles.body} 
            headerStyle={styles.header} 
            withoutCloseBtn
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            data-test-id="reset-password-form"
          >
            {({
                values,
                errors,
                touched,
                dirty,
                handleSubmit,
                getFieldProps,
                resetForm,
              }) => {
                return (
                    <FormWrapper>
                        {error && <Box className="error-box">{error}</Box>}
                        <form onSubmit={handleSubmit} className="form">
                            <Box style={{marginTop: "40px"}}>
                            <style>
                                {`
                                    .MuiInputAdornment-positionStart{
                                        margin-left: 0px !important;
                                    }
                                `}
                            </style>
                                <InputField
                                    {...getFieldProps("current_password")}
                                    type={
                                        currentVisible
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("current_password", errors, touched, values, true)}
                                    placeholder={transPt("Current password","customerUserProfile")}
                                    label={transPt("Current password","customerUserProfile")}
                                    endAdornment={renderEndAdornment('current_password')}
                                    labelStyle={!dirty ? stylesLabel.secondaryLabel : stylesLabel.filledLabel}
                                    data-test-id="current_password"
                                    name="current_password"
                                />
                                <Typography className="hint">
                                    {transPt("Forgot your password? Click","customerUserProfile")} <span onClick={() => goToForgotPassword()} className="redirect-link">{transPt("here","customerUserProfile")}.</span>.
                                </Typography>
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("password")}
                                    type={
                                        newPassword
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("password", errors, touched, values, true)}
                                    placeholder={transPt("New password","customerUserProfile")}
                                    label={transPt("New password","customerUserProfile")}
                                    endAdornment={renderEndAdornment('password')}
                                    labelStyle={!dirty ? stylesLabel.secondaryLabel : stylesLabel.filledLabel}
                                />
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("password_confirmation")}
                                    type={
                                        repeat
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("password_confirmation", errors, touched, values, true)}
                                    placeholder={transPt("Confirm New password","customerUserProfile")}
                                    label={transPt("Confirm New password","customerUserProfile")}
                                    endAdornment={renderEndAdornment('password_confirmation')}
                                    labelStyle={!dirty ? stylesLabel.secondaryLabel : stylesLabel.filledLabel}
                                />
                            </Box>
                            <Box className="box-wrapper">
                                {dirty 
                                    ? <Button className="secondary-btn" onClick={() => resetForm()} data-test-id="password-clear-btn">{transPt("Clear","customerUserProfile")}</Button> 
                                    : <Button className="secondary-btn" onClick={() => onClose()}>{transPt("Cancel","customerUserProfile")}</Button>
                                }
                                <Button className="main-btn" type="submit">{transPt("Save","customerUserProfile")}</Button>
                            </Box>
                        </form>
                    </FormWrapper>
                )
              }}
          </Formik>
        </GenericModal>
    )
}

export default ResetPasswordModal;