import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Grid, styled, Typography, Checkbox, Tooltip, CircularProgress } from "@material-ui/core";
import CommoditiesWebController, { Props } from "../generalInfo/CommoditiesController.web";
import { CheckedCheckBox, UnCheckedCheckBox } from "../assets";
import { transPt } from "../../../../components/src/HelperUtils";
// Customizable Area End

// Customizable Area Start
export interface ICommoditiesOption {
  id: number;
  label: string;
  key:string;
  value: boolean | null;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#ede1ff",
        color:"#000000",
        fontSize:"12px",
        fontFamily: "Montserrat",
      },
      arrow:{
        color: "#ede1ff",
        fontSize:"12px"
      }
    }
  }
});
const CommoditiesContainer = styled(Box)({
  height: "100%",
  "& .commoditiesOptionContainer": {
    marginTop: "8px",
  },
  "& .commodityCardContainer":{
    padding:"0px 52px 16px 0px",
    "@media (max-width: 600px)": {
      padding:"0px 0px 16px 0px",
    },
  },
  "& .optionContainer": {
    height: 60,
    display: "flex",
    gap: "16px",
    backgroundColor: "#F5F3F3",
    borderRadius: "4px",
    alignItems: "center",
    padding: "0px 20px",
    "@media (max-width: 960px)": {
      padding: "0px 8px",
    },
  },
  "& .optionName": {
    fontSize: 14,
    fontWeight: 500,
    color: "#A8A29E",
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
    fontFamily: "Montserrat",
  },
  "& .optionCard": {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  "& .commoditiesButtonContainer": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
    marginTop: "32px",
    "@media (max-width: 960px)": {
      marginTop: "54px",
    },
  },
  "& .cancelButton": {
    borderColor: "#78716C",
    color: "#78716C",
    fontSize: 18,
    fontWeight: "700",
    padding: "0px 40px",
  },
  "& .actionButton": {
    height: "40px",
    border: "1px solid",
    textTransform: "none",
    borderRadius: "100px",
  },
  "& .saveButton": {
    borderColor: "#6F05EA",
    color: "#6F05EA",
    fontSize: 18,
    fontWeight: "700",
    padding: "0px 50px",
  },
  "& .editButtonContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "32px",
  },
  "& .checkboxContainer":{
    "& .MuiCheckbox-colorSecondary.Mui-checked":{
      color:"6F05EA"
    },
    "& .MuiCheckbox-root":{
      color:"6F05EA",
      padding:"0px"
    },
  },
  "& .selectedOptionName": {
    fontSize: 14,
    fontWeight: 500,
    color: "#6F05EA",
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
    fontFamily: "Montserrat",
  },
});
const LoaderContainer = styled(Box)({
    display:"flex",
    width:"100%",
    justifyContent:"center",
    "& .MuiCircularProgress-colorPrimary":{
      color:"#6F05EA"
    }
})
// Customizable Area End

export default class CommoditiesWeb extends CommoditiesWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderOptionCard = () => {
    return (
      <Grid container >
        {this.state.commoditiesList.length > 0 && (this.state.commoditiesList.map((item: ICommoditiesOption) => (
          <Grid item xs={12} sm={6} md={4} lg={4} className="commodityCardContainer">
            
            <Box key={item.id} className="optionContainer">
              {this.state.isEdit && (
                <Box className="checkboxContainer">
                  <Checkbox 
                  onChange={() => this.handleCheckboxChange(item)} 
                  checked={item.value ? item.value : false}
                  data-test-id="option-check-box"
                  checkedIcon={<img src={CheckedCheckBox} />}
                  icon={<img src={UnCheckedCheckBox} />}
                  />
                </Box>
              )}
              <Tooltip title={item.label} arrow>
              <Typography className={item.value ? "selectedOptionName" : "optionName"}>{item.label}</Typography>
              </Tooltip>
            </Box>
          </Grid>
        )))}
     
      </Grid>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {!this.state.commoditiesListLoading ? (<CommoditiesContainer>
          <Box className="commoditiesOptionContainer">
            {this.renderOptionCard()}
          </Box>
          {!this.state.isEdit ? (
            <Box className="editButtonContainer">
              <Button
                className="saveButton actionButton"
                onClick={this.handleEditClick}
                data-test-id="edit-btn"
              >
                {transPt(`generalInfo.Edit`,"establishmentService")}
              </Button>
            </Box>
          ) : (
            <Box className="commoditiesButtonContainer">
              <Button
                className="cancelButton actionButton"
                onClick={this.handleCancelClick}
                data-test-id="cancel-btn"
              >
                {transPt(`generalInfo.Cancel`,"establishmentService")}
              </Button>
              <Button
                className="saveButton actionButton"
                onClick={this.handleSaveClick}
                data-test-id="save-btn"
              >
                {transPt(`generalInfo.Save`,"establishmentService")}
              </Button>
            </Box>
          )}
        </CommoditiesContainer>) :(<></>)}
        {this.state.commoditiesListLoading ? (
              <LoaderContainer >
                <CircularProgress />
              </LoaderContainer>
            ) : (
              <></>
            )} 
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
