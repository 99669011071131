import React from "react";
// Customizable Area Start
import {
  Box, styled, Button, Typography, IconButton, TextField,
  Select, MenuItem, ImageList,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ServicesController, { Props } from "./ServicesController.web"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {
  Cross, ViewClose,
} from "../assets";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { checkCondition, returnTruthyString } from "../../../../components/src/HelperUtils";
import { SelectProps } from '@material-ui/core/Select';
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton, SimpleDialog } from "../../../../components/src/HelperComponents";
import SubServiceGalleryWeb from "./SubServiceGallery.web";

export interface FormError {
  [key: string]: string;
}
export interface FormErrorTouched {
  [key: string]: boolean;
}
interface IMenus {
  name: string,
  value: string,
  minutes?: number,
}

type MenuWithDuration = IMenus & { minutes: number };

export const ServiceDurationList: MenuWithDuration[] = [
  {
    name: "30 mins",
    value: "30 mins",
    minutes: 30,
  },
  {
    name: "1 hour",
    value: "1 hour",
    minutes: 60,
  },
  {
    name: "1.5 hours",
    value: "1.5 hours",
    minutes: 90,
  },
  {
    name: "2 hours",
    value: "2 hours",
    minutes: 120,
  },
  {
    name: "2.5 hours",
    value: "2.5 hours",
    minutes: 150,
  },
  {
    name: "3 hours",
    value: "3 hours",
    minutes: 180,
  },
  {
    name: "3.5 hours",
    value: "3.5 hours",
    minutes: 210,
  },
  {
    name: "4 hours",
    value: "4 hours",
    minutes: 240,
  },
  {
    name: "4.5 hours",
    value: "4.5 hours",
    minutes: 270,
  },
  {
    name: "5 hours",
    value: "5 hours",
    minutes: 300,
  },
]
const ServiceTimeList: IMenus[] = [
  {
    name: "Morning",
    value: "Morning",
  },
  {
    name: "Evening",
    value: "Evening",
  },
]

const menuProps: Partial<SelectProps['MenuProps']> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
const serviceCommonSchema = {
  serviceTitle: Yup.string()
    .required("*Service Title is required")
    .trim(),
  serviceCapacity: Yup.number()
    .typeError("*Capacity must be a number")
    .required("*Capacity is required"),
  serviceAllowedPet: Yup.string()
    .required("*Allowed Pet is required")
    .trim(),
  serviceDescription: Yup.string()
    .required("*Description is required")
    .trim(),
  imagesArray: Yup.array()
    .min(1, "*One image is required"),
};

const getValidationSchema = (serviceType:string) => {
  const dogWalkingSchema = {
    serviceTime: Yup.string().required("*Service Time is required").trim(),
  };

  const groomingSchema = {
    serviceDuration: Yup.string().required("*Service Duration is required").trim(),
  };

  switch (serviceType) {
    case "Dog Walking":
      return Yup.object().shape({
        ...serviceCommonSchema,
        ...dogWalkingSchema
      });
    case "Grooming":
      return Yup.object().shape({
        ...serviceCommonSchema,
        ...groomingSchema
      })
    default:
      return Yup.object().shape({
        ...serviceCommonSchema
      });
  }
}
// Customizable Area End

export default class Services extends ServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderAddServicesModal = () => {
    const isOpen = this.state.openAddService || this.state.openEditService;
    const modalTitle = checkCondition(this.state.openEditService, this.transService("Edit Services"), this.transService("Add Services")) as string;
    const serviceType = this.props.selectedService.name;
    return (
      <DialogContainer
        open={isOpen}
        fullWidth
        aria-labelledby="add-services-id"
        data-testid="add-services-modal"
      >
        <FormTitle id="add-services-id">{modalTitle}</FormTitle>
        <FormWrapper>
          <Formik
            initialValues={this.state.serviceFormInitialValue}
            validationSchema={getValidationSchema(
              this.props.selectedService.name
            )}
            enableReinitialize
            onSubmit={(values) => {
              this.addUpdateApiCall(values);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleBlur,
              getFieldProps,
              values,
            }) => {
              const handleServiceChange = (
                event: React.ChangeEvent<{ name?: string; value: unknown }>
              ) => {
                const service = event.target.value as string;
                this.handleServiceTitleChange(service);
                setFieldValue("serviceTitle", service);
                setFieldValue("serviceAllowedPet", "");
              };
              return (
                <form onSubmit={handleSubmit}>
                  <RowBox>
                    {serviceType === "Pet Sitting" ? (
                      <LeftBox>
                        <FormLabel>{this.transService("Service")}</FormLabel>
                        <CustomTextField
                          {...getFieldProps("serviceTitle")}
                          placeholder="Enter Service"
                          fullWidth
                          error={
                            this.getErrorAndHelperText(
                              "serviceTitle",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                          helperText={
                            this.getErrorAndHelperText(
                              "serviceTitle",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        />
                      </LeftBox>
                    ) : (
                      <LeftBox>
                        <FormLabel>{this.transService("Service")}</FormLabel>
                        <CustomSelect
                          fullWidth
                          id="service-id"
                          {...getFieldProps("serviceTitle")}
                          defaultValue={""}
                          displayEmpty
                          IconComponent={ArrowIcon}
                          data-test-id="service-title-select"
                          MenuProps={menuProps}
                          onChange={(event) => {
                            handleServiceChange(event);
                          }}
                          error={
                            this.getErrorAndHelperText(
                              "serviceTitle",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                        >
                          <MenuItem value="" disabled>
                            <span style={style.defaultMenu}>
                              {this.transService("Select Service Type")}
                            </span>
                          </MenuItem>
                          {this.state.serviceOptionList.length > 0 &&
                            this.state.serviceOptionList.map((option) => (
                              <MenuFont value={option.key}>
                                {option.label}
                              </MenuFont>
                            ))}
                        </CustomSelect>
                        <FormHelperText
                          data-test-id="upload-error"
                          className="errorText"
                        >
                          {
                            this.getErrorAndHelperText(
                              "serviceTitle",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        </FormHelperText>
                      </LeftBox>
                    )}
                    <RightBox>
                      <FormLabel>{this.transService("Capacity")}</FormLabel>
                      <CustomTextField
                        {...getFieldProps("serviceCapacity")}
                        placeholder={this.transService("Enter Capacity")}
                        fullWidth
                        error={
                          this.getErrorAndHelperText(
                            "serviceCapacity",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "serviceCapacity",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      />
                    </RightBox>
                  </RowBox>

                  <RowBox style={{ marginTop: 40 }}>
                    <Box style={{ maxWidth: 210, width: "100%" }}>
                      <FormLabel>{this.transService("Allowed Pet")}</FormLabel>
                      <CustomSelect
                        fullWidth
                        {...getFieldProps("serviceAllowedPet")}
                        id="room-title-id"
                        defaultValue={""}
                        displayEmpty
                        IconComponent={ArrowIcon}
                        MenuProps={menuProps}
                        error={
                          this.getErrorAndHelperText(
                            "serviceAllowedPet",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                      >
                        <MenuItem value="" disabled>
                          <span style={style.defaultMenu}>{this.transService("Select Pet")}</span>
                        </MenuItem>
                        {this.state.allowedPetOptionList.length > 0 &&
                          this.state.allowedPetOptionList.map((option) => (
                            <MenuFont value={option.key}>
                              {option.label}
                            </MenuFont>
                          ))}
                      </CustomSelect>
                      <FormHelperText
                        data-test-id="upload-error"
                        className="errorText"
                      >
                        {
                          this.getErrorAndHelperText(
                            "serviceAllowedPet",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      </FormHelperText>
                    </Box>
                    {this.props.selectedService.name === "Grooming" && (
                      <Box style={{ maxWidth: 127, width: "100%" }}>
                        <FormLabel>{this.transService("Service Duration")}</FormLabel>
                        <CustomSelect
                          fullWidth
                          {...getFieldProps("serviceDuration")}
                          name="serviceDuration"
                          id="service-duration-id"
                          defaultValue={""}
                          displayEmpty
                          IconComponent={ArrowIcon}
                          value={values.serviceDuration}
                          MenuProps={menuProps}
                          error={
                            this.getErrorAndHelperText(
                              "serviceDuration",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).isError
                          }
                        >
                          <MenuItem value="" disabled>
                            <span style={style.defaultMenu}>00:00</span>
                          </MenuItem>
                          {ServiceDurationList.map((item, index) => {
                            return (
                              <MenuFont value={item.value} key={index}>
                                {item.name}
                              </MenuFont>
                            );
                          })}
                        </CustomSelect>
                        <FormHelperText
                          data-test-id="upload-error"
                          className="errorText"
                        >
                          {
                            this.getErrorAndHelperText(
                              "serviceDuration",
                              errors as FormError,
                              touched as FormErrorTouched
                            ).helperText
                          }
                        </FormHelperText>
                      </Box>
                    )}
                    {this.props.selectedService.name === "Dog Walking" && (
                        <Box style={{ maxWidth: 124, width: "100%" }}>
                          <FormLabel>{this.transService("Service Time")}</FormLabel>
                          <CustomSelect
                            {...getFieldProps("serviceTime")}
                            fullWidth
                            id="room-title-id"
                            defaultValue={""}
                            displayEmpty
                            IconComponent={ArrowIcon}
                            data-test-id="service-time-select"
                            MenuProps={menuProps}
                            error={
                              this.getErrorAndHelperText(
                                "serviceTime",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).isError
                            }
                          >
                            <MenuItem value="" disabled>
                              <span style={style.defaultMenu}>{this.transService("Select Time")}</span>
                            </MenuItem>
                            {ServiceTimeList.map((item, index) => {
                              return (
                                <MenuFont value={item.value} key={index}>
                                  {item.name}
                                </MenuFont>
                              );
                            })}
                          </CustomSelect>
                          <FormHelperText
                            data-test-id="upload-error"
                            className="errorText"
                          >
                            {
                              this.getErrorAndHelperText(
                                "serviceTime",
                                errors as FormError,
                                touched as FormErrorTouched
                              ).helperText
                            }
                          </FormHelperText>
                        </Box>
                      )}
                  </RowBox>

                  <LeftBox
                    style={{ marginTop: 40 }}
                    className="description-container"
                  >
                    <FormLabel>{this.transService("Description")}</FormLabel>
                    <CustomTextArea
                      {...getFieldProps("serviceDescription")}
                      multiline
                      minRows={4}
                      data-testid="desc-input-fiels"
                      variant="outlined"
                      placeholder={this.transService("Enter Description")}
                      error={
                        this.getErrorAndHelperText(
                          "serviceDescription",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "serviceDescription",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </LeftBox>

                  <ImageWrraper>
                    <Box id="room-galary">
                      <FormLabel>{this.transService("Service Gallery")}</FormLabel>
                      <GalleryWrapper>
                        <UploadButton>
                          <UploadBox>
                            <Box>
                              <UploadIcon />
                              <UploadText>{this.transService("upload file (png,jpg)")}</UploadText>
                              <input
                                type="file"
                                multiple
                                accept=".jpg,.png"
                                data-test-id="upload-img-service"
                                style={style.imageInput}
                                onChange={(event) => {
                                  const fileList = event.currentTarget.files;
                                  if (fileList) {
                                    const files = Array.from(fileList);
                                    const newFiles = [
                                      ...values.imagesArray,
                                      ...files,
                                    ];
                                    setFieldValue("imagesArray", newFiles);
                                  }
                                }}
                              />
                            </Box>
                          </UploadBox>
                        </UploadButton>
                        <ImageListWrapper>
                          {values.imagesArray.map((file, index) => {
                            return (
                              <ImageBox key={index}>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt="preview"
                                  width={83}
                                  height={83}
                                />
                                <RemoveBtn
                                  onClick={() => {
                                    const updatedFiles = values.imagesArray.filter(
                                      (_, i) => i !== index
                                    );
                                    setFieldValue("imagesArray", updatedFiles);
                                  }}
                                  data-test-id="remove-btn"
                                >
                                  <img src={Cross} />
                                </RemoveBtn>
                              </ImageBox>
                            );
                          })}
                        </ImageListWrapper>
                      </GalleryWrapper>
                      <FormHelperText
                        data-test-id="upload-error"
                        className="errorText"
                      >
                        {
                          this.getErrorAndHelperText(
                            "imagesArray",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      </FormHelperText>
                    </Box>
                  </ImageWrraper>
                  <FormButtonWrapper>
                    <FormButton
                      style={style.greyBtn}
                      data-testid="cancel-btn"
                      onClick={this.closeAddServiceModal}
                    >
                      {this.transService("Cancel")}
                    </FormButton>
                    <LoadingButton
                      buttonText={returnTruthyString(this.transService("Save"))}
                      isLoading={this.state.saveBtnLoading}
                      style={{ ...style.formButton, ...style.blueBtn }}
                      data-testid="save-btn"
                      type="submit"
                    >
                      {this.transService("Save")}
                    </LoadingButton>
                  </FormButtonWrapper>
                </form>
              );
            }}
          </Formik>
        </FormWrapper>
      </DialogContainer>
    );
  }
  
  renderImageGallery = () => {
    return (
      <Box className="imageGalleryContainer">
        <SubServiceGalleryWeb data-test-id="service-gallery" serviceImageList={this.state.selectedViewData.attributes.images_urls} galleryBackClick={this.serviceImageBackClick} galleryImageClick={this.serviceImageClick}/>
      </Box>
    );
  };

  renderSimpleDialogForService = () => {
    const { openSimpleModalForService, messageSimpleModalForService } = this.state;
    return (
      <>
        {openSimpleModalForService &&
          <SimpleDialog
            open={openSimpleModalForService}
            data-testid="simple-dialog"
            showRightBtn
            message={messageSimpleModalForService}
            handleRightClick={this.simpleModalCloseForService}
            rightBtnTitle="Okay"
          />
        }
      </>
    )
  }

  renderViewServicesModal = () => {
    const service = this.state.selectedViewData.attributes;
    const isDisplayModal = checkCondition(this.state.isImgSlider, "none", "") as string;
    return (
      <ViewDialogContainer
        open={this.state.openViewService}
        fullWidth
        maxWidth={"md"}
        aria-labelledby="view-services-id"
        data-testid="view-services-modal"
        style={{ display: isDisplayModal }}
      >
        <Box className="viewDialogContainer">
          <ViewDialogTitle>
            <ViewTitle>
              <CustomIconBtn
                style={style.viewClose}
                onClick={this.closeViewServiceModal}
                data-testid="view-close-btn"
              >
                <img src={ViewClose} />
              </CustomIconBtn>
            </ViewTitle>
            <Box style={{ paddingRight: "15px", marginBottom: "25px" }}>
              <Typography className="titleTypo">{returnTruthyString(service.title)}</Typography>
              <Box className="infoContainer">
                <Typography className="infoTypo">
                  {this.transService("Capacity")} : <span>{service.capacity}</span>{" "}
                </Typography>
                <Typography className="infoTypo">
                  {this.transService("Allowed Pets")} : <span>{returnTruthyString(service.allows_pet)}</span>
                </Typography>
                <Typography className="infoTypo">
                  {service.shift ? (
                    <>
                      {this.transService("Service Time")} : <span>{service.shift}</span>
                    </>
                  ) : ""}
                  {service.duration ? (
                    <>
                      {this.transService("Service Duration")} : <span>{service.duration}</span>
                    </>
                  ) : ""}
                </Typography>
              </Box>
              <Box className="descriptionContainer">
                <span className="descriptionTypo">
                  {service.description}
                </span>
              </Box>
            </Box>
          </ViewDialogTitle>
          <DialogContent className="imgGalleryContainer">
            <Box>{this.renderImageGallery()}</Box>
          </DialogContent>
          <Box className="viewDialogBtnContainer">
            <Button
              className="viewEditBtn"
              onClick={this.openEditServiceModal}
              data-testid="view-edit-btn"
            >
              {this.transService("Edit")}
            </Button>
          </Box>
        </Box>
      </ViewDialogContainer>
    );
  }

  renderCreateServiceModal = () => {
    return (
      <StyledCreateService
        fullWidth
        open={this.state.openCreateService}
        maxWidth={"md"}
        data-test-id="cerate-service-modal"
      >
        <Box className="CreateServiceContainer">
          <CreateServiceImgWrapper>
            <CloseIconButton onClick={() => this.handleCreateServiceDialogClose()} data-test-id="close-button"><img src={ViewClose} alt="close-icon" /></CloseIconButton>
          </CreateServiceImgWrapper>
          <CreateServiceInfoWrapper>
            <Typography className="CreateServiceTypo">
              {this.transService("To create a service, we'll need some basic information.")}
            </Typography>
            <Typography className="pleasFillTypo">
              {this.transService("Please fill in the general details first!")}
            </Typography>
          </CreateServiceInfoWrapper>
          </Box>
      </StyledCreateService>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <ListWrapper>
          {this.state.getServiceListData.length > 0 && !this.state.getServiceListLoading ? (this.state.getServiceListData.map((service, index) => {
            return (
              <>
                <ServicesWrapper key={index}>
                  <TitleWrapper>
                    <LabelFont>{this.transService("Services")} :</LabelFont>
                    <ValueFont style={{ maxWidth: 125 }} noWrap><span title={service.attributes.title}>{returnTruthyString(service.attributes.title)}</span></ValueFont>
                  </TitleWrapper>
                  <CapacityWrapper>
                    <LabelFont>{this.transService("Capacity")} :</LabelFont>
                    <ValueFont style={{ fontWeight: 600 }} noWrap>{service.attributes.capacity}</ValueFont>
                  </CapacityWrapper>
                  <DescWrapper>
                    <LabelFont>{this.transService("Description")} :</LabelFont>
                    <ValueFont noWrap >
                      <span title={service.attributes.description}>{service.attributes.description}</span>
                    </ValueFont>
                  </DescWrapper>
                  <CustomIconBtn
                    style={{ padding: 0 }}
                    onClick={() => {this.openViewServiceModal(service.id)}}
                    data-testid="view-icon-btn"
                  >
                    <KeyboardArrowRightIcon style={{ color: "#6F05EA" }} />
                  </CustomIconBtn>
                </ServicesWrapper>
              </>
            )
          })): (
            <></>
          )}
          {!this.state.getServiceListData.length && this.state.getServiceListLoading ?
          <Box className="progressBarContainer"><CircularProgress/></Box>
            : <></>}
          {!this.state.getServiceListData.length && !this.state.getServiceListLoading ?
            <Typography className="no-data-found">{this.transService("No results found!")}</Typography>
            : <></>}
        </ListWrapper>
        <ButtonWrapper>
          <CustomButton
            style={style.blueBtn}
            data-testid="add-services-btn"
            onClick={this.openAddServiceModal}
          >
            {this.transService("Add Services")}
          </CustomButton>
        </ButtonWrapper>
        {this.renderAddServicesModal()}
        {this.renderCreateServiceModal()}
        {this.state.selectedViewData.attributes && this.renderViewServicesModal()}
        {this.renderSimpleDialogForService()}
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  width: "100%",
  "& .disableNewRoomBtn": {
    color: "#78716C !important",
    border: "1px solid #78716C !important",
  },
})
const ListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  "& .no-data-found":{
    fontFamily: 'Montserrat',
    color:"rgb(183, 183, 183)",
    textAlign:"center",
    fontWeight:600,
    fontSize:18
  },
  "& .progressBarContainer":{
    display:"flex",
    justifyContent:"center",
    "& .MuiCircularProgress-colorPrimary":{
      color:"#6F05EA"
    }
  }
})
const ServicesWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  minHeight: "48px",
  height: "100%",
  alignItems: "center",
  padding: "8px 24px",
  rowGap: "8px",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "@media (max-width: 600px)": {
    padding: "8px",
  },
})
const LabelFont = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#0F172A"
})
const ValueFont = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA", 
  maxWidth: 250
})
const TitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"200px",
  flexWrap:"wrap"
})
const CapacityWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  minWidth: 100,
  flexWrap:"wrap"
})
const DescWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"370px",
  flexWrap: "wrap",
  rowGap: "8px",
  "@media (max-width: 600px)": {
    width: "300px"
  },
  "@media (max-width: 400px)": {
    width: "250px"
  }

})

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "end",
  marginTop: "32px"
})
const CustomButton = styled(Button)({
  height: "40px",
  padding: "8px 36px",
  borderRadius: "100px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "18px",
  fontFamily: "Montserrat",
  lineHeight: "24px",
  textTransform: "none"
})

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 1240,
    },
  },
  "& .MuiInput-underline.Mui-error::after":{
    border:"none"
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
    borderColor:"#D9D9D9"
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor:"#D9D9D9"
  },
  "& .description-container":{
    "& .MuiFormHelperText-contained":{
      marginLeft:"0px !important",
      fontFamily: 'Montserrat',
    }
  }
})
const FormTitle = styled(DialogTitle)({
  padding: "40px 60px",
  "@media (max-width: 600px)": {
    padding: "32px",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
  "& h2": {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: "32px",
    color: "#6F05EA",
    "@media (max-width: 600px)": {
      fontSize: "24px",
    },
  },
})

const FormWrapper = styled(DialogContent)({
  padding: "0px 60px",
  "@media (max-width: 600px)": {
    padding: "0 32px",
  },
  "& .errorText": {
    color: "#f44336",
    fontSize: "0.75rem",
    fontFamily: "Montserrat",
  },
  "@media (max-width: 400px)": {
    padding: "24px",
  },
})

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 32,
  flexWrap: "wrap"
})
const LeftBox = styled(Box)({
  maxWidth: 360,
  width: "100%",
})
const RightBox = styled(Box)({
  maxWidth: 124,
  width: "100%"
})
const FormLabel = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: "13px",
  lineHeight: "22px",
  fontWeight: 400,
  color: "#6F05EA"
})
const CustomSelect = styled(Select)({
  "& .MuiSelect-root": {
    marginLeft: "8px",
    marginTop: "4px",
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: "12px",
    },
  },
  "&:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    fontFamily: 'Montserrat',
    backgroundColor: 'transparent',
    fontSize: "12px",
  },
})
const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
  }
})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})

const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInputBase-input": {
    marginLeft: "8px",
    fontFamily: 'Montserrat',
    marginTop: "4px",
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  "& .MuiFormHelperText-root":{
    fontFamily: 'Montserrat',
  }
})
const CustomTextArea = styled(TextField)({
  marginTop: "6px",
  width: "100%",
  "& .MuiOutlinedInput-multiline": {
    padding: "0"
  },
  "& textarea": {
    padding: "8px",
    border: "1px solid #D9D9D9 !important",
    borderRadius: "8px !important",
    resize: "none",
    fontFamily: 'Montserrat',
    fontSize: "12px",
    "&::placeholder": {
      opacity: 1,
      fontFamily: 'Montserrat',
      fontStyle: "italic",
      fontSize: "12px",
      fontWeight: 400,
      color: "rgba(166, 166, 166, 1)"
    }
  },
})
const ImageWrraper = styled(Box)({
  marginTop: "40px"
})
const GalleryWrapper = styled(Box)({
  marginTop: 6,
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
  rowGap: 20,
  flexWrap: "wrap"
})
const ImageListWrapper = styled(Box)({
  width: "100%",
  maxWidth: 580,
  display: "flex",
  flexDirection: "row",
  columnGap: 12,
  rowGap: 20,
  flexWrap: "wrap",
})
const UploadButton = styled(Button)({
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  width: "84px",
  height: "84px",
  textTransform: "none"

})
const UploadIcon = styled(AddCircleOutline)({
  color: "#6F05EA",
  height: 24,
  width: 24
})
const UploadText = styled(Typography)({
  marginTop: "8px",
  fontFamily: 'Montserrat',
  fontSize: "10px",
  color: "#000000",
  fontWeight: 400,
  textAlign: "center"
})
const UploadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
})
const ImageBox = styled(Box)({
  width: "83px",
  height: "83px",
  position: "relative",
  padding: 0,
  border: "1px solid #D9D9D9",
  borderRadius: "9px",
  "& img":{
    borderRadius:"9px"
  }

})
const RemoveBtn = styled(IconButton)({
  position: "absolute",
  top: -10,
  right: -10,
  padding: 0,
})
const FormButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  columnGap: "24px",
  margin: "32px 0"
})
const FormButton = styled(Button)({
  height: "56px",
  width: "100%",
  maxWidth: "246px",
  borderRadius: "31px",
  textAlign: "center",
  fontSize: "18px",
  fontFamily: 'Montserrat',
  fontWeight: 600,
  lineHeight: "24px",
  textTransform: "none"
})
const ViewDialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      maxWidth: 930,
    },
  },

  "& .closeImageContainer": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .viewDialogContainer": {
    padding: "36px 32px 64px 47px",
    display:"flex",
    flexDirection:"column",
    height: "100%",
    "@media (max-width: 600px)": {
        padding:"18px 23px 32px 23px"
    },
  },
  "& .titleTypo": {
    fontFamily: "Montserrat",
    color: "#6F05EA",
    fontSize: "32px",
    fontWeight: 600,
    textAlign: "center",
  },
  "& .infoTypo": {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    "& span": {
      fontFamily: "Montserrat",
      color: "#6F05EA",
      textWrap: "wrap",
    },
  },
  "& .infoContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    marginTop: 20,
    flexWrap: "wrap",
  },
  "& .descriptionContainer": {
    marginTop: "28px",
    backgroundColor: "#FBFBFB",
    padding: 8,
    borderRadius: 8,
  },
  "& .descriptionTypo": {
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontWeight: 400,
    textAlign: "justify",
    whiteSpace:"pre-wrap",
  },

  "& .imgGalleryContainer": {
    marginRight: "9px",
    overflowY:"hidden",
    padding:"0 21px 0 8px",
  },
  "& .media": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "& .card": {
    border: "none",
    boxShadow: "none",
    height: "100%",
  },

  "& .viewDialogBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "62px",
    marginRight:"32px"
  },
  "& .imageGalleryContainer": {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  "& .viewEditBtn": {
    padding: "8px 0",
    height: "40px",
    width: "140px",
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none",
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  },
  "& .imageGalleryImage": {
    height: "100%",
    width: "100%"
  }
})
const ViewTitle = styled(Box)({
  padding: "0",
  display: "flex",
  justifyContent: "end"
})
const CustomIconBtn = styled(IconButton)({
  padding: 0
})
const ResponsiveImageList = styled(ImageList)({
  maxWidth: "750px",
  "& .MuiImageListItem-root": {
    maxHeight: "250px !important",
    // height: "100% !important",
  },
  "@media (max-width: 600px)": {

    "& .MuiImageListItem-root": {
      width: "50% !important"
    }
  },
  "@media (max-width: 450px)": {
    "& .MuiImageListItem-root": {
      width: "50% !important"
    }
  },
});
const StyledCreateService = styled(Dialog)({
  "& .MuiDialog-paperWidthMd":{
    maxWidth:"751px"
  },
  "& .CreateServiceContainer":{
    padding:"33px"
  }
});
const CreateServiceImgWrapper = styled(Box)({
  display:"flex",
  justifyContent:"flex-end"
});
const CloseIconButton = styled(IconButton)({
  height:"32px", 
  width:"32px"
});
const CreateServiceInfoWrapper = styled(Box)({
  textAlign:"center",
  maxWidth:"547px", 
  padding: "15px 70px 60px 70px",
  "@media (max-width: 960px)": {
    padding: "10px 50px 50px 70px"
  },
  "@media (max-width: 600px)": {
    padding: "8px 0px 40px 0px"
  },
  "& .CreateServiceTypo":{
    fontWeight:600,
    fontSize:"24px",
    lineHeight:"34px",
    "@media (max-width: 960px)": {
      fontSize:"20px",
    },
    "@media (max-width: 600px)": {
      fontSize:"16px",
    },
  },
  "& .pleasFillTypo":{
    lineHeight:"29px",
    fontSize:"20px",
    fontWeight:500,
    "@media (max-width: 960px)": {
      fontSize:"16px",
    },
    "@media (max-width: 600px)": {
      fontSize:"12px",
    },
  }
});
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  greyBtn: {
    color: "#4B4B4B",
    border: "1px solid #4B4B4B",
  } as React.CSSProperties,
  defaultMenu: {
    fontStyle: "italic",
    fontFamily: 'Montserrat',
    fontSize: "12px",
    fontWeight: 400,
    color: "rgba(166, 166, 166, 1)"
  } as React.CSSProperties,
  imageInput: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  viewClose: {
    textAlign: "end"
  } as React.CSSProperties,
  formButton: {
    height: "56px",
    width: "100%",
    maxWidth: "246px",
    textAlign: "center",
    borderRadius: "31px",
    fontSize: "18px",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    lineHeight: "24px",
    textTransform: "none"
  } as React.CSSProperties,
}

const ViewDialogTitle = styled(DialogTitle)({
  padding: "0 25px 0 0"
})
// Customizable Area End
