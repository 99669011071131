Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";

exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
}

exports.ENDPOINTS = {
  GET_EMPLOYEE_LIST: "bx_block_navmenu/employees",
  DELETE_EMPLOYEE: "bx_block_navmenu/employees",
  GET_SERVICE_SUB_SERVICES: "bx_block_navmenu/employees/get_services",
  USER_ROLE: 'bx_block_roles_permissions/roles',
  ADD_EMPLOYEE: "bx_block_navmenu/employees",
  EDIT_EMPLOYEE: "bx_block_navmenu/employees"
}

exports.API_CONTENT_TYPE = {
  APPLICATION_JSON: "application/json",
}

exports.DEFAULT_MSG = {
  EMPLOYEE_TITLE: "Employees list",
  ADD_EMPLOYEE_BTN: "Add New Employee",
  EMPLOYEE_LIST_EMPTY: "The employee list is empty.",
  PLEASE_CLICK_ADD_EMPLOYEE: "Please click 'Add New Employee' to get started.",
  DELETE_MODAL_TITLE: "Are you sure you want to permanently remove this employee?",
  DELETE_MODAL_YES: "Yes, I'm sure",
  DELETE_MODAL_NO: "No, don't Remove",
  DELETED_SUCCESSFULLY: "Record deleted successfully",
  // FORM ERRORS
  PHOTO_EMPTY: "Please upload a Image",
  NAME_EMPTY: "Please enter Name",
  ROLE_EMPTY: "Please select Role",
  EMAIL_EMPTY: "Please enter valid Email",
  PHONE_EMPTY: "Please enter valid Phone",
  SERVICE_EMPTY: "Please select Service",
  SUBSERVICE_EMPTY: "Please select Sub Service",
  INVALID_PHOTO_SIZE: "File size should not exceed 5MB",
  INVALID_PHOTO_TYPE: "Invalid file type. Please upload a PNG or JPG image",
  START_TIME_EMPTY: "Please enter Start time",
  END_TIME_EMPTY: "Please enter End time"
}

exports.SUB_SERVICES_FOR_EMPLOYEES = ["Dog Walking", "Grooming", "Training"]

exports.initialAvailability = [
  {
    day: "Monday",
    start_time: "",
    end_time: "",
    checked: false
  },
  {
    day: "Tuesday",
    start_time: "",
    end_time: "",
    checked: false
  },
  {
    day: "Wednesday",
    start_time: "",
    end_time: "",
    checked: false
  },
  {
    day: "Thursday",
    start_time: "",
    end_time: "",
    checked: false
  },
  {
    day: "Friday",
    start_time: "",
    end_time: "",
    checked: false
  },
  {
    day: "Saturday",
    start_time: "",
    end_time: "",
    checked: false
  },
  {
    day: "Sunday",
    start_time: "",
    end_time: "",
    checked: false
  }
]
// Customizable Area End
