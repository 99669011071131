export const headerLogo = require("../assets/header-logo.svg");
export const chatIcon = require("../assets/chat-icon.svg");
export const avatar = require("../assets/avatar.png");
export const calendar = require("../assets/calendar.svg");
export const paw = require("../assets/paw.svg");
export const settings = require("../assets/settings.svg");
export const establishmentsettings = require("../assets/image_setting_24px.png");
export const wallet = require("../assets/wallet.svg");
export const female = require("../assets/female.svg");
export const male = require("../assets/male_icon.svg");
export const add = require("../assets/add.svg");
export const Gallery= require("../assets/gallery.png")
export const Setting= require("../assets/Setting.png")
export const Dashboard= require("../assets/Dashboard.png")
export const Grooming= require("../assets/Grooming.png")
export const pet= require("../assets/pet.png")
export const Training= require("../assets/Traning.png")
export const Hotel= require("../assets/Hotel.png")
export const Daycare= require("../assets/Daycare.png")
export const DogWalking= require("../assets/DogWalking.png")
export const Calendar= require("../assets/calendario.png")
export const addServices = require("../assets/add.png")
export const macImage = require("../assets/macImage.svg");
export const phoneIcon = require("../assets/phoneIcon.svg");
export const windowsImage = require("../assets/windowsImage.svg");
export const Mastercard = require("../assets/Mastercard.svg");
export const Visa = require("../assets/Visa.svg");
export const Amex = require("../assets/Amex.svg");
export const Card = require("../assets/card_default.svg");
export const DeleteIcon = require("../assets/delete_icon.svg");
export const  errorImage= require("../assets/error.png")
export const CalendarIcon = require("../assets/calendar-icon.svg");
export const deleteIcon = require("../assets/delete.png");
export const checkedIcon = require("../assets/checked.png");
export const uncheckedIcon = require("../assets/unchecked.png");
export const upload = require("../assets/upload.png");
export const closeIcon = require("../assets/close-icon.svg");
export const video2 = require("../assets/video2.mp4");
export const Cross = require("../assets/group_cross.png");
export const Backarrow = require("../assets/arraowBack.png");
export const Catprofile = require("../assets/CatProfile.png");
export const request = require("../assets/request.png");
export const image_paw_24px = require("../assets/image_paw_24px.png");
export const image_crop_24px = require("../assets/image_crop_24px.png");
export const personpic = require("../assets/personpic.png");
export const group_switch = require("../assets/group_switch.png");
export const request_icon = require("../assets/requestIcon.svg");

export const allRequest = require("../assets/phoneIcon.svg");
export const progressRequest = require("../assets/phoneIcon.svg");
export const completeRequest = require("../assets/phoneIcon.svg");
export const bellIcon = require("../assets/phoneIcon.svg");
export const groupImg = require('../assets/phoneIcon.svg');
export const logoImg = require('../assets/phoneIcon.svg');
export const logoBlueImg = require('../assets/upload.png');
export const localProfile = require("../assets/phoneIcon.svg");
export const saveImage = require("../assets/phoneIcon.svg");
export const cancelImage = require("../assets/phoneIcon.svg");
export const service = require("../assets/phoneIcon.svg");
export const outgoing = require("../assets/phoneIcon.svg");

export const appointmentCardTitleIcon  = require("../assets/appointment-card-title-icon.svg");
export const groomingIcon = require("../assets/grooming-icon.svg");
export const CardDogImage = require("../assets/dog-image.png");

export const Dogfootprint = require("../assets/Dogfootprint.svg");
export const petbath = require("../assets/petbath.svg");
export const rightArrow = require("../assets/rightArrow.svg");
export const leftArrow = require("../assets/leftArrow.svg");
export const DayCareIcon = require("../assets/DayCareIcon.svg");
export const DogWalkingIcon = require("../assets/DogWalkingIcon.svg");
export const HotelsIcon = require("../assets/HotelsIcon.svg");
export const GroomingIcon = require("../assets/GroomingIcon.svg");
export const PetSittingIcon = require("../assets/PetSittingIcon.svg");
export const TrainingIcon = require("../assets/TrainingIcon.svg");
export const EmptyAppointments = require("../assets/EmptyAppointments.svg");
export const Portrait = require("../assets/Portrait.svg");
export const DefaultCard = require("../assets/DefaultCard.svg");
export const EditPen = require("../assets/edit_icon_pen.svg");
export const UploadSvg = require("../assets/upload.svg");

export const CheckedCheckBox = require("../assets/checked_checkbox.svg");
export const UnCheckedCheckBox = require("../assets/unchecked_checkbox.svg");

export const NextArrowIcon = require("../assets/next-arrow-icon.svg");
export const uploadGalleryIcon = require("../assets/upload-gallery-icon.svg");
export const removeGalleryIcon = require("../assets/remove-gallery-icon.svg");
export const selectArrowIcon = require("../assets/select-arrow-icon.svg");


export const infoIcon = require("../assets/info-icon.svg");
export const offSwitchIcon = require("../assets/off-switch-icon.svg");
export const onSwitchIcon = require("../assets/on-switch-icon.svg");
export const ViewClose = require("../assets/view_close_icon.svg");
export const viewImage1  = require("../assets/product-image.png");
export const viewImage2  = require("../assets/product-image-1.png");
export const viewImage3  = require("../assets/product-image-2.png");
export const viewImage4  = require("../assets/product-image-3.png");
export const viewImage5  = require("../assets/product-image-4.png");
export const viewImage6  = require("../assets/product-image-5.png");

export const DayCareBlack  = require("../assets/day_care_black.svg");
export const DogWalkingBlack  = require("../assets/dog_walking_black.svg");
export const HotelsBlack  = require("../assets/hotels_black.svg");
export const GroomingBlack  = require("../assets/grooming_black.svg");
export const PetSittingBlack  = require("../assets/pet_sitting_black.svg");
export const TrainingBlack  = require("../assets/training_black.svg");

export const DayCareBlue  = require("../assets/day_care_blue.svg");
export const DogWalkingBlue  = require("../assets/dog_walking_blue.svg");
export const HotelsBlue  = require("../assets/hotels_blue.svg");
export const GroomingBlue  = require("../assets/grooming_blue.svg");
export const PetSittingBlue  = require("../assets/pet_sitting_blue.svg");
export const TrainingBlue  = require("../assets/training_blue.svg");

export const SwitchEstablishment  = require("../assets/switch_establishment.svg");
export const EstablishmentSetting = require("../assets/setting_establishment.svg");
export const EstablishmentSettingBlue = require("../assets/setting_establishment_blue.svg");

export const AddServicesBlue  = require("../assets/add_service_blue.svg");
export const checkedRadio  = require("../assets/checked_radio.svg");
export const unCheckedRadio  = require("../assets/unchecked_radio.svg");

export const Tab1  = require("../assets/calendar_tab.svg");
export const Tab2  = require("../assets/gallery_tab.svg");
export const Tab3  = require("../assets/dashboard_tab.svg");
export const Tab4  = require("../assets/setting_tab.svg");

export const AccordionIcon  = require("../assets/general_info_accordian.svg");


export const VendorCalendarPerson = require("../assets/calendar_vendor_select.svg");
export const DropdownIcon = require("../assets/dropdown_icon.svg");

export const LoadImage = require("../assets/load_image.svg");

export const EditFilled = require("../assets/edit_24px.svg");
export const RequestYellow = require("../assets/request_yellow.svg");

export const galleryUploadIcon = require("../assets/galleryUploadIcon.svg");
