import React from 'react';
import { Box, Button, Typography } from "@material-ui/core"
import Field from "./Field"
import { Request, Shedule } from "../CustomisableUserProfilesController"
import { request_icon } from '../assets';
import moment from "moment";
import { LoaderButton } from "../../../../components/src/calendarWeb/LoaderButton";
import { transPt } from '../../../../components/src/HelperUtils';

type Props = {
    request: Request | null;
    selectedEvent: Shedule;
    displayFields: Record<any, boolean> | null;
    changeRequestStatus: (type: 'rejected' | 'accepted') => void;
    isLoading: boolean;
}

const DetailsRequestFields = ({ request, selectedEvent, displayFields, changeRequestStatus, isLoading }: Props) => {
    if(displayFields && request) {
        return (
            <Box className='section-wrapper'>
                <Box className='section-header'>
                    <img src={request_icon} />
                    <Typography className='section-text'>{transPt("Request", "customerUserProfile")}</Typography>
                </Box>
                <Box className='fields-wrapper' style={{ alignItems: 'flex-end'}}>
                    <Box className='field-set'>
                        <Field title='' value={request.request_type} />
                        <Field data-test-id="date" title={displayFields.endDate ? transPt("Old Start Date", "customerUserProfile") : transPt("Old Date", "customerUserProfile")} value={moment(selectedEvent.start_date).format('DD/MM/YYYY')} />
                        {displayFields.endDate && <Field data-test-id="end-date" title={transPt("Old End Date", "customerUserProfile")} value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
                        {displayFields.time && <Field data-test-id="time" title={transPt("Old Time", "customerUserProfile")} value={selectedEvent.shift} />}
                        {displayFields.timeGrooming && <Field title={transPt("Old Time", "customerUserProfile")} value={moment(selectedEvent.start_time, "HH:mm").format("hh:mm A")} />}
                    </Box>
                    <Box className='field-set'>
                        <Box />
                        <Field data-test-id="update-date" title={displayFields.endDate ? transPt("Updated Start Date", "customerUserProfile") : transPt("Updated Date", "customerUserProfile")} value={moment(request.start_date).format("DD/MM/YYYY")} />
                        {displayFields.endDate && <Field data-test-id="update-end" title={transPt("Updated End Date", "customerUserProfile")} value={moment(request.end_date).format('DD/MM/YYYY')} />}
                        {displayFields.time && <Field data-test-id="update-time" title={transPt("Updated Time", "customerUserProfile")} value={request.shift} />}
                        {displayFields.timeGrooming && <Field title={transPt("Updated Time", "customerUserProfile")} value={moment(request.start_time, "HH:mm").format("hh:mm A")} />}
                    </Box>
                </Box>
                <Box className='req-buttons-wrapper'>
                    <Button data-test-id="reject-btn" className='reject-btn' onClick={() => changeRequestStatus('rejected')} disabled={isLoading}>
                        <LoaderButton color="#C10000" size="18px" text={transPt("Reject", "customerUserProfile")} isLoading={isLoading} />
                    </Button>
                    <Button data-test-id="accept-btn" className='accept-btn' onClick={() => changeRequestStatus('accepted')} disabled={isLoading}>
                        <LoaderButton color="#6F05EA" size="18px" text={transPt("Accept", "customerUserProfile")} isLoading={isLoading} />
                    </Button>
                </Box>
            </Box>
            
        )
    }
    return null;
}

export default DetailsRequestFields;