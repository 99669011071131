export const enVendorProfile = {
    "Calendar":"Calendar",
    "Gallery":"Gallery",
    "Dashboard":"Dashboard",
    "General Information":"General Information",
    "Looks like you haven't created any services yet. Let's get started!":"Looks like you haven't created any services yet. Let's get started!",
    "Add Service":"Add Service",
    //GALLERY
        "No images added yet.":"No images added yet.",
        "To upload image in the gallery, we'll need some basic information.":"To upload image in the gallery, we'll need some basic information.",
        "Please fill in the general details first!":"Please fill in the general details first!",
    //CALENDAR
        "Upcoming Appointments":"Upcoming Appointments",
        "Past Appointments":"Past Appointments",
        "Requests":"Requests",
    //ADD BOOKING
        "Please provide your client's email address":"Please provide your client's email address",
        "Email":"Email",
        "Client email address":"Client email address",
        "Invalid email address":"Invalid email address",
        "*Please enter client email to proceed.":"*Please enter client email to proceed.",
        "Booking email has been sent successfully":"Booking email has been sent successfully",
        "Add Booking":"Add Booking",
    //BUTTON
        "Upload":"Upload",
        "Cancel":"Cancel",
        "Save":"Save",
        "Edit":"Edit",
        "Send":"Send"
}

export const ptVendorProfile = {
    "Calendar":"Calendário",
    "Gallery":"Galeria",
    "Dashboard":"Painel",
    "General Information":"Informações gerais",
    "Looks like you haven't created any services yet. Let's get started!":"Parece que você ainda não criou nenhum serviço. Vamos começar!",
    "Add Service":"Adicionar serviço",
    //GALLERY
        "No images added yet.":"Nenhuma imagem adicionada ainda.",
        "To upload image in the gallery, we'll need some basic information.":"Para fazer upload de imagens na galeria, precisaremos de algumas informações básicas.",
        "Please fill in the general details first!":"Por favor, preencha os detalhes gerais primeiro!",
    //CALENDAR
        "Upcoming Appointments":"Próximos compromissos",
        "Past Appointments":"Compromissos anteriores",
        "Requests":"Solicitações",
    //ADD BOOKING
        "Please provide your client's email address":"Forneça o endereço de e-mail do seu cliente",
        "Email":"E-mail",
        "Client email address":"Endereço de e-mail do cliente",
        "Invalid email address":"Endereço de e-mail inválido",
        "*Please enter client email to proceed.":"*Insira o e-mail do cliente para prosseguir.",
        "Booking email has been sent successfully":"O e-mail de reserva foi enviado com sucesso",
        "Add Booking":"Adicionar reserva",
    //BUTTON
        "Upload":"Carregar",
        "Cancel":"Cancelar",
        "Save":"Salvar",
        "Edit":"Editar",
        "Send":"Enviar"
}