import React from "react";
import { EventProps } from "react-big-calendar";
import { Box, Typography, styled } from "@material-ui/core";
import { FormattedEvent } from "./EmployeeCalendar";
import { handleGetFTime } from "../../../../../components/src/helpers";

const WeekWrapper = styled("div")({
  height: "100%",
  border: "1px solid #6F05EA",
  backgroundColor: "#F6F0FF",
  color: "black",
  borderRadius: "18px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  overflow: "hidden",
  padding: "0 4px",
  fontFamily: 'Montserrat',
  textAlign: 'center',

  "& .top-time": {
    fontSize: "10px", whiteSpace: "nowrap", fontWeight: 600
  },
  "& .event-content": {
    overflow: "hidden",
    alignSelf: "flex-start",
    width: "100%",
    fontSize: "11px",
  },
  "& .event-title": {
    color: '#5500CC',
    fontSize: '12px',
    fontWeight: 600
  }
});

const WeekEvent: React.FC<EventProps<FormattedEvent>> = ({ event }) => {
  const is30Min = event.duration === '30' || event.sub_service.duration === '30 mins'
  return (
    <WeekWrapper className="week-event-wrapper">
      {!is30Min && <Box className="top-time" >
        {handleGetFTime(event.start)}
      </Box>
      }
      <Box className="event-content">
        <Typography className="event-title">{`${!!event.sub_service.title ?  event.sub_service.title : event.service.service_type}`}</Typography>
      </Box>
      {!is30Min && <Box className="top-time">
        {handleGetFTime(event.end)}
      </Box>}
      
    </WeekWrapper>
  );
};

export default WeekEvent;
