import React from "react";
// Customizable Area Start
import BookingDetailsController, { Props } from "./BookingDetailsController.web";
import { Box, Dialog, styled, Typography, IconButton, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
// Customizable Area End

export default class BookingDetails extends BookingDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledDialog
                open
                fullWidth
                maxWidth={"lg"}
                onClose={this.props.handleClose}
            >
                <MainWrapper>
                    <CloseButtonBox>
                        <CloseIconButton onClick={this.props.handleClose}>
                            <Close />
                        </CloseIconButton>
                    </CloseButtonBox>
                    <TitleBox>
                        <TitleFont>Booking Details</TitleFont>
                    </TitleBox>

                    <DetailsWrapper>
                        <RowBox>
                            <StyledFont>Client Name</StyledFont>
                            <StyledFont>Esther Howard</StyledFont>
                        </RowBox>
                        <RowBox>
                            <StyledFont>Pet Name</StyledFont>
                            <StyledFont>Luka</StyledFont>
                        </RowBox>
                        <RowBox marginTop={"42px"}>
                            <StyledFont>€ 33 x 5 nights </StyledFont>
                            <StyledFont>€ 165</StyledFont>
                        </RowBox>
                        <RowBox marginTop={"18px"}>
                            <StyledFont className="bold-title">Complementary services</StyledFont>
                        </RowBox>
                        <RowBox>
                            <StyledFont>Medication</StyledFont>
                            <StyledFont>€ 37</StyledFont>
                        </RowBox>
                        <CustomHR />
                        <RowBox>
                            <StyledFont className="small-title">Total</StyledFont>
                            <TitleFont>€ 202</TitleFont>
                        </RowBox>
                    </DetailsWrapper>
                </MainWrapper>
            </StyledDialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        maxWidth: "905px",
        borderRadius: "8px"
    },
})
const MainWrapper = styled(Box)({
    padding: "24px",
})
const CloseButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "end",
    alignItems: "end"
})
const CloseIconButton = styled(IconButton)({
    padding: 0,
    "& .MuiSvgIcon-root": {
        color: "#090909"
    }
})
const TitleBox = styled(Box)({
    display: "flex",
    padding: "48px 24px 42px 24px"
})
const TitleFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "40px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
})
const RowBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
})
const StyledFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    color: "#000000",
    "&.bold-title": {
        fontWeight: 600,
    },
    "&.small-title": {
        fontWeight: 600,
        fontSize: "24px",
    }

})
const DetailsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "0 24px 72px 24px"
})
const CustomHR = styled(Divider)({
    margin: "42px 0",
    color: "#B7B7B7"
})
// Customizable Area End
