Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.httpPostMethod = 'POST'
exports.httpContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.loginApiEndPoint = 'bx_block_login/logins'
exports.petListApiEndPoint = 'account_block/animal_types'
exports.searchApiEndPoint = 'bx_block_advanced_search/search/advance_filter'
exports.errorTitle = 'Error'
exports.noImageUrl = 'https://via.placeholder.com/1600x900.png?text=No+Image'

exports.SERVICE_ENUMS = {
  HOTELS:"Hotels",
  DOG_WALKING: "Dog Walking",
  PET_SITTING: "Pet Sitting",
  DAY_CARE: "Day Care",
  TRAINING: "Training",
  GROOMING: "Grooming"
}
// Customizable Area End
