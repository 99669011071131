// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

const configJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openCustomerModal: boolean,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class ServiceDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            openCustomerModal: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }    

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    openCustomerModal = () => {
        this.setState({
            openCustomerModal: true
        })
    }
    closeCustomerModal = () => {
        this.setState({
            openCustomerModal: false
        })
    }
    // Customizable Area End
}