import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SearchData } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import { defaultScroll, getUniquePetCategories, returnTruthyString } from "../../../components/src/HelperUtils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";

export interface ISearchModel {
  country?: string;
  city?: string;
  startDate?: Date;
  endDate?: Date;
  pet?: string;
  everyWeek?: number;
  everyDay?: string;
  endAfter?: Date;
}
interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface IFindAttrModel {
  id: number,
  type: string,
  attributes: {
    id: number,
    service_title: string,
    service_type: string,
    find_service_photo: {
      url: string
      content_type: string,
      file_name: string
    }
  }
}

interface IFindServiceApiSuccessResponse {
  data: IFindAttrModel[];
}
interface IGetDiscountApiSuccessResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      image: {
        id: number;
        url: string;
      };
    };
  }[];
}


interface ListErrorDetail {
  detail?: string;
  account?: string;
  token?: string;
  error?: string;
}

export interface IPet {
  pet_category: string;
  id: number;
  name?: string;
  gender: string;
  breed: string;
  birthdate: string;
  weight: string;
  size: string;
  account_id: number;
  fur: string;
  species: string;
  created_at?: string;
  updated_at?: string;
  pet_photo: null | string;
}

interface IRecentlyBooked {
  schedule_id: number;
  service_id: number;
  sub_service_id: number;
  sub_services_price: number;
  establishment_id: number;
  establishment_name: string;
  establishment_address: string;
  establishment_latitude: number;
  establishment_longitude: number;
  sub_service_image: string;
}

interface PetCategory {
  label: string;
  value: string;
}

interface IRecentlyBookedApiSuccessResponse {
  data: IRecentlyBooked[];
  meta: {
    message: string
  }
  errors?: ListErrorDetail[];
}
//unread count message
export interface UnreadCountInterface {
  total_unread_count:number | null;  
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  searchData: ISearchModel;
  findServiceDataList: IFindServiceApiSuccessResponse;
  discountBannerData: IGetDiscountApiSuccessResponse;
  recentlyBookedList: IRecentlyBooked[];
  petsList: PetCategory[];
  //Loader
  getRecentlyBookedLoader:boolean
  getFindServiceLoader:boolean
  //MODEL
  isLogOutOpen: boolean
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  findServiceListApiCallId: string = "";
  discountBannerApiCallId: string = "";
  recentlyBookedListApiCallId: string = "";
  apiPetListCallId: string = "";
  //unread count message api end point 
  getUnreadMessageCountApi:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      tabValue: 0,
      petsList: [],
      searchData: {},
      findServiceDataList: {} as IFindServiceApiSuccessResponse,
      discountBannerData: {
        data: [],
      },
      recentlyBookedList: [],
      getRecentlyBookedLoader:false,
      getFindServiceLoader:false,
      isLogOutOpen:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      const navigationData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if (navigationData && navigationData.landingPageTabValue) {
        const tabNumber = navigationData.landingPageTabValue as number;
        this.setState({ tabValue: tabNumber});
        this.getRecentlyBannerData(tabNumber);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.findServiceListApiCallId:
            this.handleFindServiceListResponse(responseJson);
            break;
          case this.discountBannerApiCallId:
            this.handleDiscountBannerResponse(responseJson);
            break;
          case this.recentlyBookedListApiCallId:
            this.handleRecentlyBookedResponse(responseJson);
            break;
          case this.apiPetListCallId:
            this.handlePetsListResponse(responseJson);
            break;
          case this.getUnreadMessageCountApi:
            this.handleUnreadMessageCount(responseJson)  
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.checkUserRole()
    defaultScroll();
    this.getFindServiceList();
    this.getRecentlyBannerData(0);
    this.getUserPets();
    this.getUnreadMessageCountCall()
  }
  checkUserRole = () => {
    const is_vendor = returnTruthyString(localStorage.getItem('login_user_role')) == "vendor"
    if (is_vendor) {
      this.props.navigation?.navigate("Cfentrepriseaccount")
    }
  }

  handlePetsListResponse = (response: any) => {
    if(response.data) {
      const petsList = response.data.map(
        (pet: { attributes: IPet }) => pet.attributes
      );
      this.setState({ petsList: getUniquePetCategories(petsList) });
    }
    
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue)});
    this.getRecentlyBannerData(Number(newValue));
  };

  goToRegisterPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountVendorRegistration"
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  apiCall = async (data: IApiModel) => {
    const {
      contentType,
      method,
      endPoint,
      body,
      token,
      isJsonStringify,
    } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getUserPets = async () => {
    const token = localStorage.getItem("login_token");
    if(token) {
      this.apiPetListCallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.petListApiEndPoint,
        token: token,
      });
    }
  }

  getFindServiceList = async () => {
    this.setState({getFindServiceLoader:true, findServiceDataList: {} as IFindServiceApiSuccessResponse});
      this.findServiceListApiCallId = await this.apiCall({
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.landingPageFindServiceListApiEndPoint}`,
      });
  };

  handleFindServiceListResponse = (
    responseJson: IFindServiceApiSuccessResponse
  ) => {
    this.setState({getFindServiceLoader:false});
    if (responseJson) {
      this.setState({ findServiceDataList: responseJson });
    }
  };

  getTabTitle = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return "Hotels";
      case 1:
        return "Dog Walking";
      case 2:
        return "Grooming";
      case 3:
        return "Pet Sitting";
      case 4:
        return "Day Care";
      case 5:
        return "Training";
      default:
        return "Hotels";
    }
  };

  getDiscountBanner = async (tabData: number) => {
    const serviceType = this.getTabTitle(tabData);
    this.discountBannerApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getDiscountBannerApiEndPoint}${serviceType}`,
    });
  };

  handleDiscountBannerResponse = (
    responseJson: IGetDiscountApiSuccessResponse
  ) => {
    if (responseJson) {
      this.setState({ discountBannerData: responseJson });
    }
  };

  getRecentlyBookedData = async (tabData: number) => {
    this.setState({getRecentlyBookedLoader:true, recentlyBookedList : []});
    const serviceType = this.getTabTitle(tabData);
    this.recentlyBookedListApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.landingPageRecentlyBookedListApiEndPoint}${serviceType}`,
    });
  };

  handleRecentlyBookedResponse = (
    responseJson: IRecentlyBookedApiSuccessResponse
  ) => {
    this.setState({getRecentlyBookedLoader:false});
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ recentlyBookedList: responseJson.data });
    }else {
      this.setState({ recentlyBookedList: [] });
    }
  };

  goToAdvancedSearchPage = (data: SearchData) => {
    const NavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    NavigateMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdvancedSearch"
    );
    NavigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    NavigateMsg.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(NavigateMsg);
  };

  goTOBookingPage = (data:any) => {
    localStorage.setItem("service_id", data.service_id);

    const ServiceId = String(data.service_id)
    const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Booking"); // ROUTE
    NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), ServiceId); // PARAM

    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      landingPageTabValue: this.state.tabValue,
    });
    NavigateMsg.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(NavigateMsg);
  };

  handleFindServiceClick = (serviceType:string) => {
    const tabValue = this.getTabValue(serviceType);
    this.setState({ tabValue: tabValue});
    this.getRecentlyBannerData(tabValue);
    defaultScroll();
  }

  getTabValue = (serviceType:string) => {
    switch (serviceType) {
      case "Hotels":
        return 0;
      case "Dog Walking":
        return 1;
      case "Grooming":
        return 2;
      case "Pet Sitting":
        return 3;
      case "Day Care":
        return 4;
      case "Training":
        return 5;
      default:
        return 0;
    }
  }

  getRecentlyBannerData = (tabValue:number) => {
    this.getDiscountBanner(tabValue);
    this.getRecentlyBookedData(tabValue);
  }

  handleGetStartClick = async () => {
    const isLoggedIn = await getStorageData('isUserLogin')
    if (isLoggedIn) {
      this.setState({ isLogOutOpen: true })
    }
    else {
      this.goToRegisterPage();
      defaultScroll();
    }
  }

  handleCancelLogout = () => {
    this.setState({ isLogOutOpen: false })
  }

  handleSureLogout = () => {
    removeStorageData("login_token");
    removeStorageData("login_user_name");
    removeStorageData("login_user_id");
    removeStorageData("login_refresh_token");
    removeStorageData("isUserLogin");
    removeStorageData("login_user_role");
    removeStorageData("login_email");
    removeStorageData("login_user_profile");
    removeStorageData("establishment_ID");
    removeStorageData("establishmentInfo");
    removeStorageData('selectedValueVendor');
    this.goToRegisterPage();
    defaultScroll();
  }
  
  transLandingPage = (lKey: string) => {
    return i18n.t(lKey, { ns: "landingPage" });
  }
  // unread messae count api call
  getUnreadMessageCountCall=async()=>{
    const token = localStorage.getItem("login_token");
      this.getUnreadMessageCountApi = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.getunreadcountEndpoint,
        token: token,
      });
  }
  handleUnreadMessageCount=(response:UnreadCountInterface)=>{
    if(response){
      if(response.total_unread_count){
        const unreadCount = response.total_unread_count ?? 0; // Fallback if undefined
        localStorage.setItem('total_unread_count', JSON.stringify(unreadCount));
        this.forceUpdate()
      }else{
        localStorage.setItem('total_unread_count', JSON.stringify(0));
        this.forceUpdate()
      }
    }
  }
  // Customizable Area End
}
